/** Vue specific imports */
import Vue from 'vue';
/** Modules */
import Vuelidate from 'vuelidate';
import { msalMixin } from 'vue-msal';

/** Vue-plugins */
import { BVToastPlugin } from 'bootstrap-vue';

import ApplicationState from '@/singletons/application.state.singleton';

/** i18n */
import i18n from './i18n';

/** Components */
import router from './router';
import App from './App.vue';

/** Icons */
import './icons';

/** Global base components */
import './components/globals/index';

/** Entrypoint Sass */
import './scss/index.scss';

/** Azure AD */
import './util/plugin/msal';

/** Models */
import EventBus from './Services/EventBus';
import { NotificationRegistryRenderer } from './native/notification/notification.registry.renderer';

/** Yeah right. */
Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(BVToastPlugin);
/** Entrypoint Application */
new Vue({
  router,
  mixins: [msalMixin],
  data: {
    currentRoute: window.location.pathname,
  },
  beforeCreate() {
    document.title = process.env.VUE_APP_WEB_TITLE;
  },

  async mounted() {
    EventBus.init();
    if (ApplicationState.isElectron()) {
      import('./Services/Connect/connect.service')
        .then((module) => {
          module.default.init();
        })
        .catch(console.error);
      await NotificationRegistryRenderer.init();
    }
  },
  // eslint-disable-next-line arrow-body-style
  render(h) { return h(App); },
  i18n,
}).$mount('#app');
