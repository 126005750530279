<template>
  <Loader />
</template>

<script>
import ApplicationState from '@/singletons/application.state.singleton';
import AuthService from '@/Services/Auth/auth.service';
import CompanyState from '@/singletons/company.state.singleton';
/** Components */
import Loader from './components/loader.vue';

export default {
  components: {
    Loader,
  },

  props: {
    shouldNavigate: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  data() {
    return {
      CompanyState,
      timeoutRef: null,
      path: this.$route.path,
    };
  },

  computed: {
    status() { return ApplicationState.appState; },
  },

  watch: {
    // eslint-disable-next-line func-names
    'CompanyState.hasLoadedOnce': function () {
      if (this.shouldNavigate) {
        this.$router.push({ name: CompanyState.fallbackRouteName });
      }
    },
  },
  async created() {
    if (this.shouldNavigate) {
      if (ApplicationState.appState < 1) {
        await AuthService.verifySession();
      }
      if (CompanyState.hasLoadedOnce) {
        this.$router.push({ name: CompanyState.fallbackRouteName });
      }
    }
    this.timeoutRef = setTimeout(() => {
      if (this.$route.path !== this.path) this.$router.push({ name: CompanyState.fallbackRouteName });
    }, 5000);
  },
  destroyed() {
    clearTimeout(this.timeoutRef);
  },
};
</script>
