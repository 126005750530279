/* eslint-disable no-restricted-properties */
/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import MapState from '@/singletons/map.state.singleton';

/**
 * Helper functions for doing animations in the map.
 */
class AnimationHelper {
  constructor() {
    // --
  }

  selectSpritePulse = (mesh) => {
    const startedAt = Date.now();
    const anim = setInterval(() => {
      const progress = (Date.now() - startedAt) / 800;

      if (progress > 1) {
        this.clearAnimation(mesh);
        return;
      }

      const easeOutElastic = (x) => {
        const c4 = (2 * Math.PI) / 3;
        if (x === 0) {
          return 0;
        }
        if (x === 1) {
          return 1;
        }
        return Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
      };

      const scale = (1 + 0.3 * easeOutElastic(progress));

      mesh.scale.set(
        mesh.prev_scale.x * scale,
        mesh.prev_scale.y * scale,
        mesh.prev_scale.z * scale,
      );

      window.dispatchEvent(new Event('map_render'));
    }, 18);
    mesh.anim_handle = anim;
  }

  clearAnimation = (mesh) => {
    if (mesh?.anim_handle) {
      clearInterval(mesh.anim_handle);
      mesh.anim_handle = null;
    }
  }

  moveCameraFocus = (position, zoomLevel = 5) => {
    if (!position || !MapState.controls) {
      return;
    }
    MapState.controls.moveTo(position.x, position.y, position.z, true);

    // If orthographic, use zoome feature, otherwise physically dolly the camera forward
    if (MapState.camera instanceof THREE.OrthographicCamera) {
      MapState.controls.zoomTo(zoomLevel, true);
    } else {
      MapState.controls.dollyTo(zoomLevel, true);
    }
  }

  pulseAnimation = (mesh) => {
    if (mesh.userData.isTweening) {
      return;
    }
    const tweenInflate = new TWEEN.Tween(mesh.scale).to({
      x: 1.8,
      y: 1.8,
      z: 1.8,
    }, 200).easing(TWEEN.Easing.Circular.Out).onStart(() => {
      mesh.userData.isTweening = true;
      mesh.material.opacity = 0.5;
      mesh.material.color = new THREE.Color(0x000000);
    });
    const tweenDeflate = new TWEEN.Tween(mesh.scale).to({
      x: 1,
      y: 1,
      z: 1,
    }, 400).easing(TWEEN.Easing.Circular.In).onComplete(() => {
      mesh.userData.isTweening = false;
      mesh.material.opacity = 0.8;
    });
    tweenInflate.chain(tweenDeflate).start();
  }

  bounceAnimation = (vector, target, options = {}) => {
    /** Animation options */
    const to = target || THREE.Vector3();
    const easing = options.easing || TWEEN.Easing.Quadratic.In;
    const duration = options.duration || 2000;

    /** Create the tween */
    const tweenVector3 = new TWEEN.Tween(vector)
      .to({ x: to.x, y: to.y, z: to.z }, duration)
      .easing(easing)
      .onUpdate((d) => {
        if (options.update) {
          options.update(d);
        }
      })
      .onComplete(() => {
        if (options.callback) options.callback();
      });

    /** return the tween in case we want to manipulate it later on */
    return tweenVector3;
  }

  setOpacity = (obj, opacity) => {
    obj.children.forEach((child) => {
      this.setOpacity(child, opacity);
    });
    if (obj.material) {
      obj.material.opacity = opacity;
    }
  }

  downAndFadeIn = (target, delay, callback) => {
    this.setOpacity(target, 0);
    target.position.set(0, 3, 0);

    const fadeInAnimation = new TWEEN.Tween(target.material)
      .to({ opacity: 1 }, 400)
      .easing(TWEEN.Easing.Circular.Out)
      .delay(delay)
      .onUpdate(() => {
        this.setOpacity(target, target.material.opacity);
        window.dispatchEvent(new Event('map_render'));
      });

    const dropAnimation = new TWEEN.Tween(target.position)
      .to({ y: 0 }, 400)
      .easing(TWEEN.Easing.Circular.Out)
      .delay(delay)
      .onStart(() => { this.stopAnimating(target, dropAnimation); })
      .onComplete(() => { delete target.userData.animation; });

    fadeInAnimation.start();
    dropAnimation.start().onStop(callback).onComplete(callback);
  }

  pulse = (target) => {
    const pulseAnimation = new TWEEN.Tween(target.scale)
      .to({ x: 1.3, y: 1.3, z: 1.3 }, 800)
      .easing(TWEEN.Easing.Elastic.Out)
      .onUpdate(() => {
        window.dispatchEvent(new Event('map_render'));
      })
      .onStart(() => { this.stopAnimating(target, pulseAnimation); })
      .onComplete(() => { delete target.userData.animation; });

    pulseAnimation.start();
  }

  stopAnimating = (target, newAnimation) => {
    if (target.userData.animation) {
      target.userData.animation.stop();
      delete target.userData.animation;
    }
    if (target.prev_position) {
      target.position.set(
        target.prev_position.x,
        target.prev_position.y,
        target.prev_position.z,
      );
    }
    if (target.prev_scale) {
      target.scale.set(
        target.prev_scale.x,
        target.prev_scale.y,
        target.prev_scale.z,
      );
    }
    if (newAnimation) {
      target.userData.animation = newAnimation;
    }
  }
}

export default new AnimationHelper();

