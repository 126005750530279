<template>
  <div
    :key="avatarKey"
    class="avatar--container"
  >
    <img
      v-if="!azurePhoto"
      class="avatar"
      :src="src"
      :height="height"
      :width="width"
      :class="[border ? 'avatar-border': null, rounded ? 'avatar-rounded': null]"
      :aria="aria"
    >
    <img
      v-if="azurePhoto"
      class="avatar"
      :src="azurePhoto"
      :height="height"
      :width="width"
      :class="[border ? 'avatar-border': null, rounded ? 'avatar-rounded': null]"
      :aria="aria"
    >
  </div>
</template>

<script>
import { getAzureProfilePhoto } from '@/util/api';
import UserState from '@/singletons/user.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';

export default {
  name: 'SettingsAvatar',
  props: {
    src: {
      type: String,
      required: false,
      default: '../../assets/logo/logo.svg',
    },
    aria: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '100px',
    },
    width: {
      type: String,
      required: false,
      default: '100px',
    },
    border: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      UserState,
      avatarKey: 0,
      azurePhoto: null,
      CompanyState,
    };
  },
  computed: {
    status() {
      return this.UserState.userStatus.Type;
    },
    statusIndicator() {
      return this.UserState?.userStatus?.BackgroundColor || null;
    },
  },
  created() {
    getAzureProfilePhoto().then((blob) => {
      if (blob && blob.data) {
        this.azurePhoto = URL.createObjectURL(blob.data);
      }
    });
  },
  methods: {
    loaded() {
      if (this.blobUrl) URL.revokeObjectURL(this.blobUrl);
    },
  },
};
</script>
