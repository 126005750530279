/* eslint-disable class-methods-use-this */
class ConnectState {
  constructor() {
    this.deviceIsAvailable = false;
    this.devicesRaw = [];
    this.devices = [];
    this.connectedDevices = [];
    this.selectedDevice = null;
    this.measurementSystem = 'METRIC';
    this.positionMode = null;
    this.sitHeight = null;
    this.standHeight = null;
    this.events = [];
    this.eventTimeout = null;
    this.scanInterval = null;
    this.pulsateTimeout = null;
    this.pulsateIntervalTimeout = 60 * 1000;
  }

  async destroy() {
    await this.handleShutDown();
    this.deviceIsAvailable = false;
    this.devicesRaw = [];
    this.devices = [];
    this.connectedDevices = [];
    this.selectedDevice = null;
    this.measurementSystem = 'METRIC';
    this.positionMode = null;
    this.sitHeight = null;
    this.standHeight = null;
    this.events = [];
    this.eventTimeout = null;
    this.scanInterval = null;
    this.pulsateIntervalTimeout = 60 * 1000;
  }

  async handleShutDown() {
    if (!this.selectedDevice) return;
    this.clearPulsateInterval();
    await this.selectedDevice.close();
  }

  clearPulsateInterval() {
    clearInterval(this.pulsateTimeout);
    this.pulsateTimeout = null;
  }
}

export default new ConnectState();
