// eslint-disable-next-line import/no-unresolved
// import CompanyState from '@/singletons/company.state.singleton';

import PhoneService from '@/Services/Phone/phone.service';
import {
  isAlreadyLoggedIn, isCompanion, isNative, checkZidInRouteExist,
  checkTypeInRouteExist, checkBidInRouteExist, isMobile, isMobileBrowser,
  checkIfUserHasReportRole, hasRoleIssueResolve, hasRoleUserRoles,
  isAbstractUser,
} from './guard.before';
import guards from './guard.multiple.setup';

export const ROUTE_SPLASH = '/splash';

export const ROUTE_LOCATION = '/location';

export const ROUTE_SEARCH = '/search';
export const ROUTE_SEARCH_DETAILS = '/search/details/:id';

export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGIN_SECTION = '/login/:section';
export const ROUTE_LOGIN_SECTION_TOKEN = '/login/:section/:token';

export const ROUTE_CALENDAR = '/calendar';
export const ROUTE_CALENDAR_BOOKING_DETAILS = '/calendar/booking/:bid';
export const ROUTE_CALENDAR_EDIT_TIME = 'edit/time';
export const ROUTE_CALENDAR_BOOKING_ATTENDEES = '/calendar/booking/:bid/attendees';

export const ROUTE_BOOK = '/book';
export const ROUTE_BOOK_TYPE = '/book/:type';
export const ROUTE_BOOK_FILTER = '/book/:type/filter';
export const ROUTE_BOOK_ZONE = '/book/:type/:zid';
export const ROUTE_BOOK_ZONE_NAVIGATOR = '/navigator/book/:type/:zid';
export const ROUTE_BOOK_INFORMATION = '/book/:type/:zid/information';
export const ROUTE_BOOK_INFORMATION_ISSUE = '/book/:type/:zid/information/Issue';
export const ROUTE_BOOK_CUSTOM = '/book/:type/:zid/custom';
export const ROUTE_BOOK_CUSTOM_ATTENDEES = '/book/:type/:zid/custom/attendees';
export const ROUTE_BOOK_CUSTOM_ORGANIZER = '/book/:type/:zid/custom/organizer';

export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_USER_STATUS = '/settings/user-status';
export const ROUTE_SETTINGS_AVATAR = '/settings/change-avatar';
export const ROUTE_SETTINGS_CHANGE_PASSWORD = '/settings/change-password';
export const ROUTE_SETTINGS_GENERAL = '/settings/general';
export const ROUTE_SETTINGS_UNITS = '/settings/units';
export const ROUTE_SETTINGS_PRIVACY = '/settings/privacy';

export const ROUTE_SETTINGS_NAVIGATOR = '/settings/navigator';
export const ROUTE_SETTINGS_SET_NAVIGATOR_POSITION = '/settings/navigator/position';

export const ROUTE_ADMIN = '/admin';
export const ROUTE_ADMIN_ESYNC = '/admin/esync';
export const ROUTE_ADMIN_DEVICE = '/admin/devices';

export const ROUTE_WELLNESS = '/controller';
export const ROUTE_WELLNESS_EDIT = 'edit';
export const ROUTE_CONTROLLER_POSITION = '/controller/position/:id';
export const ROUTE_CONTROLLER_POSITION_HEIGHT = '/controller/position/:id/height';
export const ROUTE_CONTROLLER_POSITION_NAME = '/controller/position/:id/name';
export const ROUTE_DESK_SETTINGS = '/controller/settings';
export const ROUTE_DESK_SETTINGS_UNITS = '/controller/settings/units';
export const ROUTE_DESK_SETTINGS_OFFSET = '/controller/settings/offset';
export const ROUTE_DESK_SETTINGS_DESKLIMITS = '/controller/settings/desklimits';
export const ROUTE_DESK_SETTINGS_SHORTCUTS = '/controller/settings/shortcuts';
export const ROUTE_CONTROLLER_COMPATIBLE_DESKS = '/controller/compatibledesks';

export const ROUTE_TIMER = '/timer';

export const ROUTE_MAP = '/map';

export const ROUTE_AZURE_CALLACK = '/callback/azure';

export const ROUTE_UPDATE_APP = '/update-app';
export const ROUTE_MOBILE_BROWSER = '/mobile-browser';

export const ROUTE_REPORT = '/report';
export const ROUTE_REPORT_PAGE = '/report/:page';
export const ROUTE_REPORT_PAGE_FILTERS = '/report/:page/filters';
export const ROUTE_REPORT_PAGE_TIMEFRAME = '/report/:page/timeframe';
export const ROUTE_REPORT_PAGE_LOCATION = '/report/:page/location';
export const ROUTE_REPORT_PAGE_RESOURCETYPE = '/report/:page/resourcetype';
export const ROUTE_REPORT_PAGE_RESOURCE = '/report/:page/resource';
export const ROUTE_REPORT_PAGE_EQUIPMENT = '/report/:page/equipment';
export const ROUTE_REPORT_PAGE_TAGS = '/report/:page/tags';

export const ROUTE_ADMIN_ISSUE_TRACKER = '/admin/issue-tracker';
export const ROUTE_ADMIN_PINNED_ISSUES = 'pinned-issues';
export const ROUTE_ADMIN_SPECIFIC_ISSUE = ':issueid';
export const ROUTE_ADMIN_RESOURCE_ISSUES = 'resources/:zoneid';

export const ROUTE_404 = '/404';

const routes = [
  {
    path: ROUTE_SPLASH,
    name: 'splash',
    meta: {
      splashScreen: true,
      sidebar: false,
      redirectRoute: 'agenda',
      hasPhoneView: true,
      module: PhoneService.modules.SPLASH,
    },
    component: () => import(/* webpackChunkName: "splash-index" */ '../views/loading/splash.view.vue'),
  },
  {
    path: ROUTE_LOGIN,
    name: 'login',
    meta: {
      sidebar: false,
      hasPhoneView: false,
    },
    component: () => import(/* webpackChunkName: "login-index" */ '../views/login/login.view.vue'),
    beforeEnter: guards([isAlreadyLoggedIn, isMobileBrowser]),
  },
  {
    path: ROUTE_LOGIN_SECTION,
    name: 'login-section',
    beforeEnter: guards([isAlreadyLoggedIn, isMobileBrowser]),
    meta: {
      sidebar: false,
      hasPhoneView: false,
    },
    component: () => import(/* webpackChunkName: "login-section-index" */ '../views/login/login.view.vue'),
  },
  {
    path: ROUTE_LOGIN_SECTION_TOKEN,
    name: 'login-section-token',
    beforeEnter: guards([isAlreadyLoggedIn, isMobileBrowser]),
    meta: {
      sidebar: false,
      hasPhoneView: false,
    },
    component: () => import(/* webpackChunkName: "login-section-token-index" */ '../views/login/login.view.vue'),
  },
  {
    path: ROUTE_CALENDAR,
    name: 'calendar',
    meta: {
      requiresCompanion: true,
      hasPhoneView: true,
      module: PhoneService.modules.CALENDAR,
    },
    beforeEnter: guards([isCompanion, isMobileBrowser]),
    components: {
      sideview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/calendar/calendar.view.vue'),
      phoneview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/calendar/calendar.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: '/agenda',
    name: 'agenda',
    redirect: ROUTE_CALENDAR,
  },
  {
    path: ROUTE_CALENDAR_BOOKING_DETAILS,
    name: 'booking-details',
    props: {
      sideview: true,
    },
    meta: {
      hasPhoneView: true,
      requiresAuth: true,
      requiresCompanion: true,
      module: PhoneService.modules.CALENDAR,
    },
    beforeEnter: guards([isCompanion, checkBidInRouteExist, isMobileBrowser]),
    components: {
      sideview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/calendar/components/calendar.booking.view.vue'),
      phoneview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/calendar/components/calendar.booking.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    children: [
      {
        path: ROUTE_CALENDAR_EDIT_TIME,
        name: 'calendar-booking-edit-time',
        beforeEnter: guards([checkBidInRouteExist, isMobileBrowser]),
        props: {
          sideview: true,
        },
        meta: {
          hasPhoneView: true,
          requiresAuth: true,
          requiresCompanion: true,
          module: PhoneService.modules.CALENDAR,
        },
        components: {
          default: () => import(/* webpackChunkName: "agenda-index" */ '@/views/book/booking.edit.datetime.view.vue'),
          sideview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/book/booking.edit.datetime.view.vue'),
          phoneview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/book/booking.edit.datetime.view.vue'),
          fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
        },
      },
      {
        path: ROUTE_CALENDAR_BOOKING_ATTENDEES,
        name: 'edit-book-attendees',
        beforeEnter: guards([checkBidInRouteExist, isMobileBrowser]),
        meta: {
          hasPhoneView: true,
          requiresCompanion: true,
          module: PhoneService.modules.CALENDAR,
        },
        components: {
          default: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/attendees.booking.view.vue'),
          sideview: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/attendees.booking.view.vue'),
          phoneview: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/attendees.booking.view.vue'),
          fullscreen: () => import(/* webpackChunkName: "map-view" */ '@/views/map/map.view.vue'),
        },
      },
    ],
  },
  {
    path: '/agenda/booking/:bid',
    redirect: ROUTE_CALENDAR_BOOKING_DETAILS,
  },
  {
    path: ROUTE_BOOK,
    name: 'book',
    beforeEnter: guards([isCompanion, isMobileBrowser]),
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "book-index" */ '@/views/book/book.view.vue'),
      phoneview: () => import(/* webpackChunkName: "book-index" */ '@/views/book/book.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_TIMER,
    name: 'timer',
    beforeEnter: guards([isNative, isCompanion, isMobileBrowser]),
    meta: {
      // hasPhoneView: true,
      requiresCompanion: true,
      // module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "book-index" */ '@/views/reminders.timer/reminder.timer.view.vue'),
      phoneview: () => import(/* webpackChunkName: "book-index" */ '@/views/reminders.timer/reminder.timer.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_TYPE,
    name: 'resources',
    beforeEnter: guards([checkTypeInRouteExist, isMobileBrowser]),
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "resources-index" */ '@/views/book/resources.view.vue'),
      phoneview: () => import(/* webpackChunkName: "resources-index" */ '@/views/book/resources.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_FILTER,
    name: 'booking-filters',
    beforeEnter: guards([checkTypeInRouteExist, isMobileBrowser]),
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "booking-filters-index" */ '@/views/book/resources.filter.view.vue'),
      phoneview: () => import(/* webpackChunkName: "booking-filters-index" */ '@/views/book/resources.filter.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_ZONE,
    name: 'quick-booking',
    beforeEnter: guards([checkTypeInRouteExist, checkZidInRouteExist, isMobileBrowser]),
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "quick-booking-index" */ '@/views/book/quick.booking.view.vue'),
      phoneview: () => import(/* webpackChunkName: "quick-booking-index" */ '@/views/book/quick.booking.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_ZONE_NAVIGATOR,
    name: 'navigator-quick-booking',
    beforeEnter: guards([checkTypeInRouteExist, checkZidInRouteExist, isMobileBrowser, isAbstractUser]),
    meta: {
      hasPhoneView: false,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "quick-booking-index" */ '@/views/book/navigator.booking.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_INFORMATION,
    name: 'book-information',
    beforeEnter: guards([checkTypeInRouteExist, checkZidInRouteExist, isMobileBrowser]),
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "information-resource-index" */ '@/views/book/resource.information.view.vue'),
      phoneview: () => import(/* webpackChunkName: "information-resource-index" */ '@/views/book/resource.information.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_CUSTOM,
    name: 'book-custom',
    props: true,
    beforeEnter: guards([checkTypeInRouteExist, checkZidInRouteExist, isMobileBrowser]),
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/custom.booking.view.vue'),
      phoneview: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/custom.booking.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_CUSTOM_ATTENDEES,
    name: 'book-attendees',
    beforeEnter: guards([checkTypeInRouteExist, checkZidInRouteExist, isMobileBrowser]),
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/attendees.booking.view.vue'),
      phoneview: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/attendees.booking.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_CUSTOM_ORGANIZER,
    beforeEnter: guards([checkTypeInRouteExist, checkZidInRouteExist, isMobileBrowser]),
    name: 'book-organizer',
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/organizer.booking.view.vue'),
      phoneview: () => import(/* webpackChunkName: "custom-booking-index" */ '@/views/book/organizer.booking.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_BOOK_INFORMATION_ISSUE,
    beforeEnter: guards([checkTypeInRouteExist, checkZidInRouteExist, isMobileBrowser]),
    name: 'book-issue',
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.BOOK,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "Issue-index" */ '@/views/book/resource.information.issue.view.vue'),
      phoneview: () => import(/* webpackChunkName: "Issue-index" */ '@/views/book/resource.information.issue.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_LOCATION,
    name: 'location',
    meta: {
      hasPhoneView: false,
      requiresCompanion: true,
    },
    beforeEnter: guards([isCompanion, isMobileBrowser]),
    components: {
      sideview: () => import(/* webpackChunkName: "location-index" */ '@/views/location.selection/location.selection.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_ADMIN,
    name: 'admin',
    meta: {
      requiresCompanion: true,
      // hasPhoneView: true,
      // module: PhoneService.modules.CALENDAR,
    },
    beforeEnter: guards([hasRoleUserRoles]),
    components: {
      sideview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/admin/admin.view.vue'),
      // phoneview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/admin/admin.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    children: [
    ],
  },
  {
    path: ROUTE_ADMIN_ISSUE_TRACKER,
    name: 'admin-issue-tracker',
    beforeEnter: guards([hasRoleUserRoles, hasRoleIssueResolve]),
    components: {
      sideview: () => import(/* webpackChunkName: "powerbi-index" */ '@/views/admin/issue/admin.issue.tracker.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
      // TODO - Implement phoneview?
    },
    children: [
      {
        path: ROUTE_ADMIN_PINNED_ISSUES,
        name: 'admin-pinned-issues',
        components: {
          default: () => import(/* webpackChunkName: "powerbi-index" */ '@/views/admin/issue/admin.pinned.issues.view.vue'),
          // TODO - Implement phoneview?
        },
      },
      {
        path: ROUTE_ADMIN_SPECIFIC_ISSUE,
        name: 'admin-specific-issue',
        components: {
          default: () => import(/* webpackChunkName: "powerbi-index" */ '@/views/admin/issue/admin.specific.issue.view.vue'),
          // TODO - Implement phoneview?
        },
      },
      {
        path: ROUTE_ADMIN_RESOURCE_ISSUES,
        name: 'admin-resource-specific-issues',
        components: {
          default: () => import(/* webpackChunkName: "powerbi-index" */ '@/views/admin/issue/admin.resource.specific.issues.view.vue'),
          // TODO - Implement phoneview?
        },
      },
    ],
  },
  {
    path: ROUTE_ADMIN_ESYNC,
    name: 'admin-esync',
    meta: {
      requiresCompanion: true,
      // hasPhoneView: true,
      // module: PhoneService.modules.CALENDAR,
    },
    beforeEnter: guards([hasRoleUserRoles]),
    components: {
      sideview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/admin/esync/admin.esync.side.view.vue'),
      // phoneview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/admin/admin.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/admin/esync/admin.esync.view.vue'),
    },
  },
  {
    path: ROUTE_ADMIN_DEVICE,
    name: 'admin-devices',
    beforeEnter: guards([hasRoleUserRoles]),
    components: {
      sideview: () => import(/* webpackChunkName: "agenda-index" */ '@/views/admin/device/admin.device.side.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/admin/device/admin.device.view.vue'),
    },
  },
  {
    path: ROUTE_WELLNESS,
    meta: {
      hasPhoneView: false,
      legacy: true,
      application: 'bookings',
    },
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "wellness-index" */ '@/views/device.controller/controller.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_DESK_SETTINGS,
    name: 'desk-settings',
    meta: {
      hasPhoneView: false,
      requiresCompanion: true,
    },
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "desk-settings-index" */ '@/views/device.controller/desk.settings.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_CONTROLLER_POSITION,
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/controller.position.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_CONTROLLER_POSITION_HEIGHT,
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/controller.position.height.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_CONTROLLER_POSITION_NAME,
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/controller.position.name.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_DESK_SETTINGS,
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/desk.settings.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_DESK_SETTINGS_UNITS,
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/desk.settings.units.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_DESK_SETTINGS_OFFSET,
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/desk.settings.offset.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_DESK_SETTINGS_DESKLIMITS,
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/desk.settings.limits.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  // {
  //   path: ROUTE_DESK_SETTINGS_SHORTCUTS,
  //   beforeEnter: isNative,
  //   components: {
  //     sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/desk.settings.shortcuts.view.vue'),
  //     fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
  //   },
  // },
  {
    path: ROUTE_CONTROLLER_COMPATIBLE_DESKS,
    beforeEnter: isNative,
    components: {
      sideview: () => import(/* webpackChunkName: "controller-index" */ '@/views/device.controller/controller.compatible.desks.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_SETTINGS,
    name: 'settings',
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      application: 'map',
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "settings-index" */ '../views/settings/settings.view.vue'),
      phoneview: () => import(/* webpackChunkName: "settings-index" */ '../views/settings/settings.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    beforeEnter: guards([isCompanion, isMobileBrowser]),
  },
  {
    path: ROUTE_SETTINGS_NAVIGATOR,
    name: 'settings-navigator',
    meta: {
      hasPhoneView: false,
      requiresCompanion: true,
      application: 'map',
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "settings-index" */ '../views/settings/navigator/settings.navigator.view.vue'),
      phoneview: () => import(/* webpackChunkName: "settings-index" */ '../views/settings/navigator/settings.navigator.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    beforeEnter: guards([isCompanion, isMobileBrowser, isAbstractUser]),
  },
  {
    path: ROUTE_SETTINGS_SET_NAVIGATOR_POSITION,
    name: 'settings-set-navigator-position',
    meta: {
      hasPhoneView: false,
      requiresCompanion: true,
      application: 'map',
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "settings-index" */ '../views/settings/navigator/settings.set.navigator.position.view.vue'),
      phoneview: () => import(/* webpackChunkName: "settings-index" */ '../views/settings/navigator/settings.set.navigator.position.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    beforeEnter: guards([isCompanion, isMobileBrowser, isAbstractUser]),
  },
  {
    path: ROUTE_SEARCH,
    name: 'search',
    meta: {
      hasPhoneView: false,
      requiresCompanion: true,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "find-index" */ '@/views/search/search.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    beforeEnter: guards([isCompanion, isMobileBrowser]),
  },
  {
    path: ROUTE_SEARCH_DETAILS,
    name: 'search-details',
    meta: {
      hasPhoneView: false,
      requiresCompanion: true,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "find-index" */ '@/views/search/components/search.user.details.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    beforeEnter: guards([isCompanion, isMobileBrowser]),
  },
  {
    path: ROUTE_SETTINGS_USER_STATUS,
    name: 'user-status',
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      application: 'map',
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "user-status-index" */ '@/views/settings/settings.user.status.view.vue'),
      phoneview: () => import(/* webpackChunkName: "user-status-index" */ '@/views/settings/settings.user.status.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    beforeEnter: guards([isCompanion, isMobileBrowser]),
  },
  {
    path: ROUTE_SETTINGS_AVATAR,
    name: 'change-avatar',
    meta: {
      hasPhoneView: true,
      application: 'map',
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "settings-change-avatar-index" */ '../views/settings/settings.change.avatar.view.vue'),
      phoneview: () => import(/* webpackChunkName: "settings-change-avatar-index" */ '../views/settings/settings.change.avatar.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_SETTINGS_CHANGE_PASSWORD,
    name: 'change-password',
    meta: {
      hasPhoneView: true,
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "settings-change-password-index" */ '../views/settings/settings.change.password.view.vue'),
      phoneview: () => import(/* webpackChunkName: "settings-change-password-index" */ '../views/settings/settings.change.password.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_SETTINGS_GENERAL,
    name: 'settings-general',
    meta: {
      hasPhoneView: true,
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "settings-change-password-index" */ '../views/settings/settings.general.view.vue'),
      phoneview: () => import(/* webpackChunkName: "settings-change-password-index" */ '../views/settings/settings.general.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_SETTINGS_UNITS,
    name: 'settings-units',
    meta: {
      hasPhoneView: true,
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "settings-index" */ '../views/settings/settings.units.view.vue'),
      phoneview: () => import(/* webpackChunkName: "settings-index" */ '../views/settings/settings.units.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_SETTINGS_PRIVACY,
    name: 'settings-privacy',
    meta: {
      application: 'map',
      hasPhoneView: true,
      module: PhoneService.modules.SETTINGS,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "settings-privacy-index" */ '../views/settings/settings.privacy.view.vue'),
      phoneview: () => import(/* webpackChunkName: "settings-privacy-index" */ '../views/settings/settings.privacy.view.vue'),
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
  },
  {
    path: ROUTE_MAP,
    name: 'map',
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
      module: PhoneService.modules.MAP,
    },
    components: {
      fullscreen: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
      phoneview: () => import(/* webpackChunkName: "map-view-index" */ '@/views/map/map.view.vue'),
    },
    /** Always check if application is loaded and existing. */
    beforeEnter: guards([isCompanion, isMobileBrowser]),
  },
  {
    path: '/app/map',
    name: 'app-map',
    redirect: ROUTE_MAP,
  },
  {
    path: '/app/bookings',
    name: 'app-bookings',
    redirect: ROUTE_BOOK,
  },
  {
    path: '/standalone/*',
    redirect: ROUTE_UPDATE_APP,
  },
  {
    path: '/standalone',
    redirect: ROUTE_UPDATE_APP,
  },
  {
    path: ROUTE_UPDATE_APP,
    name: 'update-app',
    meta: {
      hasPhoneView: true,
      requiresCompanion: true,
    },
    components: {
      sideview: () => import(/* webpackChunkName: "app-update-index" */ '@/views/MobileUsersReroute/MobileUsersReroute.vue'),
      phoneview: () => import(/* webpackChunkName: "app-update-index" */ '@/views/MobileUsersReroute/MobileUsersReroute.vue'),
    },
    beforeEnter: guards([isCompanion, isMobile]),
  },
  {
    path: ROUTE_MOBILE_BROWSER,
    name: 'mobile-browser',
    // meta: {
    //   hasPhoneView: true,
    //   requiresCompanion: true,
    // },
    component: () => import(/* webpackChunkName: "mobile-browser-index" */ '@/views/MobileUsersReroute/mobile.browser.reroute.vue'),
    // {
    //   fullscreen: () => import(/* webpackChunkName: "app-update-index" */ '@/views/MobileUsersReroute/MobileUsersReroute.vue'),
    //   sideview: () => import(/* webpackChunkName: "app-update-index" */ '@/views/MobileUsersReroute/MobileUsersReroute.vue'),
    //   phoneview: () => import(/* webpackChunkName: "app-update-index" */ '@/views/MobileUsersReroute/MobileUsersReroute.vue'),
    // },
    beforeEnter: guards([isCompanion]),
  },
  {
    path: ROUTE_AZURE_CALLACK,
    meta: {
      hasPhoneView: true,
    },
    name: 'callback-microsoft',
    component: () => import(/* webpackChunkName: "callback-microsoft-index" */ '../views/loading/splash.view.vue'),
  },
  {
    path: ROUTE_404,
    name: '404',
    components: {
      fullscreen: () => import(/* webpackChunkName: "notfound-index" */ '@/views/notfound/notfound.vue'),
      phoneview: () => import(/* webpackChunkName: "notfound-index" */ '@/views/notfound/notfound.vue'),
    },
  },
  {
    path: ROUTE_REPORT,
    name: 'report-main',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: ROUTE_REPORT_PAGE,
    name: 'report-page',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: ROUTE_REPORT_PAGE_FILTERS,
    name: 'report-page-filter',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.filters.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: ROUTE_REPORT_PAGE_TIMEFRAME,
    name: 'report-page-timeframe',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.timeframe.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: ROUTE_REPORT_PAGE_LOCATION,
    name: 'report-page-location-filter',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.location.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: ROUTE_REPORT_PAGE_RESOURCETYPE,
    name: 'report-page-resourcetype-filter',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.resourcetype.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: ROUTE_REPORT_PAGE_RESOURCE,
    name: 'report-page-resourcetype-filter',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.resource.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: ROUTE_REPORT_PAGE_EQUIPMENT,
    name: 'report-page-resourcetype-filter',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.equipment.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: ROUTE_REPORT_PAGE_TAGS,
    name: 'report-page-resourcetype-filter',
    components: {
      fullscreen: () => import(/* webpackChunkName: "report-powerbi-index" */ '@/views/report/report.view.vue'),
      sideview: () => import(/* webpackChunkName: "report-menu-index" */ '@/views/report/report.tags.sideview.vue'),
    },
    beforeEnter: guards([checkIfUserHasReportRole]),
  },
  {
    path: '/',
    redirect: ROUTE_CALENDAR,
  },
  {
    path: '*',
    redirect: ROUTE_404,
  },
];

export default routes;
