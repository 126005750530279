<template>
  <b-button
    :class="`btn ${size}`"
    :disabled="disabled"
    :type="type"
    :variant="variant"
    @click="debounceClick($event)"
  >
    <slot />
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';

const types = ['button', 'submit', 'reset', 'icon-button', 'round'];
const sizes = ['tiny', 'small', 'medium', 'big', 'icon', 'stretch', 'round', 'round medium'];
const variants = ['primary', 'success', 'secondary', 'danger', 'warning', 'pastel-primary', 'pastel-space', 'space', 'primary-reverse'];

export default {
  components: {
    BButton,
  },
  props: {
    aria: {
      type: String,
      required: false,
      default: 'button',
    },
    component: {
      type: String,
      required: false,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'button',
      validator: (value) => types.includes(value),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => variants.includes(value),
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: (value) => sizes.includes(value),
    },
  },
  data() {
    return {
      debounceActive: false,
    };
  },
  methods: {
    debounceClick(event) {
      if (!this.debounceActive) {
        this.$emit('click', event);
        this.debounceActive = true;
      }
      setTimeout(() => {
        this.debounceActive = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/breakpoints.scss';
.btn {
  &.tiny{
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.625rem 0.75rem;
    border-radius: 0.5rem;
    min-width: 0rem;
  }
  &.small {
    font-weight: 600;
    border-radius: 1rem;
    font-size: 0.9rem;
    padding: 0.625rem 0.5rem;
  }
   &.medium {
    font-weight: 600;
    border-radius: 1rem;
    font-size: 1rem;
    min-width: 12rem;
    padding: 0.6rem  1.2rem;
  }
   &.big {
    font-weight: 600;
    border-radius: 1rem;
    font-size: 1rem;
    min-width: 16rem;
    padding: 1rem 1rem;
  }
  &.stretch {
    font-weight: 600;
    font-size: 0.5rem;
    padding: 1rem 1rem;
    width: 100%;
  }

  &.icon {

    /** Font */
    font-size: 16px;
    line-height: 1;

    /** Size */
    min-width: unset;
    border-radius: 50%;
    border: none;

    /** Position */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@include phone {
  .btn {
    &.medium {
      font-size: 0.9rem;
      min-width: 10rem;
      padding: 0.8rem  1rem;
    }
  }
}

.btn {
  font-family: "inter";
  border-radius: 0.5rem;
  min-width: 10rem;
  color: white;
  &:disabled{
    background-color: var(--disabled) !important;
    border-color: unset !important;
    p {
      color: #fff !important;
    }
  }
  /* Needed to make the variants trigger - https://bootstrap-vue.org/docs/reference/color-variants */
  &-primary-reverse{
     color: var(--primary-color) !important;
     border: 2px solid var(--primary-color) !important;
    &:hover{
      color: var(--primary-color) !important;
      border: 2px solid var(--primary-color) !important;
    }
  }
  &-pastel-primary{
    color: var(--primary-color) !important;
    &:hover{
      color: var(--primary-color) !important;
    }
    &:disabled{
      border-color: #fff !important;
    }
  }
  &-pastel-space{
    color: var(--space-color) !important;
    &:hover{
      color: var(--space-color) !important;
    }
  }
  &-space{
    color: white;
    &:hover{
      color: white;
    }
  }
}

.btn.round {
  border-radius: 50%;

  &.medium {
    min-width: 6rem;
    min-height: 6rem;
  }
}
</style>
