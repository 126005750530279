import FloorCameraSettings from '@/classes/floor.camera.settings.class';
import UserState from '@/singletons/user.state.singleton';

class PreloadQueryParams {
  static loadAll() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    // Load all global parameters that should be loaded before any views.
    PreloadQueryParams.loadFloor(params);
    PreloadQueryParams.loadCameraView(params);
  }

  static loadFloor(params) {
    if (params.floor) {
      const floor = parseInt(params.floor, 10);
      if (!Number.isNaN(floor)) {
        // Set in memory saved floorplan id & update the stored value in local storage
        UserState.setFloorplanId(floor);
      }
    }
  }

  static loadCameraView(params) {
    if (params.camera) {
      // Get floor id that should get the camera view.
      // Note that if floor is in query param, that should be loaded before this.
      const floorId = UserState.getFloorplanId();
      if (floorId && !Number.isNaN(floorId)) {
        // Update the stored value in local storage. Camera view is not stored as
        // data source in the memory.
        FloorCameraSettings.saveFloorCamera(params.camera, floorId);
      }
    }
  }
}

export default PreloadQueryParams;
