<template>
  <div
    id="app"
  >
    <UserIdleVue
      v-if="UserState.user?.AbstractUser"
      :duration="30 * 1000"
      @userIdle="onUserIdle"
    />

    <!-- If path includes splash then we will route to loading.view.vue anyhow -->
    <LoadingView
      v-if="!UserState.hasLoadedOnce && !isLoginPath && !isSplashPath && !CompanyState.hasLoadedOnce && !isMobileBrowser"
      :should-navigate="false"
    />
    <!-- Beta badge -->
    <!-- <beta v-if="!isProduction" /> -->
    <!-- Staging badge -->
    <Staging v-if="isStaging" />

    <!-- Navigation -->
    <side-bar
      v-if="UserState.hasLoadedOnce && CompanyState.hasLoadedOnce && !PhoneService.isInPhoneApp() && !isMobileBrowser"
      :image-path="require('./assets/logos/logo_green.svg')"
      :default-logo="require('./assets/logos/logo_green.svg')"
    />

    <router-view name="animated" />

    <router-view />

    <div class="side-page-container">
      <div
        v-if="showCloseSidePageButton"
        class="button-close-side-page wdp__chevron-wrapper"
        @click="closeSidePage"
      >
        <icon
          icon="chevron-left"
          class="wdp__chevron"
        />
      </div>

      <transition
        name="side-page-transition"
        @after-enter="onSidePageTransition()"
        @after-leave="onSidePageTransition()"
      >
        <router-view
          v-if="showSidePage && !isLoginPath"
          name="sideview"
          class="side-view__content"
        />
      </transition>
    </div>

    <Fullscreen v-if="UserState.hasLoadedOnce && !isLoginPath && CompanyState.hasLoadedOnce && ZoneState.hasLoadedOnce && !PhoneService.isInPhoneApp()" />
    <!-- <router-view
      v-if="UserState.hasLoadedOnce && !isLoginPath && CompanyState.hasLoadedOnce && ZoneState.hasLoadedOnce && !PhoneService.isInPhoneApp()"
      name="fullscreen"
    /> -->

    <router-view
      v-if="UserState.hasLoadedOnce && !isLoginPath && CompanyState.hasLoadedOnce && ZoneState.hasLoadedOnce && PhoneService.isInPhoneApp()"
      name="phoneview"
      class="phone-view__content"
    />
  </div>
</template>

<script>
/* Preload global query params */
import PreloadQueryParams from '@/classes/preload.query.params.class';

/** State */
import CompanyState from '@/singletons/company.state.singleton';
import MapState from '@/singletons/map.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import RouterService from '@/Services/Router/router.service';
import ZoneState from '@/singletons/zone.state.singleton';
import QueryMixin from '@/mixins/query.mixin';
import PhoneService from '@/Services/Phone/phone.service';
import SettingsNavigatorState from '@/views/settings/navigator/settings.navigator.state';

/** Components */
import SideBar from './views/sidebar/side.bar.vue';
import Staging from './components/Beta/Staging.vue';
import LoadingView from './views/loading/splash.view.vue';
import Fullscreen from './views/fullscreen/fullscreen.vue';
import UserIdleVue from './views/utility/components/user.idle.vue';
import MapController from './classes/map/map.controller';
import MapNavigatorController from './classes/map/map.navigator.controller';

export default {
  components: {
    UserIdleVue,
    SideBar,
    Staging,
    LoadingView,
    Fullscreen,
  },
  mixins: [QueryMixin],
  data() {
    return {
      PhoneService,
      ZoneState,
      CompanyState,
      UserState,
      SettingsNavigatorState,
      MapState,
      MapController,
      MapNavigatorController,
      sidePageTransitionFinished: true, // Gets rid of render order glitch
    };
  },
  computed: {
    isMobileBrowser() { return !PhoneService.isInPhoneApp() && (/iPhone|iPad|iPod/i.test(navigator.userAgent) || /Android|ANDROID|android/i.test(navigator.userAgent)); },
    isLoginPath() { return this.$route.path.includes('login'); },
    isSplashPath() { return this.$route.path.includes('splash') || this.$route.path.includes('callback'); },
    isProduction() { return process.env.VUE_APP_MODE === 'production'; },
    isStaging() { return process.env.VUE_APP_MODE === 'staging'; },
    showCloseSidePageButton() {
      return this.showSidePage
        && this.sidePageTransitionFinished
        && !SettingsNavigatorState.pinSidePage;
      /*
      return !SettingsNavigatorState.pinSidePage
        && this.sidePageTransitionFinished
        && SettingsNavigatorState.showSidePage
        && UserState.hasLoadedOnce
        && !PhoneService.isInPhoneApp();
       */
    },
    showSidePage() {
      return (SettingsNavigatorState.pinSidePage || SettingsNavigatorState.showSidePage)
        && UserState.hasLoadedOnce
        && CompanyState.hasLoadedOnce
        && ZoneState.hasLoadedOnce
        && !PhoneService.isInPhoneApp();
    },
  },
  watch: {
    $route(to) {
      if (!to.params.fromIdle) {
        // Avoid handling route change if the route was made from going idle
        SettingsNavigatorState.showSidePage = true;
      }

      RouterService.setZoneStateFromRoute(this.$route);
    },
    // eslint-disable-next-line func-names
    'SettingsNavigatorState.showSidePage': function (state) {
      if (!state) this.sidePageTransitionFinished = false;
    },
  },
  created() {
    /** Initial auth check */
    PreloadQueryParams.loadAll();
    window.addEventListener('resize', this.handleWindowResize, false);
    document.addEventListener('click', this.removeQueryKeysIfNeeded);
    document.addEventListener('global_error_message', (evt) => {
      this.$bvToast.toast(evt.message, {
        autoHideDelay: 5000,
        variant: evt.variant,
        title: evt.title,
        solid: true,
        noCloseButton: false,
        showTop: true,
        toaster: PhoneService.isInPhoneApp() || UserState.user?.AbstractUser ? 'b-toaster-top-center' : 'b-toaster-top-right',
      });
    });

    this.afterUserStateLoaded();
  },
  methods: {
    async afterUserStateLoaded() {
      await UserState.finnishedLoading;
      if (UserState.user?.AbstractUser) {
        document.styleSheets[0].insertRule('.b-toaster-top-center { scale: 1.5; }', 0);
      }
    },
    handleRouteChange() {
      RouterService.setZoneStateFromRoute(this.$route);
    },
    removeQueryKeysIfNeeded(event) {
      const { type, zid } = this.$route.query;
      if (event.target?.id !== 'map-icon' && event.target?.firstElementChild?.id !== 'map-icon') {
        if (zid && zid === this.oldZid) {
          this.removeQuery('zid');
        } else if (type && type === this.oldType) {
          this.removeQuery('type');
        }
      }
      this.oldZid = zid === this.oldZid ? undefined : zid;
      this.oldType = type === this.oldType ? undefined : type;
    },
    closeSidePage() {
      if (!SettingsNavigatorState.pinSidePage) {
        SettingsNavigatorState.showSidePage = false;
      }
    },
    onSidePageTransition() {
      if (SettingsNavigatorState.idle) {
        MapNavigatorController.reset();
      }

      this.sidePageTransitionFinished = SettingsNavigatorState.showSidePage;
      MapController.handleWindowResize();
    },
    onUserIdle() {
      if (!UserState.user?.AbstractUser) {
        return;
      }

      if (SettingsNavigatorState.showSidePage && !SettingsNavigatorState.pinSidePage) {
        // Only allow hiding the side page on idle state & if not pinned
        SettingsNavigatorState.showSidePage = UserState.user?.AbstractUser && SettingsNavigatorState.pinSidePage;
      } else {
        // The event which closes the side menu will call reset, but only if it closes
        // Therefore we add this reset so the camera resets even when the side menu is already closed
        MapNavigatorController.reset();
      }

      if (this.$route.name !== 'search') {
        // Avoid redundant route
        this.$router.push({
          name: 'search',
          params: { fromIdle: true },
        });
      }
    },
    handleWindowResize() {
      if (document.body.clientWidth < 1025
        && SettingsNavigatorState.showSidePage
        && !SettingsNavigatorState.pinSidePage) {
        SettingsNavigatorState.showSidePage = SettingsNavigatorState.pinSidePage;
      }
    },
  },
};

</script>
<style lang="scss">
#app{
  overflow: hidden;
  img, a {
    user-select: none;
    -webkit-user-drag: none;
  }
}
.phone-view__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  overflow-y: auto;
}

.b-toast-danger {
  .toast-body {
      border-color:  var(--bootstrap-toast--danger--border-color) !important;
      background-color: var(--bootstrap-toast--danger--background) !important;
      color: white var(--bootstrap-toast--danger--color) !important;
      box-shadow: unset;
  }
}

.side-page-container {
  position: relative;
  .button-close-side-page {
    position: absolute;
    z-index: 999;
    right: -1rem;
    top: calc(80px + 1rem);
    background-color: var(--primary-color);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
    padding: 0.2rem 0.6rem;
    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 40%);

    &:hover {
      background-color: var(--primary-color-dark);
    }

    .wdp__chevron {
      color: white;
      margin: auto;
      width: 80%;
      height: 100%;
    }
  }
}

// TODO: SET THE RIGHT SUCCESS COLOR, haven't gotten any from Eunah
// .b-toast-success {
//   .toast-body {}
// }

.tooltip  {
  &.right{
    .arrow::before{
      border-right-color: var(--resource-pastel-color) !important;
    }
  }
  &.bottom{
    .arrow::before{
      border-bottom-color: var(--resource-pastel-color) !important;
    }
  }
}
.tooltip-inner {
  background-color: var(--resource-pastel-color) !important;
  color: var(--primary-font-color) !important;
}

.tooltip.b-tooltip {
  opacity: 1;
}
</style>
