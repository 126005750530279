<template>
  <div class="booking__container">
    <div class="booking__header">
      <h5>{{ header }}</h5>
    </div>
    <div
      v-for="(row, index) in rows"
      :key="index"
      class="booking__info-row"
      @click="rowClicked(row)"
    >
      <h6 v-if="row.label">
        {{ row.label }}:
      </h6>
      <svg-icon
        v-if="row.iconSrc === 'resource'"
        :src="row.icon"
      />
      <icon
        v-if="row.iconSrc === 'fa'"
        :icon="row.icon"
      />
      <h6 class="info">
        {{ row.info }}
      </h6>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['booking', 'header', 'rows'],
  methods: {
    rowClicked(row) {
      if (row.event) {
        this.$emit(row.event);
      }
    },
  },
};
</script>
