const supportedLocales = {
  en: 'English',
  sv: 'Swedish',
  de: 'German',
};

export function supportedLocalesInclude(locale) {
  return Object.keys(supportedLocales).includes(locale);
}

export default supportedLocales;
