/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-continue */

import BookingService from '@/Services/Bookings/bookings.service';
import UserState from '@/singletons/user.state.singleton';
import WeekState from '@/singletons/week.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import ExtendedDate from '@/classes/extended.date.class';
import CompanyService from '@/Services/Company/company.service';
import { getResourcesOfType } from '@/functions/resources.functions';
import { getRealmStates } from '@/util/api';

const hasOverlappingReference = (reference, booking) => (
  (reference.From === booking.From && reference.Until === booking.Until) // .. Equals.
  || (reference.From >= booking.From && reference.Until <= booking.Until) // . Reference wraps booking.
  || (booking.From >= reference.From && booking.Until <= reference.Until) // . Booking wraps reference.
  || (reference.Until > booking.From && reference.Until < booking.Until) // .. Reference ends inside booking.
  || (reference.From > booking.From && reference.From < booking.Until) // .... Reference starts inside booking.
);

const resourceHasActiveTimeFilter = (resource) => {
  // If until filter is in past always return false
  const now = new ExtendedDate().getTime();
  if (ZoneState.referenceBooking.Until < now) {
    return false;
  }
  // Return false if a booking is overlapping
  const bookings = BookingService.getBookingsByZid(resource.Zid);
  // eslint-disable-next-line no-restricted-syntax
  for (const booking of bookings) {
    if (hasOverlappingReference(ZoneState.referenceBooking, booking)) {
      return false;
    }
  }
  return true;
};

const resourceHasActiveHasFilter = (equipment) => {
  const eqTitles = equipment.flat().map(({ Title }) => Title);
  const activeHasFilters = ZoneState.activeHasEquipmentFilters;
  return activeHasFilters.every(((filter) => eqTitles.includes(filter)));
};

const resourceHasActiveAmountFilter = (equipment) => {
  const resourceEquipments = {};
  equipment.forEach((eq) => {
    if (typeof eq.Value === 'string') {
      resourceEquipments[eq.Title] = Number(eq.Value);
    }
  });
  const activeAmountFilters = ZoneState.activeAmountEquipmentFilters;
  const filterBools = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(activeAmountFilters)) {
    if (resourceEquipments[key] && resourceEquipments[key] >= value) filterBools.push(true);
    else filterBools.push(false);
  }
  // No active filtes
  if (filterBools.length === 0) return true;
  return filterBools.every((el) => el === true);
};

class ZoneStateService {
  static async initState() {
    await this.fetchRealmState();
    await CompanyService.setZonesOnFloor(Number(UserState.savedFloorplanId));
    await BookingService.getBookingsOfDay(WeekState.date);
    ZoneState.hasLoadedOnce = true;
    ZoneStateService.updateState();
  }

  /* Sets when route updates in app.vue from router service */
  static setState(type, zid, date, hasFilters, amountFilters, from, until, queryZid, ignoreType) {
    UserState.selectedDate = date;
    ZoneState.activeZoneFilter = zid;
    ZoneState.activeZoneQueryFilter = queryZid;
    ZoneState.resourceTypeFilter = type;
    ZoneState.activeHasEquipmentFilters = hasFilters;
    ZoneState.activeAmountEquipmentFilters = amountFilters;
    ZoneState.activeDate = date;
    ZoneState.activeFrom = from;
    ZoneState.activeUntil = until;
    ZoneState.ignoreType = ignoreType;
    ZoneStateService.updateState();
  }

  static updateState() {
    if (!ZoneState.hasLoadedOnce || !CompanyState.hasLoadedOnce) return;
    ZoneState.resourcesOfActiveType = getResourcesOfType(ZoneState.resourceTypeFilter);
    ZoneState.referenceBooking = ZoneStateService.setReferenceBooking(ZoneState.activeFrom, ZoneState.activeUntil);
    // Depends on url params zid/type
    ZoneState.activeResources = ZoneStateService.setActiveResources();
    // Depends on url query zid
    ZoneState.highlightedResources = ZoneStateService.setHighlightedResources();
    ZoneStateService.startInvalidateAvailabilityTimerIfNeeded();
  }

  static setReferenceBooking(_from, _until) {
    if (!_from || !_until) return null;
    const from = _from;
    const until = _until;
    return { From: from, Until: until };
  }

  static setHighlightedResources() {
    const typeFilter = ZoneState.resourceTypeFilter;
    // // zid from query
    // const qZid = ZoneState.activeZoneQueryFilter;
    // // zid from params
    // const pZid = ZoneState.activeZoneFilter;
    const allResources = CompanyState.zones;
    const resources = (typeFilter && !ZoneState.ignoreType) ? getResourcesOfType(typeFilter) : allResources;

    // No resources return empty array
    if (!resources) return [];
    // if (ZoneState.ignoreType) return allResources;

    // Filter
    const resourcesWithHasFilter = resources?.filter(({ Equipment }) => resourceHasActiveHasFilter(Equipment));
    const resourcesWithAmountFilters = resourcesWithHasFilter.filter(({ Equipment }) => resourceHasActiveAmountFilter(Equipment));
    let resourcesWithFilters = resourcesWithAmountFilters;
    if (ZoneState.referenceBooking?.Until) resourcesWithFilters = resourcesWithAmountFilters.filter((resource) => resourceHasActiveTimeFilter(resource));
    return resourcesWithFilters;

    // If we have zid filter out resource of that zid (maybe use in futre?)
    // if (qZid || pZid) return [resourcesWithFilters?.find((r) => r.Zid === (qZid || pZid)))];
  }

  static setActiveResources() {
    if (!ZoneState.resourceTypeFilter) return CompanyState.zones;
    const resourcesWithHasFilter = getResourcesOfType(ZoneState.resourceTypeFilter)?.filter(({ Equipment }) => resourceHasActiveHasFilter(Equipment));
    const resourcesWithFilters = resourcesWithHasFilter?.filter(({ Equipment }) => resourceHasActiveAmountFilter(Equipment));
    let resources = resourcesWithFilters;
    if (ZoneState.referenceBooking?.Until) {
      resources = resourcesWithFilters.filter((resource) => resourceHasActiveTimeFilter(resource));
    }
    const zid = ZoneState.activeZoneFilter;
    if (zid) return [resources?.find((r) => r.Zid === zid)];
    return resources;
  }

  static startInvalidateAvailabilityTimerIfNeeded() {
    if (!ZoneState.availabilityStateUpdateTimerHandle) {
      ZoneState.availabilityStateUpdateTimerHandle = setInterval(ZoneStateService.invalidateZoneAvailabilityStates, ZoneState.INVALIDATE_TIMER_TICK_MS);
    }
    ZoneStateService.invalidateZoneAvailabilityStates();
  }

  // MARK: - Fetch realm state

  static async fetchRealmState() {
    const { data } = (await getRealmStates() || []);
    ZoneState.realmState = data;
    ZoneStateService.invalidateZoneAvailabilityStates();
    return data;
  }

  static getRealmStateForZone(zid) {
    for (let index = 0; index < ZoneState.realmState.length; index += 1) {
      const state = ZoneState.realmState[index];
      if (state.Zid === zid) {
        return state;
      }
    }
    return null;
  }

  static updateRealmState(zid, state) {
    let hasModified = false;
    for (let index = ZoneState.realmState.length - 1; index >= 0; index -= 1) {
      const currentState = ZoneState.realmState[index];
      if (currentState.Zid === zid) {
        if (!state) {
          ZoneState.realmState.splice(index, 1);
        } else {
          ZoneState.realmState.splice(index, 1, state);
        }
        hasModified = true;
        break;
      }
    }
    if (!hasModified && state) {
      ZoneState.realmState.push(state);
    }
  }

  // MARK: - Set state of zone based on bookings

  static invalidateZoneAvailabilityStates(inputZones) {
    if (!ZoneState.hasLoadedOnce || !CompanyState.hasLoadedOnce || !UserState.selectedFloorplan) return;
    let zones = inputZones;

    if (!zones || zones.length <= 0) {
      const { selectedFloorplan } = UserState;
      zones = CompanyState.zones.filter((zone) => zone.ParentZone === selectedFloorplan.Zid);
    }

    const zoneStates = {};

    for (let index = 0; index < zones.length; index += 1) {
      const zone = zones[index];
      const bookingsForZone = BookingService.getBookingsByZid(zone.Zid);
      const zoneRealmState = ZoneStateService.getRealmStateForZone(zone.Zid);
      // Set default
      if (!zoneStates[zone.Zid]) {
        zoneStates[zone.Zid] = {};
      }
      const state = zoneStates[zone.Zid];
      const selectedDate = new ExtendedDate(UserState.selectedDate);


      // PASTE
      const isToday = (Number.isNaN(selectedDate.getTime()) || selectedDate.isToday());
      let timepointForState;
      if (isToday) {
        timepointForState = Date.now();
      } else {
        timepointForState = selectedDate.getTime();
      }

      // Assume free, will set other states in the nextcoming checks
      state.Status = ZoneState.ZONE_STATE.AVAILABLE;
      state.MapColor = ZoneState.ZONE_MAP_COLOR.GREEN;
      state.Highlighted = true;

      // Gery out if resource isn't in activeResources (only needed if type filter)
      const highlightedZids = ZoneState.highlightedResources.map(({ Zid }) => Zid);
      if (!highlightedZids.includes(zone.Zid)) {
        state.Highlighted = false;
      }

      // If zone is space
      if (zone.Properties?.type === 'space') {
        state.Status = ZoneState.ZONE_STATE.SPACE;
        state.MapColor = ZoneState.ZONE_MAP_COLOR.BLUE;
        continue;
      }

      // Non bookable resource, with active state.
      if (isToday && !zone.Bookable && zoneRealmState && zoneRealmState.Active) {
        state.Status = ZoneState.ZONE_STATE.OCCUPIED;
        state.MapColor = ZoneState.ZONE_MAP_COLOR.RED;
        continue;
      }

      // Non bookable resource, with no device.
      if (!zone.Bookable && zone.Devices.length < 1) {
        state.Status = ZoneState.ZONE_STATE.UNKNOWN;
        state.MapColor = ZoneState.ZONE_MAP_COLOR.OTHER;
        continue;
      }

      // If we have a reference booking but it is in the past
      const referenceUntil = ZoneState.referenceBooking?.Until;
      const now = new ExtendedDate().getTime();
      if (referenceUntil && referenceUntil < now) {
        state.Status = ZoneState.ZONE_STATE.NOT_IN_FILTER;
        state.MapColor = ZoneState.ZONE_MAP_COLOR.NOT_IN_FILTER;
        state.Highlighted = false;
        continue;
      }

      // Check if there are active bookings for the zones.
      for (let bookIndex = 0; bookIndex < bookingsForZone.length; bookIndex += 1) {
        const booking = bookingsForZone[bookIndex];

        if (!ZoneState.referenceBooking) {
          // Active bookings
          if (booking.From <= timepointForState && booking.Until > timepointForState) {
            // Yellow assumed at first.
            state.Status = ZoneState.ZONE_STATE.AWAY;
            state.MapColor = ZoneState.ZONE_MAP_COLOR.YELLOW;

            // Active device state, turn occupied
            if (zone.Devices.length > 0 && zoneRealmState && zoneRealmState.Active) {
              state.Status = ZoneState.ZONE_STATE.OCCUPIED;
              state.MapColor = ZoneState.ZONE_MAP_COLOR.RED;

            // Or, if no devices and checked in, turn occupied
            } else if (zone.Devices.length < 1 && booking.CheckIn && booking.CheckIn !== null) {
              state.Status = ZoneState.ZONE_STATE.OCCUPIED;
              state.MapColor = ZoneState.ZONE_MAP_COLOR.RED;
            }

          // 15min before booking, turn yellow.
          // TODO: MAP, 15min hardcoded as YELLOW status on map. Might need to be moved or changed.
          } else if ((booking.From - 900000) <= timepointForState && booking.Until > timepointForState) {
            state.Status = ZoneState.ZONE_STATE.AWAY;
            state.MapColor = ZoneState.ZONE_MAP_COLOR.YELLOW;
          }
        } else {
          // Handle, bookable resource, with filter active.
          // Show colors depending on overlap & device / session state
          // console.log(ZoneState.referenceBooking.Until, new ExtendedDate().gettime());
          const isOverlappingReference = hasOverlappingReference(ZoneState.referenceBooking, booking);

          if (isOverlappingReference) {
            state.Status = ZoneState.ZONE_STATE.AWAY;
            state.MapColor = ZoneState.ZONE_MAP_COLOR.YELLOW;
            if (ZoneState.referenceBooking.From > Date.now()) continue;

            if (zone.Devices.length > 0 && zoneRealmState && zoneRealmState.Active) {
              state.Status = ZoneState.ZONE_STATE.OCCUPIED;
              state.MapColor = ZoneState.ZONE_MAP_COLOR.RED;
            } else if (zone.Devices.length < 1 && booking.CheckIn && booking.CheckIn !== null) {
              state.Status = ZoneState.ZONE_STATE.OCCUPIED;
              state.MapColor = ZoneState.ZONE_MAP_COLOR.RED;
            }
          }
        }
      }
    }

    // Update zone state
    ZoneState.zoneStates = zoneStates;
    ZoneState.zoneStateChanged += 1;
  }
}

export default ZoneStateService;
