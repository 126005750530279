<template>
  <div
    class="round-resource-icon__wrapper flex flex-center"
    :class="type"
  >
    <svg-icon
      class="icon-size-medium"
      :class="type"
      :src="src"
      :style="iconStyle"
    />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Valid inputs:
     * connection, resource, resource reverse, average booking, average utilization
     */
    type: {
      type: String,
      required: false,
      default: 'standard',
    },
    iconStyle: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss">
  .round-resource-icon__wrapper {
    height: 2.5rem;
    width: 2.5rem;
    &.standard{
      border-radius: 50%;
      border: var(--border);
    }
    &.settings,
    &.resource {
      border-radius: 50%;
      background-color: var(--resource-pastel-color);
        &.reverse {
        background-color: white;
        border: 1px solid  var(--primary-color);
        }
        &.reverse-fill {
          border: 1px solid var(--primary-color);
        }
    }

    &.settings {
      width: 2.25rem;
      height: 2.25rem;
    }

    &.connection{
      border-radius: 20%;
      background-color: var(--color-pastel-resource-occupied);
    }
    &.available{
      border-radius: 50%;
      background-color: var(--color-pastel-resource-free);
    }
    &.away{
      border-radius: 50%;
      background-color: var(--color-pastel-resource-away);
    }
    &.unkown{
      border-radius: 50%;
      background-color: var(--color-pastel-resource-other);
    }
    &.occupied{
      border-radius: 50%;
      background-color: var(--color-pastel-resource-occupied);
    }
    &.issue-icon{
      border-radius: 50%;
    }
    &.average {
      width: 3.5rem;
      height: 3.5rem;
      &.booking {
        border-radius: 50%;
        background-color: #F7E9E0;
      }
      &.utilization {
        border-radius: 50%;
        background-color: #E0EAF9;
      }
    }
    .icon {
      svg{
        padding: 0.2rem;
      }
      &.standard svg{
        color: var(--secondary-font-color);
        fill: var(--secondary-font-color);
      }
      &.resource svg{
        color: var(--primary-color);
        fill: var(--primary-color);
      }
      &.settings svg{
        width: 1.125rem;
        height: 1.125rem;
        display: flex;
        padding: unset;
        color: var(--primary-color);
        fill: var(--primary-color);
      }
      &.connection svg{
        color: var(--occupied-color);
        fill: var(--occupied-color);
      }
      &.available svg{
        color: var(--free-color);
        fill: var(--free-color);
      }
      &.away svg{
        color: var(--away-color);
        fill: var(--away-color);
      }
      &.unkown svg{
        color: var(--other-color);
        fill: var(--other-color);
      }
      &.occupied svg{
        color: var(--occupied-color);
        fill: var(--occupied-color);
      }
      &.average {
        &.booking svg{
          width: 2rem;
          height: 2rem;
          color: #ED5106;
          fill: #ED5106;
        }
        &.utilization svg {
          width: 2rem;
          height: 2rem;
          color: #0E4378;
          fill: #0E4378;
        }
      }
    }

    &.skeleton {
      .icon.settings svg {
        color: var(--other-color);
        fill: var(--other-color);
      }
    }
  }
</style>
