import ExtendedDate from '../classes/extended.date.class';

class WeekState {
  constructor() {
    this.date = null;
    this.weekLength = null;
    this.weekStart = null;
    this.weekEnd = null;
    this.startingDay = null;
  }

  init(date = new ExtendedDate(), len = 5, startingDay = 1) {
    this.date = date;
    this.weekLength = len;
    this.startingDay = startingDay;
    this.changeWeek(this.date);
  }

  changeWeek(date) {
    this.date = date;
    this.weekStart = this.date.getWeekStart(this.startingDay);
    this.weekStart.setHours(0, 0, 0, 0);
    this.weekEnd = this.weekStart.add('days', this.weekLength - 1);
    this.weekEnd.setHours(24, 0, 0); // 23.59 doesnt give last days bookings.....
  }

  changeDate(date) {
    this.setDate(date);
    this.changeWeek(date);
  }

  /**
   * @param {*} dateStr MUST BE 'yyyy-mm-dd' or date object
   */
  setDate(date) {
    if (typeof date === 'object') {
      this.date = date;
      return;
    }
    const [yy, mm, dd] = date.split('-');
    const d = new ExtendedDate();
    d.setFullYear(yy);
    d.setMonth(mm - 1, dd);
    d.setDate(dd);
    this.date = d;
  }
}

export default new WeekState();
