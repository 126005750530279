import Vue from 'vue';

function pascalCase(string) {
  return string
    .replace(/[-_](.)/g, (_, char) => char.toUpperCase()) // Convert dash/underscore followed by a character to uppercase
    .replace(/^[a-z]/, (char) => char.toUpperCase()) // Capitalize the first letter
    .replace(/([A-Z]+)([A-Z][a-z])/g, (match, p1, p2) => p1.charAt(0) + p1.slice(1).toLowerCase() + p2); // Handle acronyms like SVG to SvgIcon
}
/**
 * Requiring multiple base elements at once instead of importing
 * component in each file.
 */

const requireComponent = require.context(
  '.', true, /[\w-]+\.vue$/,
);

requireComponent.keys().forEach((fileName) => {
  // Get Component by file and extracting config.
  const componentConfig = requireComponent(fileName);
  // Get component as PascalCase.
  const splitName = fileName.split('/').pop();
  // Transform to PascalCase
  const componentName = pascalCase(
    splitName.replace(/^\.\//, '').replace(/\.\w+$/, ''),
  );
  // Register each component globally.
  Vue.component(componentName, componentConfig.default || componentConfig);
});
