/* eslint-disable class-methods-use-this */
import UserState from '@/singletons/user.state.singleton';
import AuthState from '@/singletons/auth.state.singleton';
import WeekState from '@/singletons/week.state.singleton';
// import CompanyState from '@/singletons/company.state.singleton';
// eslint-disable-next-line import/no-cycle
// import router from '@/router';
import {
  setUserStatus, getUserBookings, getColleagues, updateUserProfile, deleteUserAvatar, updateUserAvatar,
  getUserSmall,
} from '../../util/api';
import ExtendedDate from '../../classes/extended.date.class';

const A_YEAR_MILLISECONDS = 3.154 * 10 ** 10;
// const MONTH_MILLIS = 1000 * 60 * 60 * 24 * 31;
// const checkAttendees = (booking, username) => booking.Attendees && booking.Attendees.some((att) => att.Username === username);
// const filterByUsername = (username) => (booking) => booking.Owner === username || checkAttendees(booking, username);
const filterOutByStatus = (booking) => booking.Status !== 'END' && booking.Status !== 'NOSHOW';

class UserService {
  static async initState(user) {
    UserState.init(user);
    await Promise.all([
      UserService.fetchUserBookings(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime()),
    ]).then(() => {
      UserState.hasLoadedOnce = true;
      UserState.finnishedLoadingPromiseResolver(true);
    });
  }

  async saveProfile(key, value) {
    this.profile[key] = value;
    this.profile.LastModified += 1;
    // console.log('Puttin profile: ', this.profile);
    const response = await this.putProfile(this.profile, this.username);
    return response;
  }

  static async putProfile(profile, username) {
    const response = await updateUserProfile(profile, username);
    return response;
  }

  static getUserAuthString(param) {
    if (param.includes('?')) {
      return `&username=${UserState.username}&token=${AuthState.getToken()}`;
    }
    return `?username=${UserState.username}&token=${AuthState.getToken()}`;
  }

  static hasAllRoles(roles) {
    return roles.every(
      (r) => UserState.user.Roles.Granted.includes(r),
    );
  }

  static updateAvatar(username, fd) {
    const user = username || UserState.username;
    updateUserAvatar(user, fd);
  }

  static async deleteAvatar(username) {
    const user = username || UserState.username;
    deleteUserAvatar(user);
  }

  static async getCollegues() {
    const { data } = await getColleagues();
    return data || null;
  }

  static async getUserByUsername(username) {
    const { data } = await getUserSmall(username);
    return data;
  }

  static addBooking(booking) {
    const isAttendee = booking.Attendees.some(({ Username }) => Username === UserState.username);
    if (booking.Owner !== UserState.username && !isAttendee) return;
    const exists = UserState.bookings.find((b) => b.Bid === booking.Bid);
    if (exists) return;
    UserState.bookings.push(booking);
  }

  static updateBooking(booking) {
    const exists = UserState.bookings.find((b) => b.Bid === booking.Bid);
    if (!exists) return;
    Object.assign(exists, booking);
    UserState.bookings = UserState.bookings.filter(filterOutByStatus);
  }

  static removeBooking(bid) {
    const exists = UserState.bookings.find((b) => b.Bid === bid);
    if (!exists) return;
    UserState.bookings.splice(UserState.bookings.indexOf(exists), 1);
  }

  static async getCachedUserBooking(bid) {
    const booking = UserState.bookings.find(({ Bid }) => Bid === bid);
    if (booking) { return booking; }
    try {
      // No api request to get all bookings from user, needs from and until
      const from = new ExtendedDate().setHours(0, 0, 0, 0);
      const until = from + A_YEAR_MILLISECONDS / 4;
      const { data: fetchedBookings } = await getUserBookings(UserState.username, from, until);
      const fetchedBooking = fetchedBookings.find(({ Bid }) => Bid === bid);
      return fetchedBooking;
    } catch (e) {
      console.log('Error fetching all user bookings', e);
      return null;
    }
  }

  static async fetchUserBookings(from, until) {
    const { data: bookings } = await getUserBookings(UserState.username, from, until);
    UserState.bookings = bookings.filter(filterOutByStatus);
  }

  static async fetchUserBookingsForLocalUse(from, until) {
    const { data: bookings } = await getUserBookings(UserState.username, from, until);
    return bookings;
  }

  static setStatus(status) {
    UserState.userStatus = status;
    setUserStatus(status, UserState.username);
  }

  static updateUserStatus(status) {
    UserState.userStatus = status;
  }

  static setProfile(profile) {
    UserState.profile = profile;
  }

  static async saveProfile(key, value, username) {
    const user = username || UserState.username;
    UserState.profile[key] = value;
    UserState.profile.LastModified += 1;
    const response = await updateUserProfile(user, UserState.profile);
    return response;
  }

  static setFloorplan(floorplan) {
    UserState.selectedLocation = floorplan;
    UserState.selectedFloorplan = floorplan?.floor;
    UserState.savedFloorplanId = floorplan ? floorplan.floor.Zid : -1;
    localStorage.setItem('floor', UserState.savedFloorplanId);
  }

  static setCid(cid) {
    UserState.selectedCid = cid;
    UserState.savedCid = cid;
    localStorage.setItem('cid', cid);
  }

  static setAutoStatus() {
    UserState.userStatus.IsAuto = true;
    UserService.setStatus({ IsAuto: true }, UserState.username);
    // this.changeStatus(this.userStatus);
  }

  static changeStatus(newStatus) {
    UserState.userStatus = newStatus;
    UserService.setStatus(newStatus, UserState.username);
  }

  static updateUser(user) {
    UserState.init(user);
  }

  static destroy() {
    UserState.userStatus = null;
    UserState.username = null;
    UserState.profile = null;
    UserState.avatar = null;
    UserState.user = null;
    UserState.parent = null;
    UserState.hasLoadedOnce = false;
    UserState.calendar = [];
  }

  /** User Status */
}

export default UserService;
