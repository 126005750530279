import axios from 'axios';
import { shouldIncludeAzureBearerToken, requestAzureBeareToken } from '../plugin/msal.functions';

const apiClient = axios.create({
  withCredentials: true,
  crossDomain: true,
});

export const apiLoginClient = axios.create({
  responseType: 'json',
  withCredentials: true,
  crossDomain: true,
});

// const requestAccessToken = () => apiClient.get(`${process.env.VUE_APP_BASE_URL}/api/refresh`, { withCredentials: true });

apiClient.interceptors.request.use(async (config) => {
  /** Default headers */

  let { headers } = config;

  headers = ({
    'idesk-auth-method': 'up',
    'idesk-auth-username': localStorage.getItem('auth_username'),
    'idesk-auth-password': localStorage.getItem('auth_password'),
    Accept: '*',
    'Content-Type': 'application/vnd.idesk-v5+json',
    ...headers,
  });

  if (localStorage.getItem('auth_token')) {
    headers['idesk-auth-token'] = localStorage.getItem('auth_token');
  }

  /** Request Url */
  const { url } = config;

  /** Sends token if authentication method is set to 'azure' */
  if (shouldIncludeAzureBearerToken(url)) {
    const bearer = await requestAzureBeareToken();
    headers.Authorization = `Bearer ${bearer}`;
  }

  Object.assign(config, { headers, withCredentials: true });
  return config;
});

apiClient.interceptors.response.use((response) => response, async (error) => {
  console.log(error.response);
  if (error?.response?.status === 401) {
    if (error.response?.data?.Message === 'Token expired') {
      window.dispatchEvent(new Event('cookie:expired'));
    }
  }
  throw error;
});

export default apiClient;
