import ExtendedDate from '@/classes/extended.date.class';

const defaultAvatarUri = 'https://storage.googleapis.com/dev-assets.riocompanion.com/avatar/default.png';

class UserState {
  constructor() {
    this.userStatus = null;
    this.username = null;
    this.profile = {
      MeasurementSystem: 'METRIC',
      shortcutstand: 'CmdOrCtrl+Shift+PageUp',
      shortcutsit: 'CmdOrCtrl+Shift+PageDown',
      shortcutstop: 'CmdOrCtrl+Shift+Space',
      shortcut: '',
      safecontrol: false,
      SitHeight: 200,
      StandHeight: 400,
    };
    this.changingShortcuts = false;
    this.currentPosition = 'sitting';
    this.isSSO = null;
    this.avatar = null;
    this.defaultAvatar = null;
    this.isSSO = null;
    this.user = null;
    this.parent = null;
    this.hasLoadedOnce = false;
    this.selectedFloorplan = null;
    this.selectedCid = null;
    this.savedFloorplanId = localStorage.getItem('floor');
    this.savedCid = localStorage.getItem('cid');

    this.bookings = []; // Should be populated for whatever week we are at.
    // Set saved as start value
    this.selectedCid = this.savedCid;

    this.selectedLocation = null;

    // TODO: Set when user picks date anywhere.
    // Shared date selection between all pickers.
    // Used by the map and zone service to keep
    // track of zone state (Green, Yellow , Red)
    // depending on the bookings for that zone.

    // const today = new ExtendedDate();
    // today.setHours(0, 0, 0, 0);
    this.selectedDate = new ExtendedDate();

    this.finnishedLoading = new Promise((resolve) => {
      this.finnishedLoadingPromiseResolver = resolve;
    });
  }

  init(user) {
    // console.log('initing user...');
    this.user = user;
    this.userId = user.UserId;
    this.userStatus = user.Status;
    this.profile = user.Profile;
    this.avatar = user.Profile.Avatar;
    this.username = user.Username;
    this.isSSO = localStorage?.auth_method === 'azure';
    this.parent = user.Parent;
    this.defaultAvatar = (this.avatar === defaultAvatarUri);
  }

  getUserName() { return this.username; }

  getUserParent() { return this.parent; }

  destroy() {
    this.userStatus = null;
    this.username = null;
    this.profile = {
      MeasurementSystem: 'METRIC',
      shortcutstand: 'CmdOrCtrl+Shift+PageUp',
      shortcutsit: 'CmdOrCtrl+Shift+PageDown',
      shortcutstop: 'CmdOrCtrl+Shift+Space',
      shortcut: '',
      safecontrol: false,
      SitHeight: 3000,
      StandHeight: 5000,
    };
    this.changingShortcuts = false;
    this.currentPosition = 'sitting';
    this.avatar = null;
    this.user = null;
    this.isSSO = null;
    this.parent = null;
    this.hasLoadedOnce = false;
    this.calendar = [];
    this.savedFloorplanId = null;
    this.savedCid = null;
    this.selectedLocation = null;
    this.selectedFloorplan = null;
  }

  //
  // MARK: - Getter & setter for floorplan id

  getFloorplanId() {
    return this.savedFloorplanId;
  }

  setFloorplanId(id) {
    localStorage.setItem('floor', id);
    this.savedFloorplanId = id;
  }
}

export default new UserState();
