<template>
  <div
    class="person__container"
    @click="$emit('contentClicked')"
  >
    <!-- Avatar -->
    <div
      v-if="avatar"
      class="person__avatar--container"
    >
      <PersonAvatar
        v-if="user"
        :user="user"
        :src="avatar"
        :height="avatarSize"
        :width="avatarSize"
      />
    </div>

    <!-- Name / Identifier -->
    <div
      class="person__content"
    >
      <h6>{{ username }}</h6>
      <p>{{ email }}</p>
    </div>
  </div>
</template>

<script>
import PersonAvatar from './PersonAvatar.vue';

export default {

  components: {
    PersonAvatar,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['user', 'secondaryContent', 'action', 'avatarSize'],
  // eslint-disable-next-line vue/require-prop-types
  computed: {
    email() { return this.user?.Username; },
    username() { return this.user?.Name || this.user?.username || ''; },
    avatar() { return this.user?.AvatarUrl || this.user?.avatar || ''; },
  },
  methods: {
    emitAction() {
      this.$emit('onPersonAction');
    },
  },
};
</script>
