<template>
  <font-awesome-icon :icon="icon" />
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'Icon',
  components: {
    FontAwesomeIcon,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    icon: {
      required: true,
      default: 'sign-out-alt',
    },
  },
};
</script>
