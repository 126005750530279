<template>
  <nav
    v-if="visible"
    ref="nav"
    :class="{ visible: visible }"
  >
    <ul>
      <figure class="nav--header-logo">
        <img
          :src="brandingLogo"
        >
      </figure>

      <div
        v-if="!UserState.user.AbstractUser"
        @click.capture="onClickLink"
      >
        <li v-if="CompanyState.hasLoadedOnce">
          <SearchSideBarItem />
        </li>

        <li
          class="application agenda"
        >
          <router-link to="/calendar">
            <svg-icon
              class="menu-icon"
              src="/icons/agenda.svg"
            />
            <h6 class="small-medium">
              {{ $t('Components.AgendaHeader.title') }}
            </h6>
          </router-link>
        </li>

        <li v-if="CompanyState.hasLoadedOnce">
          <BookSidebarItem />
        </li>

        <li v-if="CompanyState.hasLoadedOnce && ApplicationState.platform === 'electron'">
          <ReminderTimerSidebarItem />
        </li>

        <li v-if="CompanyState.hasLoadedOnce && UserService.hasAllRoles(['role_report'])">
          <ReportSidebarItem />
        </li>

        <li v-if="CompanyState.hasLoadedOnce && UserService.hasAllRoles(['role_user_roles'])">
          <AdminSidebarItem />
        </li>
      </div>
      <div
        v-else
        @click.capture="onClickLink"
      >
        <li
          v-if="CompanyState.hasLoadedOnce"
          @click="SettingsNavigatorState.showSidePage = true"
        >
          <SearchSideBarItem />
        </li>

        <li
          v-if="CompanyState.hasLoadedOnce"
          @click="SettingsNavigatorState.showSidePage = true"
        >
          <BookSidebarItem />
        </li>
      </div>

      <div
        v-if="!UserState.user.AbstractUser"
        @click.capture="onClickLink"
        class="bottom-aligned"
      >
        <li
          v-if="CompanyState"
          class="nav--user-avatar extended-margin-top"
          :class="ApplicationState.platform !== 'electron' ? 'extended-margin-top': null"
        >
          <figure
            class="nav--header-icon"
          >
            <router-link to="/settings">
              <img
                v-if="!UserState.hasLoadedOnce"
                :src="imagePath"
                height="50px"
                width="50px"
              >
              <avatar
                v-if="UserState.hasLoadedOnce"
                :src="UserState.avatar"
                height="50px"
                width="50px"
                :border="false"
                :rounded="true"
                aria="UserState.Avatar"
              />
            </router-link>
          </figure>
        </li>
      </div>
      <div
        v-else
        class="bottom-aligned"
        @click.capture="onClickLink"
      >
        <li
          class="nav--user-avatar extended-margin-top"
          :class="ApplicationState.platform !== 'electron' ? 'extended-margin-top': null"
        >
          <SettingsNavigatorSideBarItem />
        </li>
      </div>
    </ul>
  </nav>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import UserState from '@/singletons/user.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import ApplicationState from '@/singletons/application.state.singleton';
import UserService from '@/Services/User/user.service';
import SettingsNavigatorState from '@/views/settings/navigator/settings.navigator.state';
import WellnessSideBarItem from '../fullscreen/components/wellness.sidebar.item.vue';
import ReminderTimerSidebarItem from './components/reminder.timer.sidebar.item.vue';
import SearchSideBarItem from './components/search.sidebar.item.vue';
import SettingsNavigatorSideBarItem from './components/settings.navigator.sidebar.item.vue';
import BookSidebarItem from './components/book.sidebar.item.vue';
import PlanSidebarItem from './components/plan.sidebar.item.vue';
import AdminSidebarItem from './components/admin.sidebar.item.vue';
import ReportSidebarItem from './components/report.sidebar.item.vue';
import Avatar from '../../components/avatar/Avatar.vue';

export default {
  name: 'SideBar',
  components: {
    Avatar,
    WellnessSideBarItem,
    ReminderTimerSidebarItem,
    SearchSideBarItem,
    SettingsNavigatorSideBarItem,
    BookSidebarItem,
    PlanSidebarItem,
    AdminSidebarItem,
    ReportSidebarItem,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['navLinks', 'imagePath', 'defaultLogo'],
  data() {
    return {
      popModal: false,
      visible: false,
      UserState,
      UserService,
      CompanyState,
      ApplicationState,
      SettingsNavigatorState,
    };
  },
  computed: {
    isVisible() {
      if (typeof this.$route.name === 'undefined' || this.$route.name == null) return false;
      if (this.$route?.meta?.sidebar) return (this.$route.meta.sidebar);
      return true;
    },
    brandingLogo() {
      return (CompanyState.branding && CompanyState.branding.logo) || this.defaultLogo;
    },
  },
  watch: {
    /** Only show sidebar if user is authenticated and not in path containing 'login' or 'license' */
    $route(route) {
      if (route.path.includes('/login')) {
        this.visible = false;
      } else if (route.path === '/mobile-user') {
        this.visible = false;
      } else if (route.path === '/splash') {
        this.visible = false;
      } else if (route.path !== '/login') {
        this.visible = true;
      }
    },
  },
  created() {
    this.visible = this.isVisible;
  },
  methods: {
    getI18(s) {
      return `Components.Sidebar.${s}`;
    },
    /** Loaded applications, e.g (companion / insight) */
    constructApplications() {
      const { modules } = this.$store.getters;
      return modules;
    },

    /** Toggles sidebar state between collapsed / expanded  */
    toggleSidebar() {
      const nav = this.$refs.nav.classList;
      nav.toggle('active');
    },
    onClickLink(e) {
      const isActiveLink = !!e.currentTarget.querySelector('.router-link-exact-active');

      if (!SettingsNavigatorState.showSidePage) {
        SettingsNavigatorState.showSidePage = true;
      } else if (isActiveLink) {
        SettingsNavigatorState.showSidePage = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/breakpoints.scss';

$item-width: 80px;

nav {
  height: 100%;
  width: $item-width;
  min-width: $item-width;
  background-color: #f1f3f5;
  background-color: var(--modal-bg-color);
  z-index: 2;
  transition: width .1s;
  color: var(--seconday-font-color);
  border-right: solid 2px #D9D9D9;
  border-right: solid 2px var(--border-color);

  a span:not(.icon) {
    display: block;
  }
  .nav--user-avatar {
    &.extended-margin-top {
      margin-top: auto;
    }
    align-items: flex-end;
  }

  span.icon {
    margin-bottom: 5px;
  }
  .nav--header-icon {
    display: block;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav--header-logo {
    width: $item-width;
    height: $item-width;
    background-color: var(--primary-color);
  }

  &.visible {
    display: block;
  }

  ul {
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;
    overflow-x: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    background-color: white;
    background-color: var(--bg-color);
    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }

  }

  li {
    width: $item-width;
    list-style-type: none;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    &.sidebar--last_of_type {
      margin-top: auto;
    }
  }
  .wellness-sidebar-item {
    margin-top: auto;
    margin-bottom: 1.5rem;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      color: var(--secondary-font-color);
      fill: var(--secondary-font-color);
    }

    h6{
      color: var(--secondary-font-color);
      white-space: nowrap;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150%;
      font-size: .875rem;
    }

    &:hover {
      text-decoration: none;
    }

    &.router-link-active {
      color: var(--primary-color);
      span.icon svg {
        fill: var(--primary-color);
      }
      h6 {
        font-weight: 600;
        color: var(--primary-color);
      }
    }
  }
  .bottom-aligned{
    margin-top: auto;
  }
}

@keyframes direcAnim {
  from { flex-direction: column; }
  to { flex-direction: row; }
}

</style>
