export default class PhoneDebugger {
  constructor(events) {
    this.events = events;
    this.setupDebugListeners();
  }

  setupDebugListeners() {
    window.addEventListener('select-camera', (evt) => {
      const event = new Event('phone');
      const data = {
        event: this.events.phone_map_selectcamera,
        data: {
          cameraView: evt.cameraView || '3D',
        },
      };
      event.data = JSON.stringify(data);
      console.log('Dispatching select camera: ', event);
      window.dispatchEvent(event);
    });

    window.addEventListener('select-location', (evt) => {
      const event = new Event('phone');
      const data = {
        event: this.events.phone_map_selectlocation,
        data: {
          floorZid: evt.zid || '5430415594618880',
        },
      };
      event.data = JSON.stringify(data);
      PhoneDebugger.broadCast(event);
    });

    window.addEventListener('goto-resource', (evt) => {
      const event = new Event('phone');
      const data = {
        event: this.events.phone_book_gotoresource,
        data: {
          zid: evt.zid || '6486356054245376',
        },
      };
      event.data = JSON.stringify(data);
      PhoneDebugger.broadCast(event);
    });

    window.addEventListener('select-resource', (evt) => {
      const event = new Event('phone');
      const data = {
        event: this.events.phone_map_selectresource,
        data: {
          zid: evt.zid || '4819174614368256',
        },
      };
      event.data = JSON.stringify(data);
      PhoneDebugger.broadCast(event);
    });

    window.addEventListener('change-module', (evt) => {
      const event = new Event('phone');
      const data = {
        event: this.events.phone_module_change,
        data: {
          module: evt.module || 'CALENDAR',
          customPath: evt.customPath,
        },
      };
      event.data = JSON.stringify(data);
      PhoneDebugger.broadCast(event);
    });

    window.addEventListener('btn-info', () => {
      const event = new Event('phone');
      const data = {
        event: this.events.phone_navigationbar_btninfo,
        data: null,
      };
      event.data = JSON.stringify(data);
      PhoneDebugger.broadCast(event);
    });

    window.addEventListener('btn-back', () => {
      const event = new Event('phone');
      const data = {
        event: this.events.phone_navigationbar_btnback,
        data: null,
      };
      event.data = JSON.stringify(data);
      PhoneDebugger.broadCast(event);
    });
  }

  static broadCast(event) {
    console.log('DEBUGGER EMITTING: ', event);
    window.dispatchEvent(event);
  }
}
