/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */

import MapState from '@/singletons/map.state.singleton';

/**
 * Show modal of loading progress.
 */
class LoadingMessage {
  constructor() {
    this.elem = document.createElement('div');
    const style = {
      position: 'absolute',
      left: '50%',
      top: '50%',
      width: '200px',
      margin: '0 auto',
      padding: '20px',
      transform: 'translate(-50%, -50%)',
      color: 'rgba(0, 0, 0, 0.5)',
      background: 'rgba(200, 200, 200, 0.8)',
      'border-radius': '20px',
      'font-size': '20px',
      'text-align': 'center',
      'pointer-events': 'none',
    };
    Object.assign(this.elem.style, style);
    MapState.targetCanvas.appendChild(this.elem);
  }

  show = (message) => {
    this.elem.innerHTML = message;
  }

  remove = () => {
    this.elem.remove();
  }
}

export default LoadingMessage;

