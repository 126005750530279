/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */

import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import _, { map } from 'underscore';
import CompanyService from '@/Services/Company/company.service';
import CompanyState from '@/singletons/company.state.singleton';
import MapState from '@/singletons/map.state.singleton';
import LoadingMessage from './loading.message';

/**
 * Parse the json for all assets. Keep track
 * of assets and be able to load them as THREE.js
 * properties on objects.
 */
class AssetManager {
  constructor() {
    // Loaders for images, textures, 3d objects etc.
    this.manager = new THREE.LoadingManager();
    this.manager.onLoad = () => { };
    this.loader = new OBJLoader(this.manager);
    this.textureLoader = new THREE.ImageLoader(this.manager);
    this.textureLoader.crossOrigin = '';

    // Default assets (created when loading all assets)
    this.default2DAsset = null;
    this.default3DAsset = null;
  }

  loadAllAssets = async (floor, callback) => {
    // Fetch all assets if needed
    if (!CompanyState.assets.length) { await CompanyService.getAssets(); }
    if (!CompanyState.zones.length) { await CompanyService.getZones(); }

    // Prepare dependencies
    const { zones, assets } = CompanyState;

    let toBeLoaded = 0;
    const neededAssets = this.findNeededAssets(zones, assets, floor);

    if (_.indexOf(neededAssets, 0) !== -1) {
      toBeLoaded += 1;
      this.createDefaultAssets(() => {
        toBeLoaded -= 1;
        if (toBeLoaded === 0) {
          callback();
        }
      });
    }

    let assetsToLoad = 0;
    MapState.msg = new LoadingMessage();
    MapState.msg.show('Loading assets<br>...');

    _.each(assets, (asset) => {
      if (asset.prefab || _.indexOf(neededAssets, asset.Aid) === -1 || asset.Type === 'group') {
        // Already loaded or not needed
        return;
      }

      toBeLoaded += 1;
      assetsToLoad = Math.max(assetsToLoad, toBeLoaded);
      this.loadFiles(asset.Files, () => {
        this.loadAsset(asset);
        toBeLoaded -= 1;
        if (toBeLoaded === 0) {
          MapState.msg.remove();
          callback();
        }
        MapState.msg.show(`Loading assets<br>${assetsToLoad - toBeLoaded}/${assetsToLoad}`);
      });
    });

    if (toBeLoaded === 0) {
      MapState.msg.remove();
      callback();
    }
  }

  findNeededAssets = (zones, assets, floor) => {
    let result = [floor.FloorProps.Aid];

    _.each(_.where(zones, { ParentZone: floor.Zid }), (zone) => {
      if (zone.FloorProps && zone.FloorProps.Aid) {
        result.push(zone.FloorProps.Aid);
      }
    });

    const floorAsset = _.findWhere(assets, { Aid: floor.FloorProps.Aid });

    if (floorAsset.Options && floorAsset.Options.floorAssets) {
      _.each(floorAsset.Options.floorAssets, (asset) => {
        result.push(asset.Aid);
      });
    }

    _.each(result, (assetId) => {
      const asset = _.findWhere(assets, { Aid: assetId });
      if (asset && asset.Type === 'group') {
        _.each(asset.Options.childs, (child) => {
          result.push(child.Aid);
        });
      }
    });

    result = _.uniq(result);

    for (let i = 0; i < result.length; i += 1) {
      if (_.findWhere(assets, { Aid: result[i] }) === undefined) {
        result.push(0);
        break;
      }
    }

    return result;
  }

  loadFiles = (files, endFunc) => {
    let toBeLoaded = 0;
    _.each(files, (file) => {
      if (file.resource) {
        return;
      }

      switch (file.Mime) {
        case 'text/obj':
        case 'application/octet-stream': // avoid this!!
          toBeLoaded += 1;
          this.loader.load(file.Path, (object) => {
            file.resource = object;
            toBeLoaded -= 1;
            if (toBeLoaded === 0) {
              endFunc();
            }
          }, undefined, () => {
            toBeLoaded -= 1;
            if (toBeLoaded === 0) {
              endFunc();
            }
          });
          break;

        case 'image/png': // TODO: what about other types of images?
        case 'image/jpeg':
          toBeLoaded += 1;
          this.textureLoader.load(file.Path, (image) => {
            const texture = new THREE.Texture();
            texture.image = image;
            texture.needsUpdate = true;
            file.resource = texture;
            toBeLoaded -= 1;
            if (toBeLoaded === 0) {
              endFunc();
            }
          }, undefined, () => {
            toBeLoaded -= 1;
            if (toBeLoaded === 0) {
              endFunc();
            }
          });
          break;

        default:
      }
    });

    if (toBeLoaded === 0) {
      endFunc();
    }
  }

  loadAsset = (asset) => {
    switch (asset.Type) {
      case 'floor':
      case '3d':
        asset.prefab = _.findWhere(asset.Files, {
          Name: asset.Options.obj,
        }).resource;
        this.applyAllOptions(asset.prefab, asset.Options, asset.Files);
        break;

      case '2d':
        const texture = _.findWhere(asset.Files, { Name: asset.Options.map }).resource;
        const circleobjMaterial = new THREE.SpriteMaterial({ map: texture });
        asset.prefab = new THREE.Sprite(circleobjMaterial);
        break;
      default:
        break;
    }
  }

  applyAllOptions = (group, options, files) => {
    // TODO: if group contains groups, lower level objects need to be processed recursively or something
    group.traverse((childObject) => {
      const opts = _.clone(options);
      const childOpts = _.findWhere(options.childOptions, {
        child: childObject.name,
      });
      if (childObject instanceof THREE.Mesh && childOpts) {
        const childKeys = _.keys(childOpts); // _.extend() does not work here!
        _.each(childKeys, (key) => {
          if (childOpts[key] !== undefined) {
            opts[key] = childOpts[key];
          }
        });
      }

      this.applyOptions(childObject, opts, files);
    });
  }

  applyOptions = (object, options, files) => {
    // Material need to be set first
    switch (options.material) {
      case 'MeshLambertMaterial':
        object.material = new THREE.MeshLambertMaterial();
        break;

      case 'MeshBasicMaterial':
        object.material = new THREE.MeshBasicMaterial();
        break;

      default:
        object.material = new THREE.MeshLambertMaterial();
    }

    Object.keys(options).forEach((key) => {
      const value = options[key];

      switch (key) {
        case 'obj':
        case 'childOptions':
        case 'material':
        case 'child':
        case 'zid':
        case 'light':
        case 'background':
        case 'ambient':
        case 'floorAssets':
          break; // Processed separately, do nothing here

        case 'map':
          if (value) {
            object.material.map = _.findWhere(files, { Name: value }).resource;
          } else {
            object.material.map = undefined;
          }
          break;

        case 'alphaMap':
          if (value) {
            object.material.alphaMap = _.findWhere(files, {
              Name: value,
            }).resource;
          } else {
            object.material.alphaMap = undefined;
          }
          break;

        case 'transparent':
          object.material.transparent = value;
          break;

        case 'opacity':
          object.material.opacity = value;
          break;

        case 'color':
          object.material.color = new THREE.Color(value);
          break;

        case 'castShadow':
          object.castShadow = value;
          break;

        case 'receiveShadow':
          object.receiveShadow = value;
          break;

          // TODO: add more options to process
        default:
      }
    });
  }

  getDefaultObject = (type) => {
    let object = null;
    if (type === '3d') {
      if (!this.default3DAsset) { return null; }
      object = this.default3DAsset.clone();
    } else {
      // 2d
      if (!this.default2DAsset) { return null; }
      object = this.default2DAsset.clone();
    }
    return object;
  }

  createDefaultAssets = (endFunc) => {
    let toBeLoaded = 2;

    // 2d
    this.textureLoader.load('assets/default.png', (image) => {
      const texture = new THREE.Texture();
      texture.image = image;
      texture.needsUpdate = true;
      const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
      this.default2DAsset = new THREE.Sprite(spriteMaterial);
      toBeLoaded -= 1;
      if (toBeLoaded === 0) {
        endFunc();
      }
    }, undefined, () => {
      toBeLoaded -= 1;
      if (toBeLoaded === 0) {
        endFunc();
      }
    });

    // 3d
    this.textureLoader.load('assets/default.png', (image) => {
      const texture = new THREE.Texture();
      texture.image = image;
      texture.needsUpdate = true;
      const plane = new THREE.PlaneGeometry(1, 1, 1, 1);
      const material = new THREE.MeshLambertMaterial({ map: texture });

      const mesh = new THREE.Mesh(plane, material);
      mesh.rotation.set(-1.57, 0, 0);
      mesh.name = 'm1';

      this.default3DAsset = new THREE.Object3D();
      this.default3DAsset.add(mesh);
      toBeLoaded -= 1;
      if (toBeLoaded === 0) {
        endFunc();
      }
    }, undefined, () => {
      toBeLoaded -= 1;
      if (toBeLoaded === 0) {
        endFunc();
      }
    });
  }
}

export default AssetManager;

