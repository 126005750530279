<template>
  <div class="background pulse">
    <Logo
      logo-type="primary"
      aria="Login logo"
      class="loading-logo"
      height="200px"
      width="200px"
    />
  </div>
</template>

<script>
/** Components */
import Logo from '@/components/logo/logo.component.vue';

export default {
  components: {
    Logo,
  },
};

</script>

<style lang="scss" scoped>
.background{
  margin:auto;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background-color: var(--primary-color);

  &.pulse{
     animation: pulse_animation 3s infinite;
  }

  @keyframes pulse_animation {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(173, 238, 189, 0.7),
      0 0 0 0 rgba(43, 139, 54, 0.7);
    }

    70% {
    transform: scale(1.03);
     box-shadow: 0 0 0 150px rgba(173, 238, 189, 0),
    0 0 0 50px rgba(43, 139, 54, 0);
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 150px rgba(173, 238, 189, 0),
      0 0 0 50px rgba(43, 139, 54, 0);
    }
  }
}

.loading-logo{
  margin: auto;
}

</style>
