/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import CompanyState from '@/singletons/company.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import PhoneService from '@/Services/Phone/phone.service';
import ApplicationState, {
  STATE_LOGIN, STATE_UNAUTHORIZED,
} from '@/singletons/application.state.singleton';
import AuthService from '@/Services/Auth/auth.service';
import { getResourcesOfType } from '@/functions/resources.functions';
import CompanyService from '../Services/Company/company.service';
import UserService from '../Services/User/user.service';

/* --- for all paths - add in router before each --- */
export const isLoggedIn = (to, from, next) => {
  if (to.name === 'mobile-browser') return;
  if (ApplicationState.appState === STATE_LOGIN && !(to.name === 'login' || to.name === 'login-section')) {
    next({ name: 'login' });
  } else {
    // DO nothing next();
  }
};

/* --- for specific paths - add in before enter on route path --- */

export const checkZidInRouteExist = async (to, from, next) => {
  const { zid: pZid } = to.params;
  const { zid: qZid } = to.query;
  const zid = pZid || qZid;
  let zone = null;
  if (zid) { zone = await CompanyService.getCachedZone(Number(zid)); }
  if (zone) {
    next();
  } else {
    next({ name: '404' });
  }
};

export const checkTypeInRouteExist = async (to, from, next) => {
  const { type } = to.params;
  const types = await CompanyService.getChacedZoneTypes();
  if (types && types.find(({ Constant }) => type === Constant)) {
    next();
  } else {
    next({ name: '404' });
  }
};

export const checkBidInRouteExist = async (to, from, next) => {
  const { bid } = to.params;
  const booking = await UserService.getCachedUserBooking(Number(bid));
  if (booking) {
    next();
  } else {
    next({ name: '404' });
  }
};

export const isAlreadyLoggedIn = async (to, from, next) => {
  if (ApplicationState.appState < 1) await AuthService.verifySession();

  if (ApplicationState.appState > STATE_LOGIN && to.path.includes('login')) {
    next({ name: CompanyState.fallbackRouteName });
  } else {
    next();
  }
};

export const isAuthorized = (to, from, next) => {
  if (ApplicationState.appState === STATE_UNAUTHORIZED) {
    next({ name: 'home' });
  } else {
    next();
  }
};

export const isCompanion = (to, _, next) => {
  if (to.matched.some((record) => record.meta.requiresCompanion)) {
    next();
  } else if (!PhoneService.isInPhoneApp() && (/iPhone|iPad|iPod/i.test(navigator.userAgent) || /Android|ANDROID|android/i.test(navigator.userAgent))) {
    next();
  } else {
    next({ name: CompanyState.fallbackRouteName });
  }
};

export const isNative = (to, _, next) => {
  if (ApplicationState.platform === 'electron') {
    next();
  } else {
    next({ name: CompanyState.fallbackRouteName });
  }
};

export const isMobileBrowser = (to, from, next) => {
  if (!PhoneService.isInPhoneApp() && (/iPhone|iPad|iPod/i.test(navigator.userAgent) || /Android|ANDROID|android/i.test(navigator.userAgent))) {
    next({ name: 'mobile-browser' });
  } else {
    next();
  }
};

export const isAbstractUser = (to, from, next) => {
  if (UserState.user?.AbstractUser) {
    next();
  } else {
    next({ name: '404' });
  }
};

export const isMobile = (to, _, next) => {
  if (PhoneService.isInPhoneApp()) {
    next();
  } else {
    next({ name: CompanyState.fallbackRouteName });
  }
};

/** Consider removing this guard since it doesnt to anything */
export const shouldShowSplashScreen = (to, _, next) => {
  if (ApplicationState.platform !== 'electron') {
    // Do nothing next();
  } else if (AuthService.hasSession() && to.name === 'splash') {
    // Do nothing, next();
  } else if (AuthService.hasSession() && _.path === '/') {
    // next('/splash');
  } else {
    // Do nothing, next();
  }
};

export const isRootPath = (to, _, next) => {
  if (to.path === '/') {
    next({ name: CompanyState.fallbackRouteName });
  } else {
    // Do nothing next();
  }
};

export const checkIfUserHasReportRole = async (to, from, next) => {
  if (UserService.hasAllRoles(['role_report'])) {
    next();
  } else {
    next({ name: '404' });
  }
};

export const hasRoleUserRoles = async (to, from, next) => {
  if (UserService.hasAllRoles(['role_user_roles'])) {
    next();
  } else {
    next({ name: '404' });
  }
};

export const hasRoleIssueResolve = async (to, from, next) => {
  if (UserService.hasAllRoles(['role_issue_resolve'])) {
    next();
  } else {
    next({ name: '404' });
  }
};
