/* eslint-disable import/no-cycle */
/*
  Worker-timers is used for the reminder to be able to run in the background,
  because chromium otherwise idles timeouts/intervals running in the background
  after a few minutes.
  */
import { setInterval as setIntervalWT, clearInterval as ClearIntervalWT } from 'worker-timers';
import UserState from '@/singletons/user.state.singleton';
import RemindersModel from '@/models/reminders/reminders.model';
// import { msToTime } from '@/Services/controllers/reminder.controller';
import { clamp } from '@/util/math.util';
import { NotificationWrapperRenderer } from '@rolergo/native-notifications-package-nodejs/renderer';
import ConnectState from './connect.state.singleton';
import { NOTIFICATION_ACTIONS } from '../native/notification/notification.constants';
import i18n from '../i18n';

export const REMINDER_STATE_NOT_ACTIVE = 5;
export const REMINDER_STATE_NOT_CONNECTED = 10;
export const REMINDER_STATE_ACTIVE = 20;
export const REMINDER_STATE_SNOOZE = 30;
export const REMINDER_STATE_FINISHED = 40;

/** TODO All this should be changed i guess */
export const COLOR_DANGER = '#C90C50';
export const COLOR_PRIMARY = '#159648';
export const COLOR_WARNING = '#F79321';
export const COLOR_INACTIVE = 'rgb(57, 57, 185)';
export const RING_COLOR_NOT_ACTIVE = '#159648';
export const RING_COLOR_ACTIVE = 'f0f0f0';

class ReminderStateSingleton {
  constructor() {
    this.profile = {
      reminder: 0,
    };
    this.targetPosition = 'standing';
    this.reminder = {
      state: REMINDER_STATE_NOT_ACTIVE,
      percent: 25,
      color: COLOR_PRIMARY,
      timeLeftAsMs: 0,
      timeLeftAtStartMs: 0,
      timeLeftAsString: '00:00',
      position: null,
      targetPosition: null,
      stopped: true,
    };
    this.reminderInterval = null;
    this.positionChangeInterval = null;
  }

  reminderIsActive() {
    return this.profile.reminder > 0;
  }

  resetReminderState() {
    this.reminder = {
      state: REMINDER_STATE_NOT_ACTIVE,
      percent: this.activeReminder ? this.activeReminder.goal : 25,
      color: COLOR_PRIMARY,
      timeLeftAsMs: 0,
      timeLeftAsString: '00:00',
      interval: null,
    };
  }

  selectReminder(reminder) {
    RemindersModel.selectReminder(reminder);

    if (this.reminder.state === REMINDER_STATE_FINISHED) {
      this.reminder.state = REMINDER_STATE_ACTIVE;
    }

    const { currentPosition } = UserState;

    this.reminder.position = currentPosition;
    this.reminder.timeLeftAtStartMs = currentPosition === 'sitting' ? reminder.sittime : reminder.standtime;
    this.reminder.timeLeftAsMs = this.reminder.timeLeftAtStartMs;
    this.reminder.timeLeftAsString = this.msToTime(this.reminder.timeLeftAsMs, true);
    this.reminder.percent = (this.reminder.timeLeftAsMs / this.reminder.timeLeftAtStartMs) * 100;

    this.reminder.targetPosition = this.getTargetPosition();

    this.updateReminderColor();
  }

  getTargetPosition() {
    return this.getCurrentPosition() === 'sitting' ? 'standing' : 'sitting';
  }

  startTimer(snooze = false) {
    this.profile.reminder = 1;
    this.reminder.state = REMINDER_STATE_ACTIVE;
    this.reminder.stopped = false;

    if (this.reminderInterval) return;

    if (!snooze) {
      this.selectReminder(RemindersModel.getReminderById(RemindersModel.currentlySelectedReminderID));
    }

    this.reminder.percent = 100;
    this.reminder.color = COLOR_PRIMARY;

    this.reminderInterval = setIntervalWT(() => {
      this.updateTimer();
    }, 1000);
  }

  stopTimer(state = REMINDER_STATE_NOT_ACTIVE) {
    if (state === REMINDER_STATE_NOT_ACTIVE) {
      this.profile.reminder = 0;
    }

    this.reminder.state = state;

    if (this.reminderInterval) {
      ClearIntervalWT(this.reminderInterval);
      this.reminderInterval = null;
    }
  }

  handlePositionChanged() {
    if (this.reminder.state === REMINDER_STATE_NOT_ACTIVE) return;

    if (!ConnectState.selectedDevice) {
      this.stopTimer();
      this.startTimer();
    }

    this.targetPosition = this.getTargetPosition();

    if (this.positionChangeInterval) {
      ClearIntervalWT(this.positionChangeInterval);
      this.positionChangeInterval = null;
    }

    let failsafeCount = 0;
    this.positionChangeInterval = setIntervalWT(() => {
      failsafeCount += 1;
      const { currentPosition } = UserState;
      if (currentPosition === this.targetPosition) {
        if (ConnectState.selectedDevice.isAutoMoving !== ConnectState.selectedDevice.position) {
          this.stopTimer();
          this.startTimer();
        }

        if (this.positionChangeInterval) {
          ClearIntervalWT(this.positionChangeInterval);
          this.positionChangeInterval = null;
        }
      }

      // Failsafe incase we never reach our targetPosition
      // to clear counter.
      if (failsafeCount >= 20 && this.positionChangeInterval) {
        ClearIntervalWT(this.positionChangeInterval);
        this.positionChangeInterval = null;
      }
    }, 1000);
  }

  // eslint-disable-next-line class-methods-use-this
  getCurrentPosition() {
    if (!ConnectState?.selectedDevice) return UserState.currentPosition;
    const distToStand = Math.abs(ConnectState?.selectedDevice?.position - UserState.profile.StandHeight) || 1;
    const distToSit = Math.abs(ConnectState?.selectedDevice?.position - UserState.profile.SitHeight) || 1;
    if (distToStand >= distToSit) return 'sitting';
    return 'standing';
  }

  msToTime = (duration, includeSeconds) => {
    // We want to use Math.floor here, not Math.round.
    let seconds = clamp(Math.floor(duration / 1000) % 60, 0, 60);
    let minutes = clamp(Math.floor(duration / (1000 * 60)) % 60, 0, 60);
    let hours = clamp(Math.floor(duration / (1000 * 60 * 60)) % 24, 0, 24);

    hours = (hours < 10) ? `0${hours}` : hours;
    minutes = (minutes < 10) ? `0${minutes}` : minutes;
    seconds = (seconds < 10) ? `0${seconds}` : seconds;

    let timeStr = `${hours}:${minutes}`;

    if (includeSeconds) {
      timeStr += `:${seconds}`;
    }

    return timeStr;
  }

  updateTimer() {
    this.reminder.timeLeftAsMs -= 1000;

    this.reminder.timeLeftAsString = this.msToTime(this.reminder.timeLeftAsMs, true);
    this.reminder.percent = (this.reminder.timeLeftAsMs / this.reminder.timeLeftAtStartMs) * 100;
    this.updateReminderColor();
    if (this.reminder.timeLeftAsMs <= 0) {
      this.finishTimer();
    }
  }

  updateReminderColor() {
    if (this.reminder.percent < 70) {
      this.reminder.color = COLOR_WARNING;
    }

    if (this.reminder.percent < 30) {
      this.reminder.color = COLOR_DANGER;
    }
  }

  createSnoozeState(ms) {
    if (this.reminder?.state !== REMINDER_STATE_FINISHED) {
      return;
    }

    this.reminder = {
      state: REMINDER_STATE_SNOOZE,
      percent: 0,
      color: COLOR_PRIMARY,
      timeLeftAsMs: ms,
      timeLeftAtStartMs: ms,
      timeLeftAsString: this.msToTime(ms, true),
      position: null,
      targetPosition: null,
      stopped: false,
    };

    this.startTimer(true);
  }

  finishTimer() {
    this.reminder.stopped = true;
    this.reminder.percent = 100;
    this.stopTimer(REMINDER_STATE_FINISHED);

    NotificationWrapperRenderer.showNotification(
      i18n.t('Notification.rol_intelligent_office'),
      i18n.t('Notification.change_position'),
      NOTIFICATION_ACTIONS.NOTIFICATION_WELLNESS_TIMEOUT_SHOW,
      [
        {
          text: i18n.t('Notification.show'),
          action: NOTIFICATION_ACTIONS.NOTIFICATION_WELLNESS_TIMEOUT_SHOW,
        },
        {
          text: i18n.t('Notification.snooze'),
          action: NOTIFICATION_ACTIONS.NOTIFICATION_WELLNESS_TIMEOUT_SNOOZE,
        },
        {
          text: i18n.t('Notification.dismiss'),
          action: NOTIFICATION_ACTIONS.NOTIFICATION_WELLNESS_TIMEOUT_DISMISS,
        },
      ],
    );
  }
}

export default new ReminderStateSingleton();
