import BookingState from '@/singletons/bookings.state.singleton';
import ExtendedDate from '@/classes/extended.date.class';
import { DateTime } from 'luxon';

import {
  getBookings, checkInBooking, checkOutBooking, updateBooking, deleteBooking, postBooking,
  getBookingsForResource,
} from '../../util/api';
import i18n from '../../i18n';

const sortBookingsByFrom = (a, b) => {
  if (a.From > b.From) {
    return 1;
  }
  if (a.From < b.From) {
    return -1;
  }
  return 0;
};
const sortBookingsByOwner = (username) => (booking) => booking.Owner === username;
const sortBookingsByZid = (zid) => (booking) => booking.Zid === zid;

const filterOnAttendee = (username) => (booking) => !!booking.Attendees.find((attendee) => attendee.Username === username && attendee.Status === 'ACCEPTED');

const removeBookingsDuplicates = (bookings) => {
  const b = Array.from(bookings);
  const result = [];
  const seen = {};
  b.sort((a, b) => Number(b.LastModified) - Number(a.LastModified));
  for (let i = 0; i < b.length; i++) {
    const key = `${b[i].ExternalId}:${b[i].Zid}`;
    if (!seen[key]) {
      result.push(b[i]);
      seen[key] = true;
    }
  }
  return result;
};

class BokingsService {
  // replaces fetchBookings
  static async getAllBookings(from, until) {
    try {
      const { data: rawData } = await getBookings(from, until);
      const data = removeBookingsDuplicates(rawData);
      BookingState.bookings = data;
      return data || [];
    } catch (error) {
      return [];
    }
  }

  static async getBookingsOfDay(date) {
    try {
      const from = DateTime.fromJSDate(date).startOf('day').toMillis();
      const millis28Hours = 1000 * 60 * 60 * 28;
      const until = from + millis28Hours;
      const { data: rawData } = await getBookings(from, until);
      const data = removeBookingsDuplicates(rawData);
      BookingState.bookings = data;
      return data || [];
    } catch (e) {
      console.log('Failed to get booking for date', date, 'for reason:', e);
      return [];
    }
  }

  static async getCachedResourceBookings(zid, from, until) {
    try {
      const { data: rawData } = await getBookingsForResource(from, until, zid);
      const data = removeBookingsDuplicates(rawData);
      return data;
    } catch (e) {
      console.log('Failed to fetch bookings for resource:', e);
      return null;
    }
  }

  // Soft changes, used by socket
  static pushBooking(newBooking) {
    const exists = BookingState.bookings.find((booking) => booking.Bid === newBooking.Bid);
    if (exists) return;
    BookingState.bookings.push(newBooking);
  }

  static updateBooking(updatedBooking) {
    const exists = BookingState.bookings.find((booking) => booking.Bid === updatedBooking.Bid);
    if (!exists) return;
    Object.assign(exists, updatedBooking);
  }

  static removeBooking(Bid) {
    const exists = BookingState.bookings.find((booking) => booking.Bid === Bid);
    if (!exists) return;
    BookingState.bookings.splice(BookingState.bookings.indexOf(exists), 1);
  }

  // Hard changes, CRUD to backend
  static async hardUpdateBooking(booking) {
    const { data } = await updateBooking(booking);
    return data;
  }

  static async hardPostBooking(booking) {
    const MIN_15 = 900000;
    if (new Date(booking.From).getTime() + MIN_15 < new Date().getTime()) throw new Error(i18n.t('Errors.cannot_book_in_past'));
    const { data } = await postBooking(booking);
    return data;
  }

  static async hardRemoveBooking(bid) {
    await deleteBooking(bid);
  }

  static getBookingsByUsername(username) {
    const ownedBookings = BookingState.bookings
      .filter(sortBookingsByOwner(username))
      .sort(sortBookingsByFrom)
      .map(this.checkIfCurrent);

    const attendedBookings = BookingState.bookings
      .filter(filterOnAttendee(username))
      .sort(sortBookingsByFrom)
      .map(this.checkIfCurrent);

    const allBookings = ownedBookings.concat(attendedBookings);

    return allBookings;
  }

  static getBookingsByZid(zid) {
    return BookingState.bookings
      .filter(sortBookingsByZid(zid))
      .sort(sortBookingsByFrom)
      .map(this.checkIfCurrent);
  }

  static getBookings() {
    return BookingState.bookings;
  }

  static checkIfCurrent(booking) {
    Object.assign(booking, { isCurrent: (booking.From < Date.now() && Date.now() < booking.Until) });
    return booking;
  }

  static isCheckInNeededForBooking(booking) {
    const MIN_15 = 1000 * 60 * 15;
    const now = new ExtendedDate().getTime();
    // If NoShow == null, then we've effectively deactivated the "check in" button
    return ((booking?.From - now) < MIN_15) && booking?.CheckIn === null && booking?.NoShow !== null;
  }

  static async checkIn(booking) {
    const pack = {
      Method: 'MANUALLY',
      Time: new Date().getTime(),
      Username: booking.Owner,
    };
    const response = await checkInBooking(pack, booking.Bid);
    return response;
  }

  static async checkOutBooking(bid) {
    const response = await checkOutBooking(bid);
    console.log(response);
  }

  static sortBookingsByTime(a, b) {
    return a.From - b.From;
  }

  static isSameDay(
    activeDate = new ExtendedDate(),
  ) {
    return (booking) => activeDate.isSameDate(new ExtendedDate(booking.From));
  }
}

export default BokingsService;
