import Vue from 'vue';
import msal from 'vue-msal';
import AuthService from '@/Services/Auth/auth.service';
import ApplicationState, { STATE_VERIFY_SESSION } from '@/singletons/application.state.singleton';
// import router from '../../router';
// import { authenticateAzure } from '../api';

/**
 * VUE ASAL / Wrapper for msal (Microsoft Azure Login)
 */
Vue.use(msal, {
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },

  auth: {
    /** ClientID from azure app registration */
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,

    /** Redirect uri when authentication has passed. */
    redirectUri: process.env.VUE_APP_REDIRECT_URI_AUTH_SUCCESS,

    /** Refirect uri when user has been logged out. */
    postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_URI_SIGNOUT,

    /** Refreshed accessToken automatically. */
    requireAuthOnInitialize: false,
    autoRefreshToken: true,

    /** Never return to login when authenitcation has been verified. */
    navigateToLoginRequestUrl: false,

    /** Callback */
    onAuthentication: (() => {
      // Maybe do something later.
      AuthService.setMethod('azure'); // This needs to be re-written in the future
    }),

    /** When accessToken (idToken) has been recieved. */
    onToken: (async () => {
      if (ApplicationState.appState < STATE_VERIFY_SESSION) {
        console.log('onToken running verifySession');
        await AuthService.verifySession();
      }
    }),

    /** Callback function to be executed before manual sign out. */
    beforeSignOut: (() => {
      // Maybe do something later.
    }),
  },
});
