<template>
  <div>
    <h6 class="time">
      {{ time }}
    </h6>
    <h6 class="date">
      {{ date }}
    </h6>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  data() {
    return {
      interval: null,
      time: null,
      date: null,
    };
  },
  beforeDestroy() {
    /* prevention of memory leakage */
    clearInterval(this.interval);
  },
  created() {
    this.interval = setInterval(() => {
      this.time = DateTime.now().toLocaleString(DateTime.TIME_SIMPLE);
      this.date = DateTime.now().toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    }, 1000);
  },
};
</script>

<style lang="scss">
  .time {
    text-align: right;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .time, .date {
    user-select: none;
  }
</style>
