/* eslint-disable class-methods-use-this */
import SpriteText from 'three-spritetext';
import * as THREE from 'three';
import MapState from '@/singletons/map.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import i18n from '@/i18n';
import CustomEvent from '@/classes/custom.event.class';
import SettingsNavigatorState from '@/views/settings/navigator/settings.navigator.state';
import TWEEN from '@tweenjs/tween.js';
import MapController from './map.controller';

class MapNavigatorController {
  constructor() {
    this.hasInitialized = false;
    this.navigatorMarkerGroup = null;
    this.navigatorPulsingDot1 = null;
    this.navigatorPulsingDot2 = null;
  }

  async init() {
    if (this.hasInitialized || !UserState.user.AbstractUser) {
      return;
    }

    SettingsNavigatorState.init();

    const navigatorMarkerText = new SpriteText(i18n.t('Map.you_are_here'), 0.35, '#c90c3b');
    navigatorMarkerText.renderOrder = 99999;
    navigatorMarkerText.material.depthTest = false;
    navigatorMarkerText.fontWeight = 600;
    navigatorMarkerText.center.add(new THREE.Vector2(0, -3.8));

    const navigatorMarkerTexture = new THREE.TextureLoader().load('/icons/map-icons/navigator_position_bubble.svg');
    const navigatorMarkerMaterial = new THREE.SpriteMaterial({ map: navigatorMarkerTexture, depthTest: false });
    const navigatorMarkerSprite = new THREE.Sprite(navigatorMarkerMaterial);
    navigatorMarkerSprite.renderOrder = 99998;
    navigatorMarkerSprite.scale.set(3, 3, 3);
    navigatorMarkerSprite.center.add(new THREE.Vector2(0, -0.6));

    const navigatorMarkerDotTexture = new THREE.TextureLoader().load('/icons/map-icons/navigator_position_dot.svg');
    const navigatorMarkerDotMaterial = new THREE.SpriteMaterial({ map: navigatorMarkerDotTexture, depthTest: false, color: '#c90c3b' });
    const navigatorMarkerDot = new THREE.Sprite(navigatorMarkerDotMaterial);
    navigatorMarkerDot.renderOrder = 99997;
    navigatorMarkerDot.scale.set(0.5, 0.5, 0.5);

    const navigatorPulsingDotMaterial1 = new THREE.SpriteMaterial({ map: navigatorMarkerDotTexture, depthTest: false, color: '#c90c3b' });
    this.navigatorPulsingDot1 = new THREE.Sprite(navigatorPulsingDotMaterial1);
    this.navigatorPulsingDot1.renderOrder = 99997;
    this.navigatorPulsingDot1.scale.set(0.5, 0.5, 0.5);

    const navigatorPulsingDotMaterial2 = new THREE.SpriteMaterial({ map: navigatorMarkerDotTexture, depthTest: false, color: '#c90c3b' });
    this.navigatorPulsingDot2 = new THREE.Sprite(navigatorPulsingDotMaterial2);
    this.navigatorPulsingDot2.renderOrder = 99997;
    this.navigatorPulsingDot2.scale.set(0.5, 0.5, 0.5);

    this.navigatorMarkerGroup = new THREE.Group();
    this.navigatorMarkerGroup.add(navigatorMarkerSprite);
    this.navigatorMarkerGroup.add(navigatorMarkerText);
    this.navigatorMarkerGroup.add(navigatorMarkerDot);
    this.navigatorMarkerGroup.add(this.navigatorPulsingDot1);
    this.navigatorMarkerGroup.add(this.navigatorPulsingDot2);

    this.pulseMarkerDot();

    document.addEventListener('onFloorLoaded', () => {
      this.onFloorLoaded();
      this.loadCameraView();
    });

    this.hasInitialized = true;
  }

  onFloorLoaded() {
    if (SettingsNavigatorState.navigatorMarker
      && SettingsNavigatorState.navigatorMarker.visible
      && UserState.selectedFloorplan.Zid === SettingsNavigatorState.navigatorMarker.floorZid) {
      this.showNavigatorMarker();
    }
  }

  setNavigatorMarker(position, floorZid = MapState.floor.Zid) {
    SettingsNavigatorState.setNavigatorMarker(true, position, floorZid);
    this.showNavigatorMarker();
  }

  showNavigatorMarker() {
    SettingsNavigatorState.setNavigatorMarker(true);
    this.navigatorMarkerGroup.visible = true;

    if (SettingsNavigatorState.navigatorMarker?.position
      && SettingsNavigatorState.navigatorMarker.floorZid === MapState.floor.Zid) {
      this.navigatorMarkerGroup.position.set(
        SettingsNavigatorState.navigatorMarker.position.x,
        SettingsNavigatorState.navigatorMarker.position.y,
        SettingsNavigatorState.navigatorMarker.position.z,
      );
      if (this.navigatorMarkerGroup.parent !== MapState.scene) {
        MapState.scene.add(this.navigatorMarkerGroup);
      }
    }
  }

  hideNavigatorMarker() {
    SettingsNavigatorState.setNavigatorMarker(false);
    this.navigatorMarkerGroup.visible = false;
  }

  loadCameraView() {
    const cameraView = SettingsNavigatorState.getCameraView();

    if (cameraView) {
      MapController.panAndZoomToPosition(
        cameraView.position,
        cameraView.zoom,
      );
    } else {
      MapController.setCameraView(MapState.customCameraViewType);
    }
  }

  resetCameraView() {
    SettingsNavigatorState.removeCameraView();
    MapController.setCameraView(MapState.customCameraViewType);

    new CustomEvent(i18n.t('Components.Settings.map_camera_reset_to_default_map_view'), 'global_error_message', 'success').dispatch();
  }

  saveCameraView() {
    SettingsNavigatorState.setCameraView(
      MapState.camera.position,
      MapState.camera.zoom,
    );

    new CustomEvent(i18n.t('Components.Settings.map_camera_save_view_current_view_saved'), 'global_error_message', 'success').dispatch();
  }

  pulseMarkerDot(speed = 2000) {
    setInterval(() => {
      this.navigatorPulsingDot1.scale = new THREE.Vector3(0.4, 0.4, 0.4);
      this.navigatorPulsingDot1.material.opacity = 1;
      const pulseAnimation = new TWEEN.Tween(this.navigatorPulsingDot1.scale)
        .to({ x: 0.8, y: 0.8, z: 0.8 }, speed)
        .easing(TWEEN.Easing.Circular.Out);

      const fadeAnimation = new TWEEN.Tween(this.navigatorPulsingDot1.material)
        .to({ opacity: 0 }, speed)
        .easing(TWEEN.Easing.Circular.Out)
        .onUpdate(() => {
          window.dispatchEvent(new Event('map_render'));
        });

      pulseAnimation.start();
      fadeAnimation.start();
    }, speed);

    setInterval(() => {
      this.navigatorPulsingDot2.scale = new THREE.Vector3(0.4, 0.4, 0.4);
      this.navigatorPulsingDot2.material.opacity = 1;
      const pulseAnimation = new TWEEN.Tween(this.navigatorPulsingDot2.scale)
        .to({ x: 1, y: 1, z: 1 }, speed * 2)
        .easing(TWEEN.Easing.Circular.Out);

      const fadeAnimation = new TWEEN.Tween(this.navigatorPulsingDot2.material)
        .to({ opacity: 0 }, speed * 2)
        .easing(TWEEN.Easing.Circular.Out)
        .onUpdate(() => {
          window.dispatchEvent(new Event('map_render'));
        });

      pulseAnimation.start();
      fadeAnimation.start();
    }, speed * 2);
  }

  reset(resetCamera = true) {
    MapController.selectionManager.hideNavigatorResourcePopUp();
    MapController.deselectMapLocation();
    if (resetCamera) {
      this.loadCameraView();
    }
  }
}

export default new MapNavigatorController();
