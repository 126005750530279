<template>
  <div>
    <div class="flex arrow__container">
      <template v-if="activeDevice">
        <div
          class="arrow__border padding padding-extra-tiny padding-all"
          :class="isPressingUp ? 'pressing' : '' "
          @mousedown="moveUp"
          @mouseup="stop"
          @mouseleave="stop"
        >
          <svg-icon
            class="arrow"
            src="/icons/controller_arrow_up.svg"
          />
        </div>
        <div
          class="arrow__border padding padding-extra-tiny padding-all"
          :class="isPressingDown ? 'pressing' : '' "
          @mousedown="moveDown"
          @mouseup="stop"
          @mouseleave="stop"
        >
          <svg-icon
            class="arrow"
            src="/icons/controller_arrow_down.svg"
          />
        </div>
      </template>
      <router-link to="/controller">
        <Button
          class="button_controller"
          size="tiny"
          variant="pastel-primary"
        >
          <WellnessSidebarItem />
        </Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/button.vue';
import ConnectState from '@/singletons/connect.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import WellnessSidebarItem from './wellness.sidebar.item.vue';

export default {
  components: {
    WellnessSidebarItem,
    Button,
  },
  data() {
    return {
      isPressingUp: false,
      ConnectState,
      isPressingDown: false,
    };
  },
  computed: {
    activeDevice() { return ConnectState.selectedDevice; },
    sitPosition() { return UserState.profile.SitHeight; },
    standPosition() { return UserState.profile.StandHeight; },
    devicePosition() {
      if (!this.activeDevice) {
        return UserState.currentPosition || 'sitting';
      }
      const activeState = Math.abs(this.sitPosition - this?.activeDevice?.position) < Math.abs(this.standPosition - this?.activeDevice?.position) ? this.sitPosition : this.standPosition;
      if (activeState === this.sitPosition) {
        return 'sitting';
      }
      return 'standing';
    },
  },
  watch: {
    'activeDevice.position': function a() {
      this.setCurrentPosition();
    },
  },
  mounted() {
    this.setCurrentPosition();
  },
  methods: {
    setCurrentPosition() {
      if (this?.devicePosition !== UserState.currentPosition) {
        UserState.currentPosition = this?.devicePosition;
      }
    },
    moveUp() {
      this.activeDevice.moveUp();
      this.isPressingUp = true;
    },
    moveDown() {
      this.activeDevice.moveDown();
      this.isPressingDown = true;
    },
    stop() {
      this.activeDevice.stop();
      this.isPressingUp = false;
      this.isPressingDown = false;
    },
  },
};

</script>

<style lang="scss">

.arrow__container {
    gap: 1rem;
    .button_controller {
      padding: 0.3rem;
      min-width: 4rem;
      h6 {
        color: var(--primary-color);
        font-size: 0.7rem;
      }
    }
    .arrow__border {
      border: 0.15rem solid white;
      border-radius: 0.5rem;
      cursor: pointer;
      .arrow {
        svg {
          height: 0.9rem;
        }
      }
      &.pressing {
        background: white;
        svg {
          path{
            fill: var(--primary-color);
          }
        }
      }
      &.pressing:hover:active {
        background: white;
        animation: pulse_animation 2s infinite;
        svg {
          path{
            fill: var(--primary-color);
          }
        }
      }
    }
    @keyframes pulse_animation {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7),
        0 0 0 0 rgba(214, 214, 214, 0.7);
      }

      70% {
      transform: scale(1.03);
      box-shadow: 0 0 0 10px rgba(173, 238, 189, 0),
      0 0 0 5px rgba(43, 139, 54, 0);
      }

      100% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(173, 238, 189, 0),
        0 0 0 5px rgba(43, 139, 54, 0);
      }
    }
  }

</style>
