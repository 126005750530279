const parseQueryArray = (commaSeperateArray) => {
  if (!commaSeperateArray) return [];
  return commaSeperateArray.split(';');
};

export const createQueryStringFromObject = (obj) => {
  let queryString = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    queryString += `${key}=${value}&`;
  }
  return queryString.slice(0, -1);
};

export const getHasFilters = (filterQuery) => {
  if (!filterQuery) return [];
  return parseQueryArray(filterQuery);
};

export const getAmountFilters = (filterQuery) => {
  const selectedValues = {};
  const parsed = parseQueryArray(filterQuery);
  parsed.forEach((filter) => {
    const [key, value] = filter.split(':');
    selectedValues[key] = Number(value);
  });
  return selectedValues;
};

export const createAmountString = (object) => {
  if (Object.entries(object).length === 0) return '';
  let string = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const [title, value] of Object.entries(object)) {
    string += `${title}:${value};`;
  }
  return string.slice(0, -1);
};

export const createHasString = (array) => {
  if (array.length === 0) return '';
  let string = '';
  // eslint-disable-next-line no-return-assign
  array.forEach((filter) => string += `${filter};`);
  return string.slice(0, -1);
};
