import apiClient from './api.interceptor';

const baseUrlProxy = process.env.VUE_APP_BASE_URL;

// eslint-disable-next-line import/prefer-default-export
// GET
export const getBookings = (from, until) => apiClient.get(`${baseUrlProxy}/booking/booking?realm=true&from=${from}&until=${until}`);
export const getBookingsForParent = (from, until, parent) => apiClient.get(`${baseUrlProxy}/booking/booking?parent=${parent}&from=${from}&until=${until}`);
export const getBookingsForResource = (from, until, zid) => apiClient.get(`${baseUrlProxy}/booking/booking?from=${from}&until=${until}&zid=${zid}`);
export const getUserBookings = (username, from, until) => apiClient.get(`${baseUrlProxy}/booking/booking?attendee=true&from=${from}&until=${until}&username=${username}`);

// PUT
export const updateBooking = (booking, bid) => apiClient.put(`${baseUrlProxy}/booking/booking/${bid}`, booking);
export const checkOutBooking = (bid) => apiClient.put(`${baseUrlProxy}/booking/booking/${bid}/end`);
export const checkInBooking = (pack, bid) => apiClient.put(`${baseUrlProxy}/booking/booking/${bid}/checkin`, pack);

// POST
export const postBooking = (booking) => apiClient.post(`${baseUrlProxy}/booking/booking`, booking);

// DELETE
export const deleteBooking = (bid) => apiClient.delete(`${baseUrlProxy}/booking/booking/${bid}`);
