import Vue from 'vue';
// import ApplicationState, { STATE_NOT_APPROVED } from '@/singletons/application.state.singleton';

class EventBus {
  constructor() {
    this.events = [];
    this.listener = null;
    this.broadcast = new Vue();
  }

  init() {
    if (!this.listener) {
      this.listener = window.addEventListener('message', this.handler.bind(this));
    }
  }

  handler(evt) {
    try {
      const event = JSON.parse(evt.data);
      // console.log('ev', event);
      this.broadcast.$emit('iframe:event', event);
    } catch (err) {
      // console.log('Couldnt parse new event');
    }
  }
}

export default new EventBus();
