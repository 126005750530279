<template>
  <router-link to="/timer">
    <template>
      <ProgressBarCircle
        :percentage="timerActive ? percentage : 100"
        :progress-color="progressBarColor"
        :stroke-width="5"
        :size="25"
        :shadow-offset="false"
        :pulsate="shouldPulsate"
      />
      <h6
        class="wellness__sidebar-item"
      >
        {{ timerActive ? timeLeft : $t('Components.Wellness.title') }}
        <br>
      </h6>
    </template>
  </router-link>
</template>

<script>
import ReminderStateSingleton, {
  REMINDER_STATE_FINISHED, REMINDER_STATE_NOT_CONNECTED, RING_COLOR_NOT_ACTIVE, RING_COLOR_ACTIVE, REMINDER_STATE_ACTIVE, REMINDER_STATE_SNOOZE,
} from '@/singletons/reminder.state.singleton';
import ProgressBarCircle from '../../reminders.timer/components/circle.component.vue';

export default {
  components: {
    ProgressBarCircle,
  },

  data() {
    return {
      ReminderStateSingleton,
    };
  },

  computed: {
    shouldPulsate() {
      return ReminderStateSingleton.reminder.state === REMINDER_STATE_FINISHED;
    },
    timerActive() {
      return ReminderStateSingleton.profile?.reminder > 0;
    },

    percentage() {
      return this.isTimerRunning ? ReminderStateSingleton.reminder.percent : 100;
    },

    progressBarColor() {
      if (this.timerActive) {
        return ReminderStateSingleton.reminder.color;
      }
      if (this.$route.path.includes('/timer')) {
        return 'var(--primary-color)';
      }
      return 'var(--secondary-font-color)';
    },

    /** might use in later versions */
    ringColor() {
      return ReminderStateSingleton.reminder.state === REMINDER_STATE_NOT_CONNECTED ? RING_COLOR_NOT_ACTIVE : RING_COLOR_ACTIVE;
    },

    isTimerRunning() {
      return !!(ReminderStateSingleton.reminder.state === REMINDER_STATE_ACTIVE || ReminderStateSingleton.reminder.state === REMINDER_STATE_SNOOZE);// (AppModel.reminder.state !== REMINDER_STATE_NOT_CONNECTED && AppModel.reminder.state !== REMINDER_STATE_FINISHED);
    },

    timeLeft() {
      return ReminderStateSingleton.reminder.timeLeftAsString;
    },
  },
};

</script>

<style scoped>

  h6 {
    font-variant-numeric: tabular-nums;
    margin-top: 0.5rem;
  }
</style>
<style lang="scss">
@import '../../device.controller/style/controller.scss';
</style>
