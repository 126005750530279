const TTL = 1000 * 60 * 60 * 24;

class SearchResultState {
  constructor() {
    this.recentFindResults = [];
    this.recentOrganizerResults = [];
  }

  clearOldFindResults() {
    // Clear items older than TTL (24h)
    this.recentFindResults = this.recentFindResults.filter((it) => (it.timestamp + TTL) > Date.now());
  }

  clearOldOrganizerResults() {
    // Clear items older than TTL (24h)
    this.recentOrganizerResults = this.recentOrganizerResults.filter((it) => (it.timestamp + TTL) > Date.now());
  }

  storeClickedFindResult(obj) {
    this.recentFindResults = this.recentFindResults.slice(-10).filter((it) => {
      const { Username, Zid } = it?.obj || {};
      // Filter out items that are same as obj we are about to add
      return !((Username && Username === obj.Username) || (Zid && Zid === obj.Zid));
    });

    this.recentFindResults.push({
      timestamp: Date.now(),
      obj,
    });
  }

  storeClickedOrganizerResult(obj) {
    this.recentOrganizerResults = this.recentOrganizerResults.slice(-10).filter((it) => {
      const { Username, Zid } = it?.obj || {};
      // Filter out items that are same as obj we are about to add
      return !((Username && Username === obj.Username) || (Zid && Zid === obj.Zid));
    });

    this.recentOrganizerResults.push({
      timestamp: Date.now(),
      obj,
    });
  }

  destroy() {
    this.recentFindResults = [];
    this.recentOrganizerResults = [];
  }
}

export default new SearchResultState();
