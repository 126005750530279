import { getTicket } from '@/util/api';
import SocketService from '@/Services/Socket/socket.service';

class SocketState {
  constructor() {
    this.socket = null;
    this.cid = null;
    this.url = process.env.VUE_APP_BASE_SOCKET_URL;
    this.isActive = false;
  }

  async init(parent) {
    // console.log(parent);
    this.cid = parent;

    if (this.isActive) { return; }
    this.isActive = true;
    try {
      const data = await getTicket();
      this.socket = new WebSocket(`${this.url}/listen/realm/${this.cid}?${data.data}`);
      this.socket.binaryType = 'arraybuffer';
      this.socket.onmessage = (event) => {
        SocketService.handle(event);
      };

      this.socket.onclose = (evt) => {
        console.log(evt);
        this.socket = null;
        this.isActive = false;
        this.retry();
      };
    } catch (e) {
      console.log(e);
    }
  }

  retry() {
    setTimeout(() => {
      if (this.cid) {
        this.init(this.cid);
      }
    }, 10000);
  }

  destroy() {
    if (this.socket) { this.socket.close(); }
    this.socket = null;
    this.cid = null;
    this.url = process.env.VUE_APP_BASE_SOCKET_URL;
  }
}

export default new SocketState();
