/* eslint-disable import/no-extraneous-dependencies */
/** State */
import sha from 'sha.js';
import ApplicationState, {
  STATE_VERIFY_SESSION, STATE_AUTHORIZED, STATE_LOGIN, STATE_AUTH_RETRY,
} from '@/singletons/application.state.singleton';
import AuthState from '@/singletons/auth.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import ConnectState from '@/singletons/connect.state.singleton';
import SocketState from '@/singletons/socket.state.singleton';
import WeekState from '@/singletons/week.state.singleton';
import LocationSelectionController from '@/views/location.selection/location.selection.controller';
import PhoneService from '@/Services/Phone/phone.service';
import SocketIO from '@/singletons/socket.io.state.singleton';
import StatesIOService from '@/Services/SocketIO/states.io.service';
import RemindersModel from '@/models/reminders/reminders.model';
// import EsyncController from '@/views/admin/esync/esync.controller';
// eslint-disable-next-line no-unused-vars
// import PhoneService from '@/Services/Phone/phone.service';
import UserService from '@/Services/User/user.service';
import CompanyService from '@/Services/Company/company.service';
import { IPC_MESSAGES } from '@/native/auth/auth.constants';
/** NPM */

/** API */
import { clearLocalStorage } from '@/functions/helper.functions';
import { STORAGE_KEYS } from '@/views/settings/navigator/settings.navigator.state';
import SearchResultState from '@/singletons/search.result.state';
import {
  authenticateAzure, authenticateBasic, logout as signout, verifyPassword, updateUserPassword, authenticateCookie,
} from '../../util/api';
import ZoneStateService from '../Zones/zone.state.service';

// Allow some exceptions to remain after clearing localStorage
const LOCALSTORAGE_EXCEPTIONS = Object.keys({
  ...STORAGE_KEYS,
});

// importing icpRender
let ipcRenderer = {};
if (ApplicationState.platform === 'electron') {
  // eslint-disable-next-line global-require
  const electron = require('electron');
  ({ ipcRenderer } = electron);
}
class AuthService {
  static init() {
    if (ApplicationState.platform === 'electron') {
      AuthService.onTokenCallback = AuthService.registerOnTokenCallback();
      AuthService.onStorageCallback = AuthService.registerStorageCallback();
      AuthService.onMethodCallback = AuthService.registerOnMethodCallback();
    }
  }

  static registerOnTokenCallback() {
    return ipcRenderer.on(IPC_MESSAGES.ON_TOKEN, (event, token) => {
      // console.log(`onToken: ${token}`);
      AuthState.setAuthenticated(true);
      AuthState.setToken(token);
      AuthState.setMethod();
      AuthService.verifyAzureToken();
    });
  }

  static registerStorageCallback() {
    return ipcRenderer.on(IPC_MESSAGES.CLEAR_STORAGE, () => {
      clearLocalStorage(LOCALSTORAGE_EXCEPTIONS);
    });
  }

  static setMethod(method) {
    AuthState.setMethod(method);
  }

  static registerOnMethodCallback() {
    return ipcRenderer.on(IPC_MESSAGES.SET_METHOD, (event, method) => {
      AuthState.setMethod(method);
    });
  }

  static hasSession() {
    switch (AuthState.getMethod()) {
      case 'basic': return true;
      case 'azure': return true;
      default: {
        return false;
      }
    }
  }

  static async verifyBasicPassword(password, username) {
    const user = username || UserState.username;
    try {
      const hash = sha('sha256').update(user + password).digest('hex');
      const response = await verifyPassword(user, hash);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async changeBasicPassword(password, username) {
    const user = username || UserState.username;
    const hash = sha('sha256').update(user + password).digest('hex');
    const data = {
      Type: 'UP', Password: hash, Username: user, LastModified: Date.now(),
    };
    await updateUserPassword(user, data);
  }

  static async verifySession() {
    ApplicationState.setState(STATE_VERIFY_SESSION);
    switch (AuthState.getMethod()) {
      case 'basic': await AuthService.verifyBasicToken(); break;
      case 'azure': await AuthService.verifyAzureToken(); break;
      case 'cookie': await AuthService.verifyPhone(); break;
      default: console.warn('VerifySession found no auth method');
    }

    if (!AuthState.isAuthenticated) {
      ApplicationState.setState(STATE_LOGIN);
    }
  }

  static getIdToken = () => localStorage.getItem('msal.idtoken');

  static async loginBasic(credentials) {
    ApplicationState.setState(STATE_VERIFY_SESSION);
    AuthService.constructUserCredentials(credentials);
    try {
      const response = await authenticateBasic();
      ApplicationState.setState(STATE_AUTHORIZED);
      setTimeout(() => {
        AuthService.authRequestSuccessCallback(response);
      }, 1000);
      return;
    } catch (e) {
      console.log('Login in basic error', e);
      ApplicationState.appState = STATE_LOGIN;
      throw e;
    }
  }

  static constructUserCredentials({ email, password }) {
    try {
      const hashedPassword = sha('sha256').update(email + password).digest('hex');
      localStorage.setItem('auth_username', email);
      localStorage.setItem('auth_password', hashedPassword);
      AuthState.setMethod();
    } catch (e) {
      console.log('Error constructing user credentials: ', e);
    }
  }

  static async verifyBasicToken() {
    try {
      const response = await authenticateBasic();
      await AuthService.authRequestSuccessCallback(response);
    } catch (error) {
      AuthService.handleBasicAuthError(error);
    }
  }

  static async verifyAzureToken() {
    if (ApplicationState.platform === 'electron') {
      await AuthService.verifyAzureTokenElectron();
    } else {
      await AuthService.verifyAzureTokenWeb();
    }
  }

  static async verifyAzureTokenElectron() {
    try {
      const token = await ipcRenderer.invoke(IPC_MESSAGES.REQUEST_TOKEN);
      AuthState.setToken(token);
      const response = await authenticateAzure();
      await AuthService.authRequestSuccessCallback(response);
    } catch (error) {
      AuthService.handleAzureAuthError(error);
    }
  }

  static async verifyAzureTokenWeb() {
    try {
      const response = await authenticateAzure();
      await AuthService.authRequestSuccessCallback(response);
    } catch (error) {
      AuthService.handleAzureAuthError(error);
    }
  }

  static async verifyPhone() {
    try {
      PhoneService.send(PhoneService.events.phone_app_version, { token: null, isAuthorized: false, reason: null });
      const version = await PhoneService.get(PhoneService.events.phone_app_version);
      /* Using token for app version over 1.4.25 */
      if (version) {
        PhoneService.send(PhoneService.events.phone_user_authstate, { token: null, isAuthorized: false, reason: null });
        const res = await PhoneService.get(PhoneService.events.phone_token_update);
        localStorage.setItem('auth_token', res.token);
        if (!res?.isAuthorized) {
          throw new Error(res?.reason || 'unauthorized');
        }
      }
      const response = await authenticateCookie();
      await AuthService.authRequestSuccessCallback(response);
    } catch (e) {
      AuthService.handleBasicAuthError(e);
    }
  }

  static async handleAzureAuthError(e) {
    console.log('Got Azure auth error: ');
    console.log(e);
    const eMsg = this.getErrorMessageForPhone('azure:auth:', e);
    if (e?.response?.status === 403) {
      console.log('403 Error');
    } else if (e?.response?.status === 401) {
      // If first error loginRedirect

      if (AuthService.isRetry()) {
        console.log('isRetry AUTH');
        AuthService.removeIsRetry();
        AuthService.logout(eMsg);
        return;
      }

      if (ApplicationState.platform === 'electron') {
        AuthService.setIsRetry();
        await this.verifyAzureTokenElectron();
      } else {
        AuthService.setIsRetry();
        await window.msal.loginRedirect({
          scopes: ['user.read'],
        });
      }
    } else {
      AuthService.logout(eMsg);
    }
  }

  // TODO: Currently the order below MATTERS, app might not work if we change it. Redo this in 1.4.1 with a statemanager.
  static async authRequestSuccessCallback({ data }) {
    const {
      user, modules, token,
    } = data;
    AuthState.isAuthenticated = true;
    AuthState.setToken(token);
    WeekState.init();
    UserService.initState(user);
    await CompanyService.initState(modules);
    ZoneStateService.initState();
    SocketState.init(UserState.getUserParent());
    SocketIO.setupSocketConnection();
    StatesIOService.SetupListeners();
    LocationSelectionController.init();

    // Used for native app reminders
    if (ApplicationState.platform === 'electron') {
      RemindersModel.init();
    }

    // if (UserService.hasAllRoles(['role_user_roles'])) {
    //   EsyncController.init();
    // }
    // if (ApplicationState.platform === 'electron') ConnectService.init();
    AuthService.removeIsRetry();
  }

  static setIsRetry() {
    localStorage.setItem('azure_retry', true);
    ApplicationState.setState(STATE_AUTH_RETRY);
  }

  static removeIsRetry() {
    localStorage.removeItem('azure_retry');
  }

  static isRetry() {
    return localStorage.getItem('azure_retry') || null;
  }

  static async logout(phoneLogoutMessage, logoutPhone = false) {
    if (PhoneService.isInPhoneApp()) {
      AuthService.logoutPhone(phoneLogoutMessage, logoutPhone);
      return;
    }
    if (ApplicationState.platform === 'electron') {
      await AuthService.logoutElectron();
    } else {
      await signout();
      // AuthService.logoutWeb();
    }
    const tempStoreFloor = localStorage.getItem('floor');
    clearLocalStorage(LOCALSTORAGE_EXCEPTIONS);
    localStorage.setItem('floor', tempStoreFloor);
    AuthState.destroy();
    CompanyService.destroyState();
    UserState.destroy();
    SocketState.destroy();
    LocationSelectionController.reset();
    ApplicationState.appState = STATE_LOGIN;
    SearchResultState.destroy();
  }

  static async logoutWeb() {
    if (AuthState.getMethod() === 'azure') { window.msal.logout(); }
  }

  static async logoutElectron() {
    await ConnectState.destroy();
    await signout();
    if (AuthState.getMethod() === 'azure') {
      ipcRenderer.send(IPC_MESSAGES.ON_LOGOUT);
    }
  }

  static handleBasicAuthError(e) {
    AuthService.isAuthenticated = false;
    console.log('Got basicAuthError: ', e);
    const eMsg = this.getErrorMessageForPhone('basic:auth:', e);
    if (e?.response?.status) {
      const eCode = e.response.status;
      if (eCode === 401) {
        console.log('Basic auth got error 401');
        AuthService.logout(eMsg);
      } else if (eCode === 403) {
        console.log('Basic auth error 403');
        AuthService.logout(eMsg);
      }
    }
  }

  static getErrorMessageForPhone(preFix, e) {
    let message = e?.response?.data?.Code;
    message = message || e?.message;
    message = message || 'unknown';
    message += ':';
    message += e?.response?.data?.status;
    const res = preFix + message;
    return res;
  }

  static logoutPhone(reason, intentional = false) {
    if (intentional) AuthService.signOutPhone(reason);
    else AuthService.unAuthorizePhone(reason);
  }

  static unAuthorizePhone(reason) {
    PhoneService.send(PhoneService.events.phone_user_authstate, { isAuthorized: false, reason });
  }

  static signOutPhone(reason) {
    PhoneService.send(PhoneService.events.phone_user_signout, { reason });
  }
}

window.addEventListener('cookie:expired', () => {
  AuthService.logoutPhone('cookie:expired');
});

window.addEventListener('error:401', () => {
  AuthService.logout('error:401:unauthenticated', false);
});

export default AuthService;
