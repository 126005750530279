// import dns from 'dns';

export const STATE_INITIAL = 0; // On start up.
export const STATE_MOBILE_USER = 7; // If user tries to access website through mobile device
export const STATE_LOGIN = 10; // We dont have a token.
export const STATE_AUTHENTICATED = 20; // We have a token.
export const STATE_AUTH_RETRY = 25; // Checking token.
export const STATE_VERIFY_SESSION = 30; // Checking token.
export const STATE_UNAUTHORIZED = 31; // The token can't be verified, not being used right now
export const STATE_AUTHORIZED = 40; // Token verified.

export const INTERNET_STATE_NORMAL = 0;
export const INTERNET_STATE_OFFLINE = 10;
export const INTERNET_STATE_SERVICE_UNAVAILABLE = -10;
class ApplicationState {
  constructor() {
    this.appState = STATE_INITIAL;
    this.platform = process.env.VUE_APP_PLATFORM;
  }

  getState() {
    return this.appState;
  }

  setState(state) {
    this.appState = state;
  }

  isElectron() {
    return this.platform === 'electron';
  }
}

export default new ApplicationState();
