<template>
  <div @click.capture="onClickLink">
    <router-link to="/location">
      <div class="location_selector-container">
        <svg-icon
          class="location-icon"
          src="/icons/building_nav.svg"
        />
        <div
          v-if="!!UserState.selectedFloorplan"
          class="location_name"
        >
          <h6 class="tiny-thin">
            {{ locationParentName }}
          </h6>
          <h6 class="tiny-thin">
            {{ locationName }}
          </h6>
        </div>
        <div
          v-else
          class="location_name"
        >
          <h6
            class="small-medium"
          >
            {{ $t('Components.Sidebar.Location') }}
          </h6>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>

import UserState from '@/singletons/user.state.singleton';
import SettingsNavigatorState from '@/views/settings/navigator/settings.navigator.state';

export default {
  data() {
    return {
      UserState,
      SettingsNavigatorState,
    };
  },
  computed: {
    tooltipText() {
      return this.locationParentName ? `${this.locationParentName} - ${this.locationName}` : this.locationName;
    },
    selectedLocation() {
      return UserState.selectedFloorplan.Name;
    },
    locationParentName() {
      return UserState.selectedLocation?.parentName;
    },
    locationName() {
      return UserState.selectedLocation?.title;
    },
  },
  methods: {
    onClickLink(e) {
      const isActiveLink = !!e.currentTarget.querySelector('.router-link-exact-active');

      if (!SettingsNavigatorState.showSidePage) {
        SettingsNavigatorState.showSidePage = true;
      } else if (isActiveLink) {
        SettingsNavigatorState.showSidePage = false;
      }
    },
  },
};

</script>

<style lang="scss">
.location_selector-container{
  display: flex;
  gap: 0.5rem;
  .location-icon{
    fill:white;
    svg{
      vertical-align: baseline;
      height: 30px;
    }
  }
  .location_name{
    h6{
      color:white;
      margin: 0;
    }
  }
}
</style>
