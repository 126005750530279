import apiClient from './api.interceptor';

const baseUrlProxy = process.env.VUE_APP_BASE_URL;

// GET
export const getIssue = (id) => apiClient.get(`${baseUrlProxy}/v1/issue/${id}`, { withCredentials: true });
export const getIssues = (companyid, from, until, getdeleted) => apiClient.get(`${baseUrlProxy}/v1/issue?companyid=${companyid}&from=${from}&until=${until}&getdeleted=${getdeleted}`, { withCredentials: true });
export const getIssueStatuses = () => apiClient.get(`${baseUrlProxy}/v1/issue/statuses`);
export const getPinnedIssues = (companyid) => apiClient.get(`${baseUrlProxy}/v1/issue/pinned?companyid=${companyid}`, { withCredentials: true });

// POST
export const createIssue = (body) => apiClient.post(`${baseUrlProxy}/v1/issue`, body, { withCredentials: true });
// TODO - Implement assignee solution
export const assignIssue = (id, userid) => apiClient.post(`${baseUrlProxy}/v1/issue/${id}/assign`, { userid }, { withCredentials: true });

// PUT / PATCH
export const updateIssue = (id, partialIssue) => apiClient.patch(`${baseUrlProxy}/v1/issue/${id}`, partialIssue, { withCredentials: true });

// DELETE
export const removeIssue = (id) => apiClient.delete(`${baseUrlProxy}/v1/issue/${id}`, { withCredentials: true });
// TODO - Implement assignee solution
export const unassignIssue = (id, userid) => apiClient.delete(`${baseUrlProxy}/v1/issue/${id}/unassign`, { data: { userid }, withCredentials: true });
