import apiClient from './api.interceptor';

const baseUrl = process.env.VUE_APP_BASE_URL; // 'http://localhost:3000';

/* GET */
export const getSyncedCompany = (parent) => apiClient.get(`${baseUrl}/v1/admin/esync/${parent}`, { withCredentials: true });
export const getSyncedUsers = (parent) => apiClient.get(`${baseUrl}/v1/admin/esync/${parent}/users`, { withCredentials: true });
export const getEsyncSettings = (parent) => apiClient.get(`${baseUrl}/v1/admin/esync/${parent}/settings`, { withCredentials: true });
export const getSyncStatus = (parent) => apiClient.get(`${baseUrl}/v1/admin/esync/${parent}/status`, { withCredentials: true });
export const getSyncedCompanyLog = (parent) => apiClient.get(`${baseUrl}/v1/admin/esync/${parent}/debug/log`, { withCredentials: true });

/* POST */
export const setEsyncSettings = (parent, settings) => apiClient.put(`${baseUrl}/v1/admin/esync/${parent}/settings`, settings, { withCredentials: true });
export const setDebugMode = (parent, mode) => apiClient.post(`${baseUrl}/v1/admin/esync/${parent}/debug`, mode, { withCredentials: true });
export const startSync = (parent) => apiClient.post(`${baseUrl}/v1/admin/esync/${parent}/start`, { withCredentials: true });
/* Restart with fullsync */
export const restartSync = (parent) => apiClient.post(`${baseUrl}/v1/admin/esync/${parent}/restart`, { withCredentials: true });

/* DELETE */
export const stopSync = (parent) => apiClient.delete(`${baseUrl}/v1/admin/esync/${parent}/stop`, { withCredentials: true });
