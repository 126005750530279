import { io } from 'socket.io-client';

export const CONNECTION_STATES = {
  CONNECTED: 'CONNECTED',
  CONNECTING: 'CONNECTING',
  DISCONNECTED: 'DISCONNECTED',
};

class SocketIOService {
  socket;

  constructor() {
    this.ioUrl = process.env.VUE_APP_BASE_SOCKET_IO_URL;
    this.socketConnections = {};
    this.options = {
      extraHeaders: {},
    };

    /** When using locally served socket. */
    if (process.env.VUE_APP_LOCAL_JWT) {
      this.options.extraHeaders = {
        cookies: process.env.VUE_APP_LOCAL_JWT,
      };
    }
    this.options.extraHeaders['idesk-auth-token'] = localStorage.getItem('auth_token');
  }

  setupSocketConnection() {
    // console.log('setup IO');
    this.socket = this.newIO('socket', this.ioUrl, {
      withCredentials: true,
      ...this.options,
    });
    this.legacy = this.newIO('legacy', `${this.ioUrl}/legacy`, {
      withCredentials: true,
      ...this.options,
    });
  }

  newIO(name, url, opts) {
    const socket = io(url, opts);
    this.socketConnections[name] = false;

    return socket
      .on('connect', () => {
        this.socketConnections[name] = socket.connected;
      }).on('disconnect', () => {
        this.socketConnections[name] = socket.connected;
      }).on('connect_error', (error) => {
        console.warn('socket.io.connect_error', name, error.message);
      }).on('error', (error) => {
        console.warn('socket.io.error in ', name, error.message);
      });
  }

  getConnectedState() {
    const connectedCount = Object.values(this.socketConnections).filter((val) => val).length;
    if (connectedCount === Object.keys(this.socketConnections).length) {
      // All connected
      return CONNECTION_STATES.CONNECTED;
    } if (connectedCount === 0) {
      // All disconnected
      return CONNECTION_STATES.DISCONNECTED;
    }

    // Some disconnected. Connecting...
    return CONNECTION_STATES.CONNECTING;
  }
}

export default new SocketIOService();
