/* eslint-disable no-underscore-dangle */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-useless-constructor */

import * as THREE from 'three';
import CameraControls from 'camera-controls';
import MapState from '@/singletons/map.state.singleton';
import FloorCameraSettings from '@/classes/floor.camera.settings.class';

/**
 * Camera controls is library from:
 * https://github.com/yomotsu/camera-controls
 */

/**
 * Handle the different camera options, perspectives.
 * Support for pan, zoom, rotation.
 */
class CameraController {
  constructor() {
    MapState.camera = null;
    MapState.controls = null;
    MapState.cameraViewType = null;
    this.cameraTarget = new THREE.Vector3();
    this.orthographic = true;
    this.distance = 0;
  }

  setupCamera() {
    if (FloorCameraSettings.checkIfExistsInStorage(MapState.floor.Zid)) {
      const view = FloorCameraSettings.retriveFloorCamera(MapState.floor.Zid);
      this.setCameraView(view);
    } else {
      this.setCameraView('Perspective');
    }
  }

  updateCameraControls = () => {
    // Dispose if one exists already
    if (MapState.controls != null) {
      MapState.controls.dispose();
    }

    // Camera controls
    CameraControls.install({ THREE });
    MapState.controls = new CameraControls(MapState.camera, MapState.renderer.domElement);
    MapState.controls.dampingFactor = 0.1;
    MapState.controls.draggingDampingFactor = 1;
    MapState.controls.dollyToCursor = true;

    // Camera inputs
    MapState.controls.mouseButtons.left = CameraControls.ACTION.TRUCK;
    MapState.controls.mouseButtons.middle = CameraControls.ACTION.TRUCK;
    MapState.controls.mouseButtons.right = CameraControls.ACTION.TRUCK;
    MapState.controls.mouseButtons.wheel = this.orthographic ? CameraControls.ACTION.ZOOM : CameraControls.ACTION.DOLLY;
    MapState.controls.mouseButtons.shiftLeft = CameraControls.ACTION.NONE;
    MapState.controls.touches.one = CameraControls.ACTION.TRUCK;
    MapState.controls.touches.two = this.orthographic ? CameraControls.ACTION.TOUCH_ZOOM_TRUCK : CameraControls.ACTION.TOUCH_DOLLY_TRUCK;
    MapState.controls.touches.three = CameraControls.ACTION.TRUCK;

    // Limits
    MapState.controls.minZoom = 0.5;
    MapState.controls.maxZoom = 30;
    MapState.controls.minDistance = 6;
    MapState.controls.maxDistance = 130;

    // Update
    MapState.controls.update(0);

    // Set infinity dolly to allow the camera to zoom past the limit
    MapState.controls.infinityDolly = true;
    MapState.renderer.setAnimationLoop(() => {
      if (MapState.camera.position.y < 5) {
        // If camera moves too far down, push camera and control plane up
        const yD = 5 - MapState.camera.position.y;
        MapState.controls.moveTo(MapState.controls._target.x, MapState.controls._target.y + yD, MapState.controls._target.z, false);
        MapState.camera.position.y += yD;
      }

      MapState.renderer.render(MapState.scene, MapState.camera);
    });
  }

  // MARK: - Handle window resize

  handleWindowResize = () => {
    if (!MapState.camera || !MapState.targetCanvas) {
      return;
    }
    if (this.orthographic) {
      this.updateOrthographicCamera();
    } else {
      MapState.camera.aspect = MapState.targetCanvas.offsetWidth / MapState.targetCanvas.offsetHeight;
      MapState.camera.updateProjectionMatrix();
    }
  }

  setCameraView = (_cameraViewType) => {
    // Set camera view on local model
    if (!_cameraViewType) {
      MapState.cameraViewType = MapState.CAMERA_VIEW_TYPE.overview;
      MapState.customCameraViewType = '';
    } else {
      MapState.setCustomCameraViewType(_cameraViewType);
    }

    // Save selected camera view
    FloorCameraSettings.saveFloorCamera(_cameraViewType, MapState.floor.Zid);

    // Get camera view model
    let view = null;
    if (MapState.floorAsset.Options.views && MapState.floorAsset.Options.views[MapState.customCameraViewType]) {
      view = MapState.floorAsset.Options.views[MapState.customCameraViewType];
    } else {
      view = this.getBuiltInCameraViewModel(MapState.cameraViewType);
    }

    // Create the camera
    if (view.orthographic) {
      this.createOrthographicCamera();
    } else {
      this.createPerspectiveCamera();
    }

    // Set local props
    this.orthographic = view.orthographic;
    this.cameraTarget.set(view.center.x, view.center.y, view.center.z);

    // Set camera transform
    MapState.camera.position.set(view.position.x, view.position.y, view.position.z);
    MapState.camera.rotation.set(view.rotation._x, view.rotation._y, view.rotation._z);

    // Center from model is not correct. Need to get the real center
    // to be able to restore the position/rotation of camera after
    // we create the camera controls.
    const realCenter = this.getFloorProjectedPointFrom(MapState.camera);
    this.cameraTarget.set(realCenter.x, realCenter.y, realCenter.z);

    // Update camera controls, new camera means we need
    // to recreate the controls.
    this.updateCameraControls();

    // Restore camera position/rotation using camera controls
    // Note: Should only modify camera using controls after this point.
    MapState.controls.setLookAt(

      // Camera position
      MapState.camera.position.x,
      MapState.camera.position.y,
      MapState.camera.position.z,

      // Look at position
      this.cameraTarget.x,
      this.cameraTarget.y,
      this.cameraTarget.z,

      // Transition
      false,
    );

    // Fit & center if needed
    if (view.autofit) {
      this.fitAndCenterFloor(MapState.floorplanGroup);
    }
  }

  getBuiltInCameraViewModel = (cameraView) => {
    const topDownModel = {
      orthographic: true,
      position: { x: 0, y: 40, z: 0 },
      rotation: { _x: -1.57, _y: 0, _z: 0 },
      center: { x: 0, y: 0, z: 0 },
      autofit: true,
    };

    const overviewModel = {
      orthographic: true,
      position: { x: 0, y: 40, z: 35 },
      rotation: {
        _x: -0.9669966757528409,
        _y: -0.5029374964254963,
        _z: -0.6099381041370995,
      },
      center: { x: 0, y: 0, z: 0 },
      autofit: true,
    };

    const _3dModel = {
      orthographic: false,
      position: { x: 0, y: 40, z: 35 },
      rotation: { _x: -1, _y: 0, _z: 0 },
      center: { x: 0, y: 0, z: 0 },
      autofit: true,
    };

    let model;
    if (cameraView === MapState.CAMERA_VIEW_TYPE.topDown) {
      model = topDownModel;
    } else if (cameraView === MapState.CAMERA_VIEW_TYPE.overview) {
      model = overviewModel;
    } else if (cameraView === MapState.CAMERA_VIEW_TYPE._3d) {
      model = _3dModel;
    } else {
      model = overviewModel;
    }
    return model;
  }

  createOrthographicCamera = () => {
    MapState.camera = new THREE.OrthographicCamera(
      -MapState.targetCanvas.offsetWidth / 20,
      MapState.targetCanvas.offsetWidth / 20,
      MapState.targetCanvas.offsetHeight / 20,
      -MapState.targetCanvas.offsetHeight / 20,
      -500,
      1000,
    );
  }

  updateOrthographicCamera = () => {
    MapState.camera.left = -MapState.targetCanvas.offsetWidth / 20;
    MapState.camera.right = MapState.targetCanvas.offsetWidth / 20;
    MapState.camera.top = MapState.targetCanvas.offsetHeight / 20;
    MapState.camera.bottom = -MapState.targetCanvas.offsetHeight / 20;
    MapState.camera.updateProjectionMatrix();
  }

  createPerspectiveCamera = () => {
    MapState.camera = new THREE.PerspectiveCamera(
      50,
      MapState.targetCanvas.offsetWidth / MapState.targetCanvas.offsetHeight,
      0.1,
      1000,
    );
  }

  fitAndCenterFloor = (floorGroup) => {
    const boxFloor = (new THREE.Box3()).setFromObject(floorGroup);
    const boxFloorSize = new THREE.Vector3();
    const boxFloorCenter = new THREE.Vector3();

    boxFloor.getSize(boxFloorSize);
    boxFloor.getCenter(boxFloorCenter);

    const hypo = boxFloor.max.clone().sub(boxFloor.min).length();
    const halfHypoVector = new THREE.Vector3(hypo, 0, hypo).multiplyScalar(0.5);

    const boxTemp = new THREE.Box3(
      halfHypoVector.clone().multiplyScalar(-1),
      halfHypoVector.clone().multiplyScalar(1),
    );

    const lastAzimuthAngle = MapState.controls.azimuthAngle;
    const lastPolarAngle = MapState.controls.polarAngle;

    MapState.controls.fitToBox(boxTemp, false);
    MapState.controls.rotateTo(lastAzimuthAngle, lastPolarAngle, false);
    MapState.controls.moveTo(boxFloorCenter.x, boxFloorCenter.y, boxFloorCenter.z, false);
  }

  getFloorProjectedPointFrom = (camera) => {
    const floorPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
    const intersectedPos = new THREE.Vector3();
    const cameraDirection = new THREE.Vector3();

    camera.getWorldDirection(cameraDirection);

    const ray = new THREE.Ray(camera.position, cameraDirection);
    ray.intersectPlane(floorPlane, intersectedPos);
    return intersectedPos;
  }
}

export default CameraController;

