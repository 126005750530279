/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-underscore-dangle */ // Needed to use rotation json params.
/* eslint-disable no-param-reassign */

import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import CompanyState from '@/singletons/company.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import MapState from '@/singletons/map.state.singleton';
import CompanyService from '@/Services/Company/company.service';
import _, { map } from 'underscore';
import AssetManager from './asset.manager';
import AnimationHelper from './animation.helper';

const tag = 'FLOORPLAN_LOADER - ';

/**
 * Load floorplan and create all MapItems needed.
 */
class FloorplanLoader {
  constructor(selectionManager) {
    this.assetManager = new AssetManager();
    this.selectionManager = selectionManager;
    MapState.floor = null;
    MapState.floorAsset = null;
    MapState.floorPrefab = null;
    MapState.floorplanGroup = null;
    MapState.planElements = {};
    MapState.lights = [];
  }

  static getFloorPlanGroup() {
    return MapState.floorplanGroup;
  }

  setFloor = (floor, callback) => {
    // Set floor
    MapState.floor = floor;

    // Prepare data
    const { zones, assets } = CompanyState;

    // Make sure floor has props
    if (!MapState.floor.FloorProps || !MapState.floor.FloorProps.Aid) {
      console.log(`${tag} Floor does not have props or Aid.`);
      callback();
      return;
    }

    // Save rotation from the last floorplanGroup, otherwise changing to perspective (iso) wont work as expected
    if (MapState.floorplanGroup) {
      const prevRot = MapState.floorplanGroup.rotation.y;
      MapState.floorplanGroup = new THREE.Group();
      MapState.floorplanGroup.rotation.y = prevRot;
    } else {
      MapState.floorplanGroup = new THREE.Group();
    }

    this.assetManager.loadAllAssets(MapState.floor, () => {
      MapState.floorAsset = _.findWhere(assets, { Aid: MapState.floor.FloorProps.Aid });
      if (!MapState.floorAsset) {
        console.log(`${tag} Could not find floor assets.`);
        callback();
        return;
      }
      MapState.floorPrefab = MapState.floorAsset.prefab;

      _.each(MapState.floorAsset.Options.childOptions, (options) => {
        let child = MapState.floorPrefab.getObjectByName(options.child);
        if (!child) {
          child = MapState.floorPrefab.getObjectByName(options.zid);
        }
        if (child) {
          if (options.zid) {
            child.name = parseInt(options.zid, 10);
            MapState.rayCastGroup.push(child);
          }
          if (options.linkTo) {
            child.userData.linkTo = options.linkTo;
            MapState.rayCastGroup.push(child);
          }
        }
      });
      MapState.msg.remove();
      this.applyPosition(MapState.floorPrefab, MapState.floor.FloorProps);
      MapState.floorplanGroup.add(MapState.floorPrefab);

      // Lights
      const lights = [];

      // Global scene properties: light and background
      const light = MapState.floorAsset.Options.light || {};
      light.color = light.color || '#ffffff';
      light.intensity = light.intensity || 1;
      light.castShadow = light.castShadow || false;
      const directionalLight = new THREE.DirectionalLight(
        light.color,
        light.intensity,
      );
      directionalLight.castShadow = light.castShadow;
      if (light.position) {
        directionalLight.position.set(light.position.x, light.position.y, light.position.z);
      } else {
        directionalLight.position.set(-40, 80, 20);
      }

      // Computing camera limits this way is not the best solution
      const bbox = new THREE.Box3().setFromObject(MapState.floorPrefab);
      const { camera } = directionalLight.shadow;
      camera.left = Math.min(bbox.min.x, bbox.min.z);
      camera.bottom = camera.left;
      camera.right = Math.max(bbox.max.x, bbox.max.z);
      camera.top = camera.right;

      const maxDim = Math.max(-camera.left, camera.right);
      let mapSize = 512;
      if (maxDim > 20) {
        mapSize = 2048;
      } else if (maxDim > 10) {
        mapSize = 1024;
      }

      directionalLight.shadow.mapSize.width = mapSize;
      directionalLight.shadow.mapSize.height = mapSize;

      lights.push(directionalLight);

      if (MapState.floorAsset.Options.ambient) {
        const ambient = new THREE.AmbientLight(MapState.floorAsset.Options.ambient);
        lights.push(ambient);
      }
      MapState.lights = lights;

      // Meshes related to the floorplan, for decoration (not zones). Icons.
      // console.log(`${tag} About to put floor assets for decoration.`);
      _.each(MapState.floorAsset.Options.floorAssets, (asset) => {
        this.putAsset(asset, assets);
      });

      // Meshes that are assigned to a zone, like bookable desks.
      // console.log(`${tag} About to put assets for resources / zones (like desks).`);
      _.each(_.where(zones, { ParentZone: MapState.floor.Zid }), (zone) => {
        if (zone.FloorProps && zone.FloorProps.Aid) {
          this.putAsset(zone.FloorProps, assets, zone.Zid, zone.Type.toLowerCase());
        }
      });

      // Add plan elements
      this.addPlanElements(zones);

      // Update states of zones
      this.onZoneStateChanged();

      // scene.add(MapState.floorplanGroup);
      callback();
    });
  }


  // MARK: - Put asset

  putAsset = (prop, assets, zid, type) => {
    const asset = _.findWhere(assets, { Aid: prop.Aid });
    let object;
    if (!asset) {
      object = this.assetManager.getDefaultObject(prop.Type);
      if (!object) { return; }
    } else if (asset.Type === 'group') {
      object = this.createGroup(assets, asset, prop);
    } else {
      object = asset.prefab.clone();
    }

    this.applyPosition(object, prop);

    // Decorator
    if (prop.Type === '3d') {
      object.name = zid;
      object.userData.info = prop.Info;
      object.userData.linkTo = prop.LinkTo;

      const meshes = this.findAllMeshes(object);
      meshes.forEach((mesh) => {
        mesh.material = mesh.material.clone();
        if (zid || prop.Info || prop.LinkTo) {
          MapState.rayCastGroup.push(mesh);
        }
      });

      MapState.floorplanGroup.add(object);

      // annotation/marker
    } else if (prop.Type === '2d') {
      object.userData.info = prop.Info;
      object.userData.linkTo = prop.LinkTo;
      object.name = zid;
      object.userData.clickable = (!!prop.LinkTo);
      if (object.name || object.userData.clickable) {
        MapState.rayCastGroup.push(object);
      }
      MapState.rayCastGroupObjects.push(object);
      MapState.floorplanGroup.add(object);
    } else if (prop.Type === 'group') {
      // Right now it is handled same way as 3d object
      object.userData.info = prop.Info;
      object.userData.linkTo = prop.LinkTo;
      object.name = zid;

      const meshes = this.findAllMeshes(object);
      meshes.forEach((mesh) => {
        mesh.material = mesh.material.clone();
        if (zid || prop.Info || prop.LinkTo || mesh.userData.zid || mesh.userData.info || mesh.userData.linkTo) {
          MapState.rayCastGroup.push(mesh);
        }
      });

      MapState.floorplanGroup.add(object);
    }
  }

  addPlanElements = (zones) => {
    const planElements = {};

    for (let i = 0; i < zones.length; i += 1) {
      const zid = zones[i].Zid;
      let element = MapState.floorplanGroup.getObjectByName(zid, true);
      if (element) {
        if (zones[i].Hidden) {
          if (element instanceof THREE.Mesh && zones[i].Type !== 'FLOOR') {
            element = element.parent;
          }
          element.parent.remove(element);
        } else {
          planElements[zid] = element;
          element.userData.zone = zones[i];
        }
      }
    }

    MapState.planElements = planElements;
  };


  // MARK: - Generate grid of points for placements

  getPointsInGrid = (position, count, size, margin) => {
    const col = Math.ceil(Math.sqrt(count));
    const rows = col;
    const res = [];

    const _position = position || new THREE.Vector3(0, 0, 0);
    const _size = size || 1;
    const _margin = margin || 0.5;

    const offset = -((col * (_size) + (col - 1) * _margin) * 0.5) + (_size / 2);
    let placedCount = 0;

    for (let y = (rows - 1); y >= 0; y -= 1) {
      for (let x = 0; x < col; x += 1) {
        if (placedCount < count) {
          placedCount += 1;

          const posx = x * (_size + _margin) + offset + _position.x;
          const posy = y * (_size + _margin) + offset + _position.y;

          res.push(new THREE.Vector3(posx, posy, _position.z));
        }
      }
    }
    return res;
  }


  // MARK: - Sensors

  clearSensors = () => {
    this.selectionManager.clearSelection();

    for (let i = 0; i < MapState.sensorObjs.length; i += 1) {
      const circleobj = MapState.sensorObjs[i];

      // Remove from raycast group
      const raycastGroupIndex = MapState.rayCastGroupObjects.indexOf(circleobj);
      if (raycastGroupIndex > -1) {
        MapState.rayCastGroupObjects.splice(raycastGroupIndex, 1);
      }

      // Remove from floorplan group
      MapState.floorplanGroup.remove(circleobj);
      MapState.scene.remove(circleobj);

      // Dispose
      circleobj.children.forEach((obj) => { obj.geometry.dispose(); });
      circleobj.children.forEach((obj) => { obj.material.dispose(); });
    }
    MapState.sensorObjs = [];
  }

  placeSensorIndicators = (sensors) => {
    // sensors = [{ id: String, zid: Number } ...].

    this.clearSensors();
    const sensorGroupsByZone = {};
    const { zones } = CompanyState;

    // Group sensors by zone
    _.each(sensors, (sensor) => {
      const zone = zones.find((it) => it.Zid === sensor.zid);
      if (zone && zone.ParentZone === MapState.floor.Zid) {
        const group = sensorGroupsByZone[zone.Zid];
        if (!group) {
          sensorGroupsByZone[zone.Zid] = [sensor];
        } else {
          sensorGroupsByZone[zone.Zid].push(sensor);
        }
      }
    });

    // Create points & place sensors
    _.each(sensorGroupsByZone, (sensorGroup, key) => {
      // Zone position
      const zonePosition = this.getZonePosition({ Zid: key }, true);

      // Get points relative to zero position
      const points = this.getPointsInGrid(null, sensorGroup.length, 0.7, 0);

      // Create empty 3d object & add to group
      const group = new THREE.Group();
      for (let i = 0; i < points.length; i += 1) {
        const point = points[i];
        point.object = new THREE.Object3D();
        point.object.position.set(point.x, point.y, point.z);
        group.attach(point.object);
      }


      // Place group at zone & rotate along floor plane
      group.position.set(zonePosition.x, zonePosition.y + 1.5, zonePosition.z);
      group.rotation.x = THREE.MathUtils.degToRad(90);

      // Update point positions based on new world pos
      for (let i = 0; i < points.length; i += 1) {
        const point = points[i];
        point.object.getWorldPosition(point);
        point.object = null;
        points[i] = point;
      }

      // Clear group.
      group.clear();

      // Draw icons for each point
      for (let i = 0; i < points.length; i += 1) {
        const point = points[i];
        const sensor = sensorGroup[i];
        const sensorId = sensor.id;

        this.drawCircle(sensor.color, point.x, point.y, point.z, (circle) => {
          MapState.sensorObjs.push(circle);
          circle.userData.sensorId = sensorId;
        });
      }
    });
    window.dispatchEvent(new Event('map_render'));
  }


  // MARK: - Update when zones changes

  onZoneStateChanged = () => {
    const states = ZoneState.zoneStates;
    const floor = CompanyService.getMapFloor(MapState.floor?.Zid);

    if (!floor) {
      return;
    }

    _.each(floor.zones, (zone) => {
      const el = MapState.planElements[zone.Zid];
      const state = states[zone.Zid];

      if (el === undefined) {
        return;
      }

      this.findAllMeshes(el, true).forEach((mesh) => {
        if (!mesh.material) {
          return;
        }
        const color = this.getColorForZoneMapColor(state?.MapColor);
        if (color) {
          if (state?.Highlighted) {
            mesh.material.color = color; // new THREE.Color(color).multiplyScalar(0.7);
          } else {
            mesh.material.color = MapState.colors.colorResourceNotInFilter;// color; // new THREE.Color(color).multiplyScalar(1.4);
          }
        }
      });
    });
  }

  getColorForZoneMapColor = (zoneMapColor) => {
    if (zoneMapColor === ZoneState.ZONE_MAP_COLOR.OTHER) {
      return MapState.colors.colorResourceOther;
    }
    if (zoneMapColor === ZoneState.ZONE_MAP_COLOR.NOT_IN_FILTER) {
      return MapState.colors.colorResourceNotInFilter;
    }
    if (zoneMapColor === ZoneState.ZONE_MAP_COLOR.GREEN) {
      return MapState.colors.colorResourceFree;
    }
    if (zoneMapColor === ZoneState.ZONE_MAP_COLOR.YELLOW) {
      return MapState.colors.colorResourceAway;
    }
    if (zoneMapColor === ZoneState.ZONE_MAP_COLOR.RED) {
      return MapState.colors.colorResourceOccupied;
    }
    if (zoneMapColor === ZoneState.ZONE_MAP_COLOR.BLUE) {
      return MapState.colors.colorResourceSpace;
    }
    return MapState.colors.colorResourceNotInFilter;
  }


  // MARK: - Convinience

  applyPosition = (object, instance) => {
    if (instance.Position) {
      object.position.set(
        instance.Position.x,
        instance.Position.y,
        instance.Position.z,
      );
    }
    if (instance.Rotation) {
      object.rotation.set(
        instance.Rotation._x,
        instance.Rotation._y,
        instance.Rotation._z,
      );
    }
    if (instance.Scale) {
      object.scale.set(instance.Scale.x, instance.Scale.y, instance.Scale.z);
    }
  }

  createGroup = (assets, asset, instance) => {
    const group = new THREE.Group();
    group.name = 'Group';
    group.userData.type = 'group';
    const children = instance.Children || {};

    asset.Options.childs.forEach((as) => {
      const childAsset = _.findWhere(assets, { Aid: as.Aid });
      if (childAsset) {
        const obj = childAsset.prefab.clone();
        obj.userData.type = childAsset.Type;
        obj.userData.info = '';
        Object.assign(obj.userData, children[as.Child]);
        obj.name = obj.userData.zid || childAsset.Name;
        this.applyPosition(obj, as);
        group.add(obj);
      }
    });

    return group;
  }

  findAllMeshes = (object, limit) => {
    const meshes = [];
    if (object.type === 'Object3D') {
      object.children.forEach((child) => {
        meshes.push(child);
      });
      return meshes;
    }
    if (object.type !== 'Group') {
      meshes.push(object);
      return meshes;
    }
    object.children.forEach((child) => {
      if (child.type === 'Group') {
        if (!limit || !child.userData.zid) {
          child.children.forEach((childOfChild) => {
            Object.assign(childOfChild.userData, child.userData);
            meshes.push(childOfChild);
          });
        }
      } else {
        meshes.push(child);
      }
    });
    return meshes;
  }

  getZonePosition = (zone, disregardClickPosition) => {
    if (!zone) {
      return new THREE.Vector3(-1, -1, -1);
    }
    let vector;
    const _disregardClickPosition = disregardClickPosition || false;

    if (!_disregardClickPosition && zone.clickPosition) {
      vector = zone.clickPosition.clone();
    } else {
      let element;

      if (zone.Zid) {
        element = MapState.planElements[zone.Zid];
      } else if (zone.selected) {
        element = zone.selected;
      }

      if (!element) {
        return new THREE.Vector3(-1, -1, -1);
      }

      if (element.geometry) {
        element.geometry.computeBoundingBox();
        element.updateMatrixWorld();
        const { boundingBox } = element.geometry;
        vector = new THREE.Vector3();
        vector.subVectors(boundingBox.max, boundingBox.min);
        vector.multiplyScalar(0.5);
        vector.add(boundingBox.min);
        vector.applyMatrix4(element.matrixWorld);
      } else if (element.type === 'Sprite') {
        MapState.floorplanGroup.updateMatrixWorld();
        vector = new THREE.Vector3(element.position.x, element.position.y, element.position.z);
        vector.applyMatrix4(MapState.floorplanGroup.matrixWorld);
      } else {
        vector = element.position.clone();
        vector.applyMatrix4(MapState.floorplanGroup.matrixWorld);
      }
    }
    return vector;
  }

  drawCircle = (color, x, y, z, callback) => {
    const circleObj = new THREE.Object3D();

    const circleMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(color), side: THREE.DoubleSide, transparent: true });

    const borderMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.BackSide, transparent: true });

    const radius = 0.45;
    const segments = 32;
    const circleGeometry = new THREE.SphereGeometry(radius, segments, segments);
    const borderGeometry = new THREE.SphereGeometry(radius * 1.2, segments, segments);

    const circle = new THREE.Mesh(circleGeometry, circleMaterial);
    const border = new THREE.Mesh(borderGeometry, borderMaterial);

    circleObj.add(circle);
    circleObj.add(border);

    circleObj.position.set(x, y + 1.5, z);
    circleObj.userData.clickable = true;

    MapState.rayCastGroupObjects.push(circleObj);
    MapState.floorplanGroup.attach(circleObj);

    if (callback) {
      callback(circleObj);
    }
  }

  animateSensorSprite = (did, color) => {
    _.each(MapState.rayCastGroupObjects, (circleobj) => {
      if (circleobj.userData.sensorId === did) {
        /** Only allow one animation per sensor at a time. */
        if (circleobj.isAnimating) {
          return;
        }

        circleobj.isAnimating = true;
        const { position } = circleobj;
        const targetBounce = {
          ...position,
          y: position.y + 2.3,
        };

        /** Set new color & border color white under animation */
        circleobj.children[0].material.color = new THREE.Color(color); // Circle
        AnimationHelper.pulseAnimation(circleobj.children[1]);

        /** Animate bounce */
        const bounce = AnimationHelper.bounceAnimation(circleobj.position, targetBounce, {
          duration: 1000,
          easing: TWEEN.Easing.Circular.Out,
          update: () => {
            window.dispatchEvent(new Event('map_render'));
          },
          callback: () => {
          },
        });

        /** Animate deBounce */
        const deBounce = AnimationHelper.bounceAnimation(circleobj.position, position, {
          duration: 500,
          easing: TWEEN.Easing.Circular.In,
          update: () => {
            window.dispatchEvent(new Event('map_render'));
          },
          callback: () => {
            circleobj.isAnimating = false;
            /* border color black again */
            circleobj.children[1].material.color = new THREE.Color(0x000000);
            circleobj.children[1].material.opacity = 1;

            window.dispatchEvent(new Event('map_render'));
          },
        });
        /** Start animation - chain debounce to start after bounce is finished. */
        bounce.chain(deBounce).start();
      }
    });
  }
}

export default FloorplanLoader;

