import ApplicationState from '@/singletons/application.state.singleton';
import PhoneService from '@/Services/Phone/phone.service';

const requestScope = { scopes: ['user.read'] };

const shouldIncludeAzureBearerToken = (url = '') => {
  const authMethod = localStorage.getItem('auth_method') === 'azure';
  const isCallback = url.includes('azure');

  if (authMethod || isCallback) return true;

  return false;
};

/** Electron will always fetch cached token */
const getCachedBearerToken = () => localStorage.getItem('auth_token_msal');

/** If silent requests fails we failover to redirect */
const aquireTokenRedirect = () => {
  window.msal.acquireTokenRedirect(requestScope);
};

const requestAzureBeareToken = async () => {
  const isElectron = ApplicationState.isElectron();
  const cachedToken = getCachedBearerToken();
  const isPhone = PhoneService.isInPhoneApp();

  if (isElectron || isPhone) return cachedToken;

  try {
    const response = await window.msal.acquireTokenSilent(requestScope);

    /** We need the raw idToken for authentication against Azure */
    const { idToken: { rawIdToken } } = response;

    return rawIdToken;
  } catch (e) {
    return aquireTokenRedirect();
  }
};

export {
  shouldIncludeAzureBearerToken,
  requestAzureBeareToken,
};
