class BookingState {
  constructor() {
    this.bookings = [];
  }

  destroy() {
    this.bookings = [];
  }
}

export default new BookingState();
