import apiClient from './api.interceptor';

const baseUrl = process.env.VUE_APP_BASE_URL;
const guestHash = 'w7XDFfKbH355gOEyYd26n5HtVzYr5dKaQh88tdZ7quZAGcsDzL2M0lDjFP4CzrmI';

// If logged out pretend to be a sensor
export const presenceGuest = (id) => apiClient.post(`${baseUrl}/event/guestevent`, {}, { withCredentials: true, headers: { 'idesk-desk-id': id, 'idesk-auth-password': guestHash, 'idesk-auth-method': 'hash' } });

export const presence = (id, data) => apiClient.post(`${baseUrl}/event/event`, data, { withCredentials: true });
export const left = (id, data) => apiClient.post(`${baseUrl}/event/event`, data, { withCredentials: true });
export const mode = (id, data) => apiClient.post(`${baseUrl}/event/event`, data, { withCredentials: true });
