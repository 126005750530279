<template>
  <div class="fullscreen-container">
    <Topbar />
    <router-view name="fullscreen" />
  </div>
</template>

<script>
/* Components */
import Topbar from './components/topbar.vue';

export default {
  components: {
    Topbar,
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-container{
  width: 100%;
  overflow: hidden;
  .scrollable{
    height: calc(100vh - 5rem);
    overflow-y: overlay;
    overflow-x: hidden;
    &.percentage{
      height: calc(100% - 5rem);
    }
  }
}
</style>
