import apiClient from './api.interceptor';

const baseUrlProxy = process.env.VUE_APP_BASE_URL;

/* GET */
export const getDevices = (params) => apiClient.get(`${baseUrlProxy}/event/devices`, { params });
export const getDevicesCount = (params) => apiClient.get(`${baseUrlProxy}/event/devices/count`, { params });
export const getSensors = (cid) => apiClient.get(`${baseUrlProxy}/core/devices?parent=${cid}&status=ACTIVE&includeDeviceState=true`);
export const getSensorById = (sensorId) => apiClient.get(`${baseUrlProxy}/core/device/${encodeURIComponent(sensorId)}`);
// ExternalIp,LocalIp,SessUsername,SessCreated,SessLastActivity,RSSI,BSSID,MAC,SSID,tcpFail,conTime,wifiFail,cnt,battery,VUSB,VBat,fw,hw,firmware,hardware,wifi fw
export const getSensorsRemote = () => apiClient.get(`${baseUrlProxy}/remote/head?meta=all`);
export const getSensorRemoteById = (sensorId) => apiClient.get(`${baseUrlProxy}/remote/head/${encodeURIComponent(sensorId)}`);

/* DELETE */
export const forceCloseSensorSession = (sensorId) => apiClient.delete(`${baseUrlProxy}/session/${sensorId}/close`);
