<template>
  <div />
</template>

<script>

import SettingsNavigatorState from '@/views/settings/navigator/settings.navigator.state';

export const USER_IDLE = 'userIdle';
export const USER_ACTIVE = 'userActive';
export const SET_USER_IDLE = 'setUserIdle';
export const SET_USER_ACTIVE = 'setUserActive';

export default {
  name: 'UserIdleVue',
  props: {
    duration: {
      type: Number,
      default: 1000 * 60 * 5,
    },
  },
  data() {
    return {
      events: ['mousedown', 'keypress', 'click', 'wheel', 'touchstart', 'touchend', 'touchmove'],
      start: null,
      counter: null,
    };
  },
  watch: {
    $route(to) {
      if (!to.params.fromIdle) {
        // Avoid handling route change if the route was made from going idle
        this.clearTimer();
      }
    },
  },
  mounted() {
    SettingsNavigatorState.idleDuration = this.duration;
    this.events.forEach((event) => window.addEventListener(event, this.clearTimer));
    this.clearTimer();
    this.counter = setInterval(this.checkIdleState, 1000);
    this.$root.$on(SET_USER_IDLE, () => { this.onIdle(); });
    this.$root.$on(SET_USER_ACTIVE, () => { this.onActive(); });
  },
  beforeDestroy() {
    clearInterval(this.counter);
    this.events.forEach((event) => window.removeEventListener(event, this.clearTimer));
  },
  methods: {
    checkIdleState() {
      SettingsNavigatorState.idleTimeCounter = Date.now() - this.start;
      if (!SettingsNavigatorState.idle && (this.start + this.duration) < Date.now()) {
        this.onIdle();
      }
    },
    clearTimer() {
      this.start = Date.now();
      if (SettingsNavigatorState.idle) {
        this.onActive();
      }
    },
    onIdle() {
      SettingsNavigatorState.idle = true;
      this.$emit(USER_IDLE);
    },
    onActive() {
      SettingsNavigatorState.idle = false;
      this.$emit(USER_ACTIVE);
    },
  },
};
</script>

<style lang="scss">
</style>
