/* eslint-disable no-unused-vars */
import ZoneStateService from '@/Services/Zones/zone.state.service';
import { getHasFilters, getAmountFilters } from '@/functions/filter.query.functions';
import ExtendedDate from '../../classes/extended.date.class';

class RouterService {
  static setZoneStateFromRoute(route) {
    const { params } = route;
    const { query } = route;
    const { type, zid } = params;
    const {
      type: qType, zid: qZid, has, amount, date,
    } = query;
    let { from, until } = query;
    const rType = type || qType;
    const hasFilters = getHasFilters(has);
    const amountFilters = getAmountFilters(amount);

    // If true then ignore resource filter
    const { ignoreType } = query;

    from = (from && from !== until) ? new ExtendedDate(date).setHours(0, 0, 0, 0) + Number(from) : null;
    until = (from && until && from !== until) ? new ExtendedDate(date).setHours(0, 0, 0, 0) + Number(until) : null;
    ZoneStateService.setState(rType, Number(zid), date, hasFilters, amountFilters, from, until, Number(qZid), (ignoreType === 'true'));
  }
}

export default RouterService;
