import ExtendedDate from '@/classes/extended.date.class';
import WeekState from '@/singletons/week.state.singleton';

const QueryMixin = {
  data() {
    return {
      date: this.getInitialDate(),
      currentPath: this.$route.path,
      query: this.$route.query,
    };
  },

  beforeMount() {
    const issueTrackerPath = this.$route.name === 'admin-issue-tracker';
    const allowPastDate = issueTrackerPath;
    this.checkDate(allowPastDate);
  },

  methods: {
    getInitialDate() {
      return this.$route.query.date || WeekState.date?.getDateString() || new ExtendedDate().getDateString();
    },

    checkDate(allowPastDate = false) {
      if (this.$route.query.date) {
        const today = new ExtendedDate();
        const queryDate = ExtendedDate.createDateFromQuery(this.$route.query.date);
        if (Number.isNaN(queryDate.getTime())) {
          WeekState.setDate(today);
          this.updateQuery({ date: WeekState.date.getDateString() });
        } else if (!allowPastDate && queryDate.isBefore(today) && !queryDate.isSameDate(today)) {
          WeekState.setDate(today);
          this.updateQuery({ date: WeekState.date.getDateString() });
        } else if (allowPastDate && queryDate.isAfter(today) && !queryDate.isSameDate(today)) {
          WeekState.setDate(today);
          this.updateQuery({ date: WeekState.date.getDateString() });
        } else {
          WeekState.setDate(queryDate);
        }
      }
    },

    /** URI Manipulation */

    /* Pushes history */
    updateQuery(query) {
      this.encodeQuery(query);
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, ...query } }).catch(() => {});
    },

    /* Does not push history */
    replaceQuery(query) {
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, ...query } }).catch(() => {});
    },

    removeQuery(key) {
      // Make a clean copy of the current query
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      delete queries[key];
      // console.log(queries);
      this.$router.replace({ path: this.$route.path, query: queries }).catch(() => {});
    },

    clearQueryies() {
      this.$router.replace({ path: this.$route.path, query: {} }).catch(() => {});
    },

    /** Always encode values as URI safe */
    encodeQuery(query) {
      Object.keys(query).forEach((key) => encodeURIComponent(query[key]));
    },

    parseQueryArray(commaSeperateArray) {
      if (!commaSeperateArray) return [];
      return commaSeperateArray.split(';');
    },
  },

};

export default QueryMixin;
