class SVGCache {
  constructor() {
    this.icons = {};
  }

  async get(src) {
    const hasIcon = src in this.icons;
    if (!hasIcon) this.icons[src] = { inTransit: false };
    if (this.icons[src].inTransit) return this.icons[src].icon;

    this.icons[src].inTransit = true;
    this.icons[src].icon = new Promise((resolve) => {
      fetch(src).then((r) => {
        const icon = r.text();
        this.icons[src].icon = icon;
        resolve(icon);
      });
    });

    return this.icons[src].icon;
  }
}

export default new SVGCache();
