<template>
  <div class="topbar-container">
    <!-- Search field here -->
    <SocketConnectionState v-if="UserService.hasAllRoles(['role_dev'])" />
    <ControllerTopbarItem v-if="ApplicationState.platform === 'electron'" />
    <div
      v-if="UserState.user.AbstractUser"
      class="date-time-top-bar"
      :class="[hideBrandDateTime ? 'fade-in' : 'fade-out']"
    >
      <DateTimeKeeper />
    </div>
    <LocationSidebarItem />
  </div>
</template>

<script>

import ApplicationState from '@/singletons/application.state.singleton';
import MapState from '@/singletons/map.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import DateTimeKeeper from '@/views/map/components/datetime.keeper.vue';
import UserService from '@/Services/User/user.service';
import SettingsNavigatorState from '@/views/settings/navigator/settings.navigator.state';
import LocationSidebarItem from './location.sidebar.item.vue';
import SocketConnectionState from './socket.connection.state.vue';
import ControllerTopbarItem from './controller.topbar.item.vue';

export default {
  components: {
    LocationSidebarItem,
    ControllerTopbarItem,
    DateTimeKeeper,
    SocketConnectionState,
  },
  data() {
    return {
      ApplicationState,
      MapState,
      hideBrandDateTime: false,
      UserService,
      UserState,
      SettingsNavigatorState,
    };
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'MapState.camera.zoom'() {
      this.hideBrandDateTime = MapState.camera?.zoom > 5;
    },
  },
};
</script>

<style lang="scss">
$item-height: 80px;
.topbar-container{
  display: flex;
  justify-content: right;
  gap: 2rem;
  background-color: var(--primary-color);
  height: $item-height;
  width: 100%;
  padding: 0.5rem 2rem;
  *{
    margin:auto 0;
  }
}

.date-time-top-bar {
  color: white;

  &.fade-in {
    visibility: visible;
    opacity: 1;
    transition: 0.5s opacity 0.5s ease-in-out;
  }

  &.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s ease-in-out;
  }

  h6 {
    font-weight: 300;
    color: white;

    &.time {
      font-size: 1.25rem;
    }

    &.date {
      font-size: 0.8125rem;
    }
  }
}

</style>
