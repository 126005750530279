<template>
  <div class="resource__container">
    <div
      class="resource__container"
      @click="$emit('contentClicked')"
    >
      <RoundResourceIcon
        :type="`resource ${resourceState}`"
        :src="src"
      />
      <!-- Name / Identifier -->
      <div class="resource__content overflow-hidden">
        <h6 class="elipsis">
          {{ name }}
        </h6>
        <div class="resource__content--secondary overflow-hidden">
          <p
            v-if="secondaryContent.text"
            class="elipsis"
          >
            {{ secondaryContent.text }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="!hideAction"
      class="end-icons padding padding-small padding-top"
      @click="emitResourceAction"
    >
      <svg-icon
        class="end-icon"
        src="/icons/map_navigation_pin.svg"
      />
    </div>
  </div>
</template>

<script>
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';

export default {
  components: {
    RoundResourceIcon,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['src', 'name', 'secondaryContent', 'hideAction', 'status'],
  computed: {
    resourceState() {
      switch (this.status) {
        case 'Occupied': return 'occupied';
        case 'Booked': return 'away';
        default: return 'available';
      }
    },
  },
  methods: {
    emitResourceAction() {
      this.$emit('onResourceAction');
    },
  },
};
</script>
<style lang="scss" scoped>
  .end-icon {
    cursor: pointer;
    fill: var(--primary-color);
    svg {
      margin-right: 0;
    }
  }
  .resource__content {
    flex: 1;
  }
</style>
