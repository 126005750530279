/* eslint-disable prefer-destructuring */
const { DateTime } = require('luxon');

export const groupBy = (keys) => (array) => array.reduce((objectsByKeyValue, obj) => {
  const value = keys.map((key) => obj[key]).join('-');
  // eslint-disable-next-line no-param-reassign
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
}, {});

export const sortObj = (obj) => Object.keys(obj).sort().reduce((result, key) => {
  // eslint-disable-next-line no-param-reassign
  result[key] = obj[key];
  return result;
}, {});

// eslint-disable-next-line arrow-body-style
export const removeDuplicateValueInObjectArray = (arr, key) => {
  return arr.filter((value, index, self) => index === self.findIndex((t) => (t[key] === value[key])));
};

export const promiseWhen = (condition, timeout = 2000) => new Promise((resolve, reject) => {
  // eslint-disable-next-line consistent-return
  const loop = () => {
    console.log('CONDITION: ', condition());
    if (condition()) {
      return resolve();
    }
    setTimeout(loop, timeout);
  };
  const looper = setTimeout(loop, 10);
  setTimeout(() => {
    clearTimeout(looper);
    return reject();
  }, timeout);
});

export const getFromLocalStorage = (key) => JSON.parse(localStorage.getItem(key));

export const removeFromLocalStorage = (key) => localStorage.removeItem(key);

export const saveToLocalStorage = (key, obj, append = true) => {
  const item = append && typeof obj === 'object' ? { ...getFromLocalStorage(key), ...obj } : obj;
  localStorage.setItem(key, JSON.stringify(item));
  // Break references
  return getFromLocalStorage(key);
};

export const clearLocalStorage = (exceptionKeys) => {
  if (!exceptionKeys) {
    localStorage.clear();
  } else {
    const exceptions = {};
    exceptionKeys.forEach((key) => {
      exceptions[key] = getFromLocalStorage(key);
    });

    localStorage.clear();

    Object.entries(exceptions).forEach(([key, value]) => {
      saveToLocalStorage(key, value, false);
    });
  }
};

export const calculateWorkingHours = (year, month) => {
  const y = Number(year);
  const m = Number(month);
  const daysInMonth = DateTime.local(y, m).daysInMonth;
  let totalWorkingHours = 0;
  for (let d = 1; d <= daysInMonth; d += 1) {
    const date = DateTime.local(y, m, d);
    if (date.weekday < 6) { // Weekdays are 1 (Monday) through 5 (Friday)
      totalWorkingHours += 8; // Add 8 hours for each weekday
      console.log(daysInMonth, totalWorkingHours, date);
    }
  }

  return totalWorkingHours;
};

export const sumArr = (acc, next) => acc + next;

export const randomInt = (max) => Math.floor(Math.random() * max);

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const msToHrs = (ms) => ms / (1000 * 60 * 60);

export const toFixedNumber = (floatVal, fractionDigits) => Number(floatVal.toFixed(fractionDigits));

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);
