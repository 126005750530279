class CompanyState {
  constructor() {
    this.cid = null;
    this.realm = null;
    this.companiesInRealm = [];
    this.companyTree = null;
    this.floors = new Set();
    this.userStatusTypes = [];
    this.zoneTypes = [];
    this.zonesOnFloor = [];
    this.zones = [];
    this.assets = [];
    this.spacesInRealm = [];
    this.timeslotsInRealm = [];
    this.fallbackRouteName = 'splash';
    this.branding = null;
    this.hasLoadedOnce = false;
  }

  /*
    NOTE: Should not be used by anyone excpet CompanyService.
  */
  init(company) {
    const {
      Cid,
      Branding,
      Realm,
    } = company[0];
    this.cid = Cid;
    this.branding = Branding;
    this.realm = Realm;
    this.fallbackRouteName = 'calendar';
    // this.hasLoadedOnce = true;
  }

  destroy() {
    this.cid = null;
    this.realm = null;
    this.companyTree = null;
    this.companiesInRealm = [];
    this.floors = new Set();
    this.userStatusTypes = [];
    this.zoneTypes = [];
    this.zones = [];
    this.assets = [];
    this.branding = null;
    this.hasLoadedOnce = false;
    this.fallbackRouteName = 'splash';
    this.placeholder = [
      'app:placeholder:one',
      'app:placeholder:two',
      'app:placeholder:three',
      'app:placeholder:four',
    ];
  }
}

export default new CompanyState();
