/* eslint-disable no-multi-spaces */
import Vue from 'vue';
import VueRouter from 'vue-router';
import PhoneService from '@/Services/Phone/phone.service';
import AuthService from '../Services/Auth/auth.service';
import {
  isLoggedIn, shouldShowSplashScreen, isRootPath,
} from './guard.before';
import ApplicationState from '../singletons/application.state.singleton';
/** Services */

/** Routes */
import routes, {
  ROUTE_CALENDAR, ROUTE_BOOK, ROUTE_MAP, ROUTE_SETTINGS,
} from './routes';

/** Models */

Vue.use(VueRouter);
const router = new VueRouter({
  routes,
  mode: process.env.VUE_APP_PLATFORM === 'web' ? 'history' : 'hash',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

/** Phone router */
PhoneService.broadcast.$on(PhoneService.events.phone_module_change, (evt) => {
  const { module, customPath } = evt.data;
  let toPath = '';

  switch (module) {
    case PhoneService.modules.CALENDAR: toPath = ROUTE_CALENDAR;  break;
    case PhoneService.modules.BOOK:     toPath = ROUTE_BOOK;      break;
    case PhoneService.modules.MAP:      toPath = ROUTE_MAP;       break;
    case PhoneService.modules.SETTINGS: toPath = ROUTE_SETTINGS;  break;
    case 'CUSTOM':                      toPath = customPath;      break;
    default: PhoneService.reportUnhandled(PhoneService.events.phone_module_change, `No route for module: ${module}`); return;
  }

  // Preserve zid when changing module to map
  if (module === PhoneService.modules.MAP && router.currentRoute.params.zid) {
    toPath += `?zid=${router.currentRoute.params.zid}`;
  }

  // Push navigation
  router.push(toPath);
});

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, _, next) => {
  if (ApplicationState.appState < 1 && to.path.includes('standalone')) {
    return next();
  }

  shouldShowSplashScreen(to, _, next);
  isRootPath(to, _, next);

  if (ApplicationState.appState < 1) {
    await AuthService.verifySession();
  }

  isLoggedIn(to, _, next);
  next();
});

router.afterEach((to, from) => {
  PhoneService.send(PhoneService.events.phone_module_state, {
    fromPath: from.path,
    fromModule: from.meta?.module,
    toPath: to.path,
    toModule: to.meta?.module,
  });
});

export default router;
