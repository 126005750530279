<template>
  <div
    class="circle-progress"
    :style="{ width: size + 'px', height: size + 'px' }"
  >
    <slot />
    <svg
      :class="boxShadow ? 'box-shadow': null"
      :width="size"
      :height="size"
      :viewPort="'0 0 ' + size + ' ' + size"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        v-if="showBackdrop"
        class="ring"
        :stroke="ringColor"
        :r="r"
        :cx="size / 2"
        :cy="size / 2"
        :stroke-width="strokeWidth"
        fill="none"
      />
      <circle
        class="progress_circle"
        :class="pulsate ? 'pulsate': null"
        :stroke="progressColor"
        :r="r"
        :cx="size / 2"
        :cy="size / 2"
        :stroke-width="strokeWidth"
        fill="none"
        :stroke-dasharray="dasharray"
        :stroke-dashoffset="dashoffset"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    showBackdrop: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 36,
    },
    percentage: {
      type: Number,
      default: 0,
      validator: (val) => val <= 100,
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
    ringColor: {
      type: String,
      default: '#f0f0f0',
    },
    progressColor: {
      type: String,
      default: '#159648',
    },
    boxShadow: {
      type: Boolean,
      default: false,
    },
    pulsate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    r() {
      return this.size / 2 - this.strokeWidth / 2;
    },
    dasharray() {
      return 2 * Math.PI * this.r;
    },
    dashoffset() {
      return this.dasharray * (1 - this.percentage / 100);
    },
    progressNumber() {
      return this.showNumber && parseInt(this.percentage, 10);
    },
  },
};
</script>

<style lang="scss" scoped>

.circle-progress {
  position: relative;

  /** Positioning of inner objects (slots) */
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: rotate(-90deg);
    position: absolute;
    pointer-events: none;
  }

  .progress_circle {
    stroke-linecap: round;
    transition: stroke-dashoffset 0.25s linear;
  }
}

.box-shadow {
  -webkit-filter: drop-shadow(2px 4px 6px #f0f0f0);
  filter: drop-shadow(2px 4px 6px #cccccc);
}

.circle-percentage {
  font-size: 75%;
}

.pulsate {
  stroke: rgb(123, 0, 0);
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes color {
  0% {
    stroke: rgb(123, 0, 0);
  }
  50% {
    stroke: #C90C50;
  }
  100% {
    stroke: rgb(123, 0, 0);
  }
}
</style>
