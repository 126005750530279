<template>
  <span class="icon" />
</template>

<script>
import SvgCache from './svg.cache';

const cache = new Map();

export default {
  name: 'SvgIcon',
  props: {
    src: {
      type: String,
      required: true,
    },
    cache,
  },
  watch: {
    async src() {
      const res = await SvgCache.get(this.src);
      this.$el.innerHTML = res;
    },
  },
  async mounted() {
    const res = await SvgCache.get(this.src);
    this.$el.innerHTML = res;
  },
};
</script>
