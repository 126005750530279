<template>
  <figure :aria="aria">
    <img
      :src="logoPath"
      :height="height"
      :width="width"
    >
  </figure>
</template>

<script>
/* eslint-disable global-require */
export default {
  /** Props */
  props: {
    logoType: {
      type: String,
      required: false,
      default: 'primary',
    },
    aria: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '100px',
    },
    width: {
      type: String,
      required: false,
      default: '100px',
    },
  },
  computed: {
    logoPath() {
      switch (this.logoType) {
        case 'secondary':
          return require('../../assets/logos/secondary_logo.svg');
        case 'splash':
          return require('../../assets/logos/splash_logo.svg');
        case 'primary':
        default:
          return require('../../assets/logos/logo.svg');
      }
    },
  },
};
</script>
