/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-useless-constructor */

import * as THREE from 'three';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import TWEEN from '@tweenjs/tween.js';
import MapState from '@/singletons/map.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import ZoneStateService from '@/Services/Zones/zone.state.service';
import PhoneService from '@/Services/Phone/phone.service';
import UserState from '@/singletons/user.state.singleton';
import CameraController from './camera.controller';
import SelectionManager from './selection.manager';
import FloorplanLoader from './floorplan.loader';
import AnimationHelper from './animation.helper';

/**
 * Entry point for the map.
 */
class MapController {
  //
  // MARK: - Initialization

  constructor() {
    this.hasInitialized = false;
  }

  /**
   * -
   * @param {*} canvas canvas dom element.
   * @param {*} mode (default 'MapState.MODE_TYPE.book') 'book' or 'sensor' modes are supported.
  */
  init({
    canvas = null, mode = MapState.MODE_TYPE.book,
  }) {
    //
    // Set data filtering when in sensor mode
    if (mode === MapState.MODE_TYPE.sensor) {
      ZoneState.hasActiveEquipmentFilter = true;
      ZoneState.filterOnSensors = true;
      ZoneStateService.invalidateZoneAvailabilityStates();
    } else {
      ZoneState.hasActiveEquipmentFilter = false;
      ZoneState.filterOnSensors = false;
      ZoneStateService.invalidateZoneAvailabilityStates();
    }

    // console.log('Initializing map controller...');

    // Scene
    MapState.mode = mode;
    MapState.scene = new THREE.Scene();
    MapState.renderer = null;
    MapState.colors = this.getThemeStyleColors();
    MapState.clock = new THREE.Clock();

    // Dom element references
    MapState.targetCanvas = canvas;

    // Subcomponents
    this.cameraController = new CameraController();
    this.selectionManager = new SelectionManager(this.getScreenCoordinates, this.cameraController);
    this.floorplanLoader = new FloorplanLoader(this.selectionManager);

    // Loop active
    this.active = true;
    this.hasInitialized = true;

    // Render state
    this.hasRenderQueued = true;

    // Setup
    this.setup();
    this.render();
  }

  setup = () => {
    // Setup renderer
    this.setupRenderer();

    // Set background
    MapState.scene.background = new THREE.Color(MapState.colors.colorSceneBackground);

    // Markers
    MapState.navigationMarkerTexture = new THREE.TextureLoader().load('/icons/map-icons/navigation_marker.svg');
    MapState.navigationMarkerMaterial = new THREE.SpriteMaterial({ map: MapState.navigationMarkerTexture, visible: false, depthTest: false });
    MapState.navigationMarkerSprite = new THREE.Sprite(MapState.navigationMarkerMaterial);
    MapState.navigationMarkerSprite.renderOrder = 999;
    MapState.navigatorPopupObject = new CSS2DObject();
    MapState.scene.add(MapState.navigatorPopupObject);

    // Clear existing
    this.clear();
    this.active = true;
    this.hasInitialized = true;

    // Resize event
    window.addEventListener('resize', this.handleWindowResize, false);

    // Resize event upon inactivity
    window.addEventListener('idle', this.handleWindowResize, false);

    // Render events
    window.addEventListener('map_render', this.queueRender, false);

    // Mouse events
    MapState.renderer.domElement.addEventListener('pointerdown', this.handleMouseDown, false);
    MapState.renderer.domElement.addEventListener('pointerup', this.handleMouseUp, false);
    MapState.renderer.domElement.addEventListener('wheel', this.handleMouseWheel, false);
    MapState.renderer.domElement.addEventListener('pointermove', this.handleMouseMove);

    // Touch events
    MapState.renderer.domElement.addEventListener('touchstart', this.handleTouchStart, false);
    MapState.renderer.domElement.addEventListener('touchmove', this.handleTouchMove, false);
    MapState.renderer.domElement.addEventListener('touchend', this.handleTouchEnd, false);

    // PhoneEvents
    PhoneService.broadcast.$on(PhoneService.events.phone_map_selectcamera, (evt) => {
      const { cameraView } = evt.data;
      this.setCameraView(cameraView);
    });

    // Update loop
    this.startUpdateLoop();
  }

  // MARK: - Clear & deinit

  clear = () => {
    this.active = false;
    this.hasInitialized = false;

    // Resize event
    window.removeEventListener('resize', this.handleWindowResize, false);

    window.removeEventListener('idle', this.handleWindowResize, false);

    // Render events
    window.removeEventListener('map_render', this.queueRender, false);

    if (MapState.renderer) {
      // Mouse events
      MapState.renderer.domElement.removeEventListener('pointerdown', this.handleMouseDown, false);
      MapState.renderer.domElement.removeEventListener('pointerup', this.handleMouseUp, false);
      MapState.renderer.domElement.removeEventListener('wheel', this.handleMouseWheel, false);
      MapState.renderer.domElement.removeEventListener('pointermove', this.handleMouseMove);

      // Touch events
      MapState.renderer.domElement.removeEventListener('touchstart', this.handleTouchStart, false);
      MapState.renderer.domElement.removeEventListener('touchmove', this.handleTouchMove, false);
      MapState.renderer.domElement.removeEventListener('touchend', this.handleTouchEnd, false);
    }

    // Dispose what can be disposed
    this.disposeThreeInstances();
  }

  disposeThreeInstances = () => {
    // TODO: MAP, Clear more. All instances of THREE Object3D need to be destroyed.
    if (MapState.controls) {
      MapState.controls.dispose();
    }
  }

  // MARK: - Renderer

  startUpdateLoop = () => {
    if (this.active) {
      // Request next frame
      requestAnimationFrame(this.startUpdateLoop);
    }

    // Update
    this.update();

    // Render if needed
    if (this.hasRenderQueued) {
      this.hasRenderQueued = false;
      this.render();
    }
  }

  update = () => {
    // Update TWEEN
    TWEEN.update();

    // Update camera
    const delta = MapState.clock.getDelta();
    if (MapState.controls) {
      const updated = MapState.controls.update(delta);
      if (updated) {
        this.queueRender();
      }
    }

    // Update marker
    this.selectionManager.invalidateMarkerPosition();
  }

  setupRenderer = () => {
    try {
    // To avoid flickering in IE11, set preserveDrawingBuffer to true, this might be bad for performance so its only set on IE
      if (navigator.msMaxTouchPoints !== undefined) {
        MapState.renderer = new THREE.WebGLRenderer({
          antialias: true,
          preserveDrawingBuffer: true,
          logarithmicDepthBuffer: true,
        });
      } else {
        MapState.renderer = new THREE.WebGLRenderer({ antialias: true });
      }

      if (UserState.user?.AbstractUser) {
      // Currently only handle pixel ratios for the navigator (abstract users)
      // because it may cause excessive resolution on phones or tablets
        MapState.renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);
      }

      // Renderer settings
      MapState.renderer.shadowMap.enabled = true;
      MapState.renderer.autoClear = false;
      MapState.renderer.setClearColor(0x000000, 0);

      // Renderer size & add to canvas.
      MapState.targetCanvas.appendChild(MapState.renderer.domElement);
      MapState.renderer.setSize(MapState.targetCanvas.offsetWidth, MapState.targetCanvas.offsetHeight);
      MapState.renderer.domElement.setAttribute('oncontextmenu', 'return false');


      MapState.cssRenderer = new CSS2DRenderer(MapState.targetCanvas);
      MapState.cssRenderer.setSize(MapState.targetCanvas.offsetWidth, MapState.targetCanvas.offsetHeight);
      MapState.cssRenderer.domElement.style.position = 'absolute';
      MapState.cssRenderer.domElement.style.top = '0px';
      MapState.cssRenderer.domElement.style.pointerEvents = 'none';
      MapState.targetCanvas.appendChild(MapState.cssRenderer.domElement);
    } catch (e) {
    //
    }
  }

  queueRender = () => {
    this.hasRenderQueued = true;
  }

  render = () => {
    if (MapState.renderer && MapState.camera) {
      MapState.renderer.render(MapState.scene, MapState.camera);
    }
    if (MapState.cssRenderer && MapState.camera) {
      MapState.cssRenderer.render(MapState.scene, MapState.camera);
    }
  }


  // MARK: - Convenience

  getScreenCoordinates = (zone, disregardClickPosition) => {
    const vector = this.floorplanLoader.getZonePosition(zone, disregardClickPosition);
    const { camera, renderer } = MapState;
    const canvas = renderer.domElement;

    vector.project(camera);
    vector.x = ((vector.x + 1) * canvas.width) / 2;
    vector.y = ((-vector.y + 1) * canvas.height) / 2;
    return { x: vector.x, y: vector.y };
  }


  // MARK: - Handle floor changes

  setFloor = (floor, callback) => {
    // console.log('**********');
    // console.log('SET  FLOOR');
    // console.log(`ZID: ${floor.Zid}`);
    // console.log('**********');

    // Reset selection
    this.selectionManager.init();

    // Load floor
    this.floorplanLoader.setFloor(floor, () => {
      this.invalidateFloor();

      document.dispatchEvent(new Event('onFloorLoaded'));

      if (callback) {
        callback();
      }
    });
  }

  getFloor = () => {
    return MapState.floor;
  }

  invalidateFloor = () => {
    if (!MapState.floorplanGroup) {
      return;
    }

    // Add new floorplan
    MapState.scene.clear();
    MapState.scene.add(MapState.floorplanGroup);
    MapState.scene.add(MapState.navigationMarkerSprite);

    // Add lights
    const { lights } = MapState;
    for (let index = 0; index < lights.length; index += 1) {
      const light = lights[index];
      MapState.scene.add(light);
    }

    // Set camera view
    this.cameraController.setupCamera();

    // Set initial zone state
    this.floorplanLoader.onZoneStateChanged();

    // Render
    this.render();
  }

  onZoneStateChanged = () => {
    this.floorplanLoader.onZoneStateChanged();
    this.render();
  }


  // MARK: - Handle window resize

  handleWindowResize = (_) => {
    try {
      MapState.renderer.setSize(MapState.targetCanvas.offsetWidth, MapState.targetCanvas.offsetHeight);
      MapState.cssRenderer.setSize(MapState.targetCanvas.offsetWidth, MapState.targetCanvas.offsetHeight);
      this.cameraController.handleWindowResize();
      this.render();
    } catch (e) {
      //
    }
  }

  setCameraView = (view) => {
    MapState.setCustomCameraViewType(view);
    // eslint-disable-next-line no-unused-expressions
    this.cameraController.setCameraView(view);
    this.selectionManager.hideMarker();
    this.queueRender();
  }
  // MARK: - Mouse & touch events

  handleMouseDown = (event) => {
    event.preventDefault();
    const pos = this.getMousePosFromEvent(event);
    this.selectionManager.handleMouseDown(event, pos);
    this.queueRender();
  }

  handleMouseUp = (event) => {
    event.preventDefault();
    const pos = this.getMousePosFromEvent(event);
    this.selectionManager.handleMouseUp(event, pos);
    this.queueRender();
  }

  handleMouseWheel = (event) => {
    event.preventDefault();
    const pos = this.getMousePosFromEvent(event);
    this.selectionManager.handleMouseWheel(event, pos);
    this.queueRender();
  }

  handleMouseMove = (event) => {
    event.preventDefault();
    const pos = this.getMousePosFromEvent(event);
    this.selectionManager.handleMouseMove(event, pos);
    this.queueRender();
  }

  // Touch events

  handleTouchStart = (event) => {
    this.selectionManager.handleTouchStart(event);
  }

  handleTouchMove = (event) => {
    // If needed, implement
  }

  handleTouchEnd = (event) => {
    // If needed, implement
  }


  // MARK: - Event convenience

  getMousePosFromEvent = (event) => {
    const rect = MapState.renderer.domElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return new THREE.Vector2(x, y);
  }

  getTouchPosFromEvent = (event) => {
    const touchPositions = [];
    for (let index = 0; index < event.touches.length; index += 1) {
      const touch = event.touches[index];
      touchPositions.push(this.getMousePosFromEvent(touch));
    }
    return touchPositions;
  }

  showMapMarker = (Zone, animate = false) => {
    MapState.selectedZone = Zone;

    if (!this.selectionManager || !Zone) { return; }

    this.selectionManager.placeMarker(Zone);
    if (animate) { this.selectionManager.panAndZoomToZone(MapState.selectedZone); }
    this.queueRender();
  }

  panAndZoomToPosition = (position, zoomLevel) => {
    AnimationHelper.moveCameraFocus(position, zoomLevel);
  }

  panAndZoomOut = () => {
    AnimationHelper.moveCameraFocus(new THREE.Vector3(), 3);
  }

  deselectMapLocation = () => {
    if (!this.hasInitialized) { return; }
    MapState.selectedZone = null;
    const clearEmissiveColor = new THREE.Color(0x000000);
    if (this.selectionManager.selected) {
      this.selectionManager.selected.material.emissive = clearEmissiveColor;
      this.selectionManager.selected = null;
    }
    this.selectionManager.hideMarker();
    this.queueRender();
  }

  // MARK: - Colors

  getThemeStyleColors = () => {
    return {
      colorSceneBackground: this.getThemeStyleColor('--color-scene-background'),
      colorResourceFree: this.getThemeStyleColor('--color-resource-free'),
      colorResourceOccupied: this.getThemeStyleColor('--color-resource-occupied'),
      colorResourceAway: this.getThemeStyleColor('--color-resource-away'),
      colorResourceNotInFilter: this.getThemeStyleColor('--color-resource-not-in-filter'),
      colorResourceOther: this.getThemeStyleColor('--color-resource-other'),
      colorResourceSpace: this.getThemeStyleColor('--color-resource-space'),
      colorEmissiveHover: this.getThemeStyleColor('--color-emissive-hover'),
      colorEmissiveSelected: this.getThemeStyleColor('--color-emissive-selected'),
      colorSensor: this.getThemeStyleColor('--color-sensor'),
      colorSensorSelected: this.getThemeStyleColor('--color-sensor-selected'),
    };
  }

  getThemeStyleColor = (name) => new THREE.Color(
    window
      .getComputedStyle(window.document.body)
      .getPropertyValue(name).trim(),
  );
}

export default new MapController();

