/* eslint-disable no-restricted-syntax */
import ZoneState from '@/singletons/zone.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';

export const getResourcesStateCount = (resources) => {
  const zs = ZoneState.ZONE_STATE;
  const states = {};
  states[zs.AVAILABLE] = 0;
  states[zs.AWAY] = 0;
  states[zs.OCCUPIED] = 0;
  states[zs.UNKNOWN] = 0;
  resources.forEach((resource) => {
    const stateData = ZoneState.zoneStates[resource.Zid];
    if (stateData) {
      states[stateData.Status] += 1;
    } else {
      states[zs.AVAILABLE] += 1;
    }
  });

  return states;
};

export const getResourcesOfType = (type) => {
  for (const [, value] of Object.entries(CompanyState.zonesOnFloor)) {
    if (value[type]) return value[type];
  }
  return null;
};

export const getConstantOfType = (type) => {
  for (const [, value] of Object.entries(CompanyState.zonesOnFloor)) {
    if (value[type]) return value[type][0].Constant;
  }
  return null;
};

/*
   this.ZONE_STATE_TO_STR = {
      0: 'UNKNOWN',
      1: 'AVAILABLE',
      2: 'AWAY',
      3: 'OCCUPIED',
    }; */

export const sortResourcesByStatus = (resources) => resources.sort((a, b) => {
  if (!ZoneState.zoneStates || !ZoneState.zoneStates[a.Zid] || !ZoneState.zoneStates[b.Zid]) return 0;
  const status1 = ZoneState.zoneStates[a.Zid].Status;
  const status2 = ZoneState.zoneStates[b.Zid].Status;
  if (status1 === 0 || status2 === 0) {
    const value = status1 <= status2 ? 1 : -1;
    if (status1 === status2) return 0;
    return value;
  }
  const value = status1 <= status2 ? -1 : 1;
  if (status1 === status2) return 0;
  return value;
});

export const sortResourcesByName = (resources) => resources.sort((a, b) => a.Name.localeCompare(b.Name));

export const getResourceTypeIcon = (zid) => {
  if (!zid) return null;
  const zone = CompanyState.zones.find(({ Zid }) => Zid === zid);
  if (!zone) return null;
  return zone.Icon;
};
