/* eslint-disable key-spacing */
import BookingService from '@/Services/Bookings/bookings.service';
// import SensorService from '@/Services/Sensors/sensor.dservice';
// import ResourceMap from '@/Services/BTW/ResourceMap';
import UserState from '@/singletons/user.state.singleton';
import UserService from '@/Services/User/user.service';
import ZoneStateService from '@/Services/Zones/zone.state.service';

class SocketService {
  constructor() {
    this.parsed = null;
    this.evt = null;
    this.events = {
      booking_state:    'web:booking:state',
      booking_deleted:  'web:booking:deleted',
      booking_created:  'web:booking:created',
    };
  }

  handle(event) {
    try {
      this.parsed = JSON.parse(event.data);
      if (this.parsed !== 'PING_PONG') {
        switch (this.parsed.EventType) {
          /** USER */
          case 'user.status.changed':
            this.userStatusChanged();
            break;

          case 'user.changed':
            this.userChanged();
            break;

          /** BOOKING */
          case 'booking.status.changed':
            this.evt = new Event(this.events.booking_state);
            this.evt.data = this.parsed;
            window.dispatchEvent(this.evt);
            BookingService.updateBooking(this.parsed.Value);
            UserService.updateBooking(this.parsed.Value);
            // ResourceMap.updateResourceLists();
            ZoneStateService.invalidateZoneAvailabilityStates();
            break;

          case 'booking.created':
            this.evt = new Event(this.events.booking_created);
            this.evt.data = this.parsed;
            window.dispatchEvent(this.evt);
            BookingService.pushBooking(this.parsed.Value);
            UserService.addBooking(this.parsed.Value);
            // ResourceMap.updateResourceLists();
            ZoneStateService.invalidateZoneAvailabilityStates();
            break;

          case 'booking.deleted':
            this.evt = new Event(this.events.booking_deleted);
            this.evt.data = this.parsed;
            window.dispatchEvent(this.evt);
            BookingService.removeBooking(this.parsed.TargetId);
            UserService.removeBooking(this.parsed.TargetId);
            // ResourceMap.updateResourceLists();
            ZoneStateService.invalidateZoneAvailabilityStates();
            break;

          case 'booking.modified':
            this.evt = new Event(this.events.booking_state);
            this.evt.data = this.parsed;
            window.dispatchEvent(this.evt);
            BookingService.updateBooking(this.parsed.Value);
            UserService.updateBooking(this.parsed.Value);
            // ResourceMap.updateResourceLists();
            ZoneStateService.invalidateZoneAvailabilityStates();
            break;

          case undefined:
            this.eventTypeUndefined();
            break;

          default:
            this.eventTypeNotRecognized();
            break;
        }
      }
    } catch (e) {
      console.log('Couldnt handle event');
      console.log(e);
    }
  }

  userStatusChanged() {
    // console.log('status socket');
    if (UserState.username === this.parsed.TargetId) {
      UserService.updateUserStatus(this.parsed.Value);
    }
  }

  userChanged() {
    // console.log('User changed');
    if (UserState.username === this.parsed.TargetId) {
      UserService.updateUser(this.parsed.Value);
    }
  }

  eventTypeUndefined() {
    console.log('The event has no type');
    console.log(this.parsed);
  }

  // eslint-disable-next-line class-methods-use-this
  eventTypeNotRecognized() {
    // console.log(`The event: ${this.parsed.EventType} has no handler, create one?`);
  }
}

export default new SocketService();
