import apiClient from './api.interceptor';

const baseUrlProxy = process.env.VUE_APP_BASE_URL;

// ZoneTypes
export const getZoneTypes = (cid) => apiClient.get(`${baseUrlProxy}/core/zone/types?cid=${cid}`);

// Zones
export const getCompanyZones = (parent) => apiClient.get(`${baseUrlProxy}/core/zone?&parent=${parent}`);
export const getZones = (parent) => apiClient.get(`${baseUrlProxy}/core/zone?realm=true&parent=${parent}`);
export const getCompanyZonesWithType = (parent, type) => apiClient.get(`${baseUrlProxy}/core/zone?parent=${parent}&type=${type}`);
export const getZone = (zid) => apiClient.get(`${baseUrlProxy}/core/zone/${zid}`);
export const getColleagues = () => apiClient.get(`${baseUrlProxy}/state/v5/colleagues`);
export const getRealmStates = () => apiClient.get(`${baseUrlProxy}/state/v5/realmstates`);
export const getBookingSuggestions = (dateString, zid) => apiClient.get(`${baseUrlProxy}/zone/v1/${zid}/suggestions?date=${dateString}`);

// Companies
export const getCompanyTree = () => apiClient.get(`${baseUrlProxy}/core/company/tree`);
export const getCompaniesByParentId = (parent) => apiClient.get(`${baseUrlProxy}/core/company?cid=${parent}`);

// Misc
export const getAvailableUserStatusTypes = (cid) => apiClient.get(`${baseUrlProxy}/core/v3/user/${cid}/availableUserStatusTypes`);
export const getAssets = (cid) => apiClient.get(`${baseUrlProxy}/core/asset?cid=${cid}`);
