class FloorCameraSettings {
  static saveFloorCamera(cameraView, selectedFloor) {
    localStorage.setItem(selectedFloor.toString(), cameraView);
  }

  static retriveFloorCamera(selectedFloor) {
    return localStorage.getItem(selectedFloor.toString());
  }

  static checkIfExistsInStorage(selectedFloor) {
    if (localStorage.getItem(selectedFloor.toString()) !== null) {
      return true;
    }
    return false;
  }
}

export default FloorCameraSettings;
