/* global MobileWrapper */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */

import Vue from 'vue';
import UserState from '@/singletons/user.state.singleton';
import PhoneDebugger from './phone.debug';
/**
 * Communicator between the web and the phone app. Used to
 * receive and send events. Add event listeners on broadcast
 * property to listen for specific events elsewhere.
 *
 * When sending from web use:
 * **send(event, data)** <br/>
 * _event_: is the name of the event.
 * _data_: is the data of any structure or value.
 *
 * When receiving events from the app we listen for
  * **phone** events emitted from the _window_.
 * These come formatted as { event: String, data: Any? }.
 *
 * When listening for phone events broadcasted, listen on
 * **PhoneService.broadcast** with the event you want to
 * listen to.
 */

// If true, the app will allow you to login & open kebab menus e.g.
const DEBUG_WEB_AS_PHONE = false;

class PhoneService {
  //
  // MARK: - Initalize

  constructor() {
    this.listener = window.addEventListener('phone', this.onAppMessageReceived.bind(this));
    this.broadcast = new Vue();

    // Always use events from this list. So that we can keep track of where they are used.
    // Make sure to keep the "phone.specification.md" up to date.

    /* eslint-disable key-spacing */
    this.events = {
      phone_event_unhandled:        'phone:event:unhandled',
      phone_module_change:          'phone:module:change',
      phone_module_state:           'phone:module:state',
      phone_kebab_show:             'phone:kebab:show',
      phone_kebab_response:         'phone:kebab:response',
      phone_navigationbar_report:   'phone:navigationbar:report',
      phone_navigationbar_state:    'phone:navigationbar:state',
      phone_navigationbar_btnback:  'phone:navigationbar:btnback',
      phone_navigationbar_btninfo:  'phone:navigationbar:btninfo',
      phone_map_selectresource:     'phone:map:selectresource',
      phone_map_selectlocation:     'phone:map:selectlocation',
      phone_map_selectcamera:       'phone:map:selectcamera',
      phone_book_gotoresource:      'phone:book:gotoresource',
      phone_user_reportauth:        'phone:user:reportauth',
      phone_token_update:           'phone:token:update',
      phone_app_version:            'phone:app:version',
      phone_user_authstate:         'phone:user:authstate',
      phone_user_signout:           'phone:user:signout',
      phone_user_update:            'phone:user:update',
      phone_device_connect:         'phone:device:connect',
    };

    this.modules = {
      CALENDAR: 'CALENDAR',
      BOOK: 'BOOK',
      PLAN: 'PLAN',
      MAP: 'MAP',
      SETTINGS: 'SETTINGS',
      SPLASH: 'SPLASH',
      ADMIN: 'ADMIN',
    };

    // TODO: Should only run on localhost.
    this.debugger = new PhoneDebugger(this.events);

    this.appendMetaTags();
  }

  // MARK: - Checking if user is in mobile app
  isAndroid() {
    return (
      typeof MobileWrapper !== 'undefined'
      && MobileWrapper != null
    );
  }

  isIOS() {
    return (
      window.webkit != null
      && window.webkit.messageHandlers != null
      && window.webkit.messageHandlers.webAppWrapperEventBus != null
    );
  }

  isInPhoneApp() {
    return (this.isAndroid() || this.isIOS() || DEBUG_WEB_AS_PHONE);
  }

  isDebuggingWebAsPhone() {
    return DEBUG_WEB_AS_PHONE;
  }

  // MARK: - Send data to the phone app

  /**
   * Send event to the phone app.
   * @param {String} event
   * @param {Any?} data
   */
  send(event, data) {
    const message = { event, data };
    const json = JSON.stringify(message);
    try {
      if (this.isAndroid()) {
        MobileWrapper.postEvent(json);
      } else if (this.isIOS()) {
        window.webkit.messageHandlers.webAppWrapperEventBus.postMessage(json);
      }
    } catch (error) {
      console.log('Error from sending data to the phone app');
      console.log(error);
    }
  }

  // MARK: - Receive phone app events

  onAppMessageReceived(evt) {
    // Parse incoming message
    let event = null;
    try {
      event = JSON.parse(evt.data);
    } catch (err) { /**/ }

    if (event == null || typeof event.event !== 'string') {
      return;
    }

    // Handle event:
    // TODO: Maybe some or all implementation of
    // the received events should be handled here.
    // Right now they are listening for broadcasts
    // and handled in their respective places.
    // See possible events at the constructor _events_.

    // Emit event
    this.broadcast.$emit(event.event, event);
  }

  /** Append metatags for disabling zoom for native devices */
  appendMetaTags() {
    if (!this.isInPhoneApp()) return;

    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, user-scalable=no';
    document.head.appendChild(meta);
  }

  reportUnhandled(evt, reason) {
    const data = {
      event: evt,
      reason,
    };
    this.send(this.events.phone_event_unhandled, data);
  }

  async get(event) {
    return new Promise((resolve, _) => {
      this.broadcast.$on(event, (e) => {
        const obj = e.data;
        resolve(obj);
      });

      // No response
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
}

export default new PhoneService();
