/* eslint-disable no-underscore-dangle */

class MapState {
  constructor() {
    // Used to await map finished loading
    this.hasFinnishedLoadingPromise = new Promise((resolve) => {
      this.hasFinnishedLoadingPromiseResolver = resolve;
    });

    // Mode Enum
    this.MODE_TYPE = {
      book: 'book',
      sensor: 'sensor',
      issue: 'issue',
      navigator: 'navigator',
    };

    // Built-in camera view types
    this.CAMERA_VIEW_TYPE = {
      overview: 1,
      topDown: 2,
      _3d: 3,
      custom: 4,
    };

    // Render orders
    this.RENDER_ORDERS = {
      sprite: 1000,
      spriteSelected: 2000,
    };

    // General
    this.scene = null;
    this.renderer = null;
    this.cssRenderer = null;
    this.targetCanvas = null;
    this.popup = null;
    this.colors = null;
    this.msg = null;
    this.mode = this.MODE_TYPE.book;
    this.clock = null;

    // Camera
    this.camera = null;
    this.controls = null;
    this.cameraViewType = this.CAMERA_VIEW_TYPE.overview;
    this.customCameraViewType = null;

    // Loaded
    this.hasLoadedOnce = false;

    // Markers
    this.navigationMarkerTexture = null;
    this.navigationMarkerMaterial = null;
    this.navigationMarkerSprite = null;
    this.navigatorPopupObject = null;
    this.elementNavigatorPopup = null;
    this.issueTextures = {
      OPEN: { default: null, notifiication: null },
      IN_PROGRESS: { default: null, notifiication: null },
      RESOLVED: { default: null, notifiication: null },
      CLOSED: { default: null, notifiication: null },
    };

    // Objects
    this.markerObjects = {
      issueMarkers: {},
    };

    // Floor
    this.floor = null;
    this.floorAsset = null;
    this.floorPrefab = null;
    this.floorplanGroup = null;
    this.planElements = {};
    this.lights = [];

    // Sensors
    this.sensorObjs = [];

    // Raycast groups
    this.rayCastGroup = [];
    this.rayCastGroupObjects = [];

    // Selection
    this.selectedObj = null;
    this.selectedZone = null;
    this.mouse = null;
  }

  setCustomCameraViewType(viewType) {
    const overview = ['perspective', 'overview'];
    const topDown = ['top-down', 'topdown'];
    const _3d = ['3d'];
    const typeToCheck = viewType.toLowerCase();

    if (overview.includes(typeToCheck)) {
      this.cameraViewType = this.CAMERA_VIEW_TYPE.overview;
    } else if (topDown.includes(typeToCheck)) {
      this.cameraViewType = this.CAMERA_VIEW_TYPE.topDown;
    } else if (_3d.includes(typeToCheck)) {
      this.cameraViewType = this.CAMERA_VIEW_TYPE._3d;
    } else {
      this.cameraViewType = this.CAMERA_VIEW_TYPE.custom;
    }
    this.customCameraViewType = viewType;
  }
}

export default new MapState();
