import SocketIO from '@/singletons/socket.io.state.singleton';
import ZoneStateService from '@/Services/Zones/zone.state.service';

class StatesIOService {
  static SetupListeners() {
    /** Socket IO Events */
    SocketIO.legacy.on('state.created', (event) => {
      ZoneStateService.updateRealmState(event.TargetZid, event.Value);
    });
    SocketIO.legacy.on('state.modified', (event) => {
      ZoneStateService.updateRealmState(event.TargetZid, event.Value);
    });
    SocketIO.legacy.on('state.deleted', (event) => {
      ZoneStateService.updateRealmState(event.TargetZid, null);
    });
    SocketIO.legacy.on('connect', () => {
      ZoneStateService.fetchRealmState();
    });
  }
}

export default StatesIOService;
