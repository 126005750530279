import apiClient from './api.interceptor';

const baseUrlProxy = process.env.VUE_APP_BASE_URL;

// Get bookings for specific zonetype during specified time
// eslint-disable-next-line import/prefer-default-export
export const fetchSpecificZoneType = (realm, parent, from, until, zoneType) => apiClient.get(`${baseUrlProxy}/booking/bto/fullweek?realm=${realm}&parent=${parent}&from=${from}&until=${until}&zoneType=${zoneType}`);

// Get all bookings between specified times
export const fetchAllZoneTypes = (realm, parent, from, until) => apiClient.get(`${baseUrlProxy}/booking/bto/fullweek/all?realm=${realm}&parent=${parent}&from=${from}&until=${until}`);

// Get everything
export const fetchFullZoneMap = (realm, parent, from, until) => apiClient.get(`${baseUrlProxy}/booking/bto/fullmap?realm=${realm}&parent=${parent}&from=${from}&until=${until}`);

// Get all bookings of group
export const fetchAllGroupBookings = (realm, parent, from, until, group) => apiClient.get(`${baseUrlProxy}/booking/bto/fullweek/group?realm=${realm}&parent=${parent}&from=${from}&until=${until}&group=${group}`);
