<template>
  <div class="avatar--container">
    <span
      v-if="user && user.Status && statusIndicator"
      class="user-status__container"
      :class="statusIndicator"
    />
    <img
      class="avatar avatar-rounded"
      :src="userIcon"
      :height="height"
      :width="width"
      aria="Person avatar aria"
    >
  </div>
</template>

<script>
import CompanyService from '@/Services/Company/company.service';

export default {
  props: {
    src: {
      type: String,
      required: false,
      default: '/images/default_avatar.png',
    },
    height: {
      type: String,
      required: false,
      default: '100px',
    },
    width: {
      type: String,
      required: false,
      default: '100px',
    },
    border: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    userIcon() { return this.user?.AvatarUrl || this.user?.avatar || this.src || '/images/default_avatar.png'; },
    status() { return CompanyService.getUserStatusFromType(this.user?.Status?.Type); },
    statusIndicator() { return CompanyService.getUserStatusFromType(this.user?.Status?.Type)?.BackgroundColor || null; },
  },
};
</script>
