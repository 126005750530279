<template>
  <div
    v-if="socketConnectionState !== CONNECTION_STATES.CONNECTED"
    class="connection-state-container"
  >
    <div
      v-if="socketConnectionState === CONNECTION_STATES.CONNECTING"
    >
      <svg-icon
        class="socket-connection-icon"
        src="/icons/cloud-question.svg"
      />
    </div>
    <div
      v-else-if="socketConnectionState === CONNECTION_STATES.DISCONNECTED"
    >
      <svg-icon
        class="socket-connection-icon"
        src="/icons/cloud-exclamation.svg"
      />
    </div>
  </div>
</template>

<script>
import SocketIOService, { CONNECTION_STATES } from '@/singletons/socket.io.state.singleton';

export default {
  data() {
    return {
      SocketIOService,
      CONNECTION_STATES,
      socketConnectionState: CONNECTION_STATES.DISCONNECTED,
    };
  },
  created() {
    this.interval = setInterval(() => {
      this.socketConnectionState = SocketIOService.getConnectedState();
    }, 1000);
  },
};
</script>

<style lang="scss">
$item-height: 80px;
.connection-state-container{
  display: flex;
  justify-content: right;
  *{
    margin:auto 0;
  }
}

.socket-connection-icon{
  fill: white;
  svg{
    vertical-align: baseline;
    height: 1.5rem;
  }
}

</style>
