import PhoneService from '../Services/Phone/phone.service';

class AuthState {
  constructor() {
    this.isAuthenticated = false;
    this.authMethod = localStorage.auth_method || null;
    this.authToken = localStorage.auth_token_msal || null;
  }

  setAuthenticated(auth) {
    this.isAuthenticated = auth;
  }

  getToken() { return this.authToken; }

  setToken(token) {
    localStorage.setItem('auth_token_msal', token);
    this.authToken = token;
  }

  getMethod() {
    if (this.authMethod) {
      return this.authMethod;
    }
    this.setMethod();
    return this.authMethod;
  }

  static clearMethod() {
    localStorage.removeItem('auth_method');
  }

  setMethod(method) {
    let m = null;
    if (PhoneService.isInPhoneApp()) {
      m = 'cookie';
    } else if ('msal.idtoken' in localStorage) {
      m = 'azure';
    } else if ('auth_password' in localStorage) {
      m = 'basic';
    } else if (method) {
      m = method;
    }
    if (m) {
      localStorage.auth_method = m;
      this.authMethod = m;
    } else {
      console.warn('Could not set login method');
    }
  }

  destroy() {
    AuthState.clearMethod();
    this.authMethod = null;
    this.isAuthenticated = false;
    this.authToken = null;
  }
}

export default new AuthState();
