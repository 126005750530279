/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */

import * as THREE from 'three';
import _ from 'underscore';
import MapState from '@/singletons/map.state.singleton';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import PhoneService from '@/Services/Phone/phone.service';
import AnimationHelper from './animation.helper';

/**
 * Keep track of hover, selections of map items.
 */
class SelectionManager {
  constructor(getScreenCoordinatesFunc, cameraController) {
    this.getScreenCoordinates = getScreenCoordinatesFunc;
    this.cameraController = cameraController;
    this.init();
  }

  init = () => {
    // Ground reference for raycasts
    this.ground = new THREE.Plane(new THREE.Vector3(0, 1, 0));

    // Raycast groups
    MapState.rayCastGroup = [];
    MapState.rayCastGroupObjects = [];

    // Selection
    this.INTERSECTED = null;
    this.INTERSECTED_OBJ = null;
    this.intersects = [];
    this.selected = null;
    MapState.selectedObj = null;

    // Selected zone
    MapState.selectedZone = null;

    // Mouse
    this.currentMousePos = new THREE.Vector2();
    this.lastMouseDownPos = new THREE.Vector2();
    this.lastMousePos = new THREE.Vector2();
    this.viewportMousePos = new THREE.Vector2();

    // Raycaster
    this.raycaster = new THREE.Raycaster();
    this.raycaster.params.Points.threshold = 0.32;

    // Events
    this.eventListeners = {};

    // Constants
    this.selectionFitToSphereRadius = 8;

    // Null zone
    this.triggerEvent('on_zone_selected', { id: null });

    // this.hideMarker();
  }


  // MARK: - Handle selections

  raycast = () => {
    // Make sure we have camera
    const { camera } = MapState;
    if (!camera) {
      return;
    }

    // Get viewport mouse position
    MapState.mouse = this.currentMousePos.clone();
    MapState.mouse.x = (this.currentMousePos.x / MapState.targetCanvas.offsetWidth) * 2 - 1;
    MapState.mouse.y = -(this.currentMousePos.y / MapState.targetCanvas.offsetHeight) * 2 + 1;

    this.raycaster.setFromCamera(MapState.mouse, camera);

    if (MapState.mode === MapState.MODE_TYPE.book) {
      // Get intersects & clear color
      this.intersects = this.raycaster.intersectObjects(MapState.rayCastGroup);
      const clearEmissiveColor = new THREE.Color(0x000000);

      // Hover over intersection
      if (this.intersects.length > 0) {
        const currentIntersect = this.intersects[0].object;
        const currentCanBeSelected = (!currentIntersect.userData.zone || currentIntersect.userData.zone.Color !== null);

        // Clear if last intersected is not the current one
        if (this.INTERSECTED && this.INTERSECTED !== currentIntersect && this.INTERSECTED !== this.selected) {
          this.INTERSECTED.material.emissive = clearEmissiveColor;
        }

        // Current intersect
        this.INTERSECTED = currentIntersect;

        // Set emissive if not selected and can be selected
        if (this.INTERSECTED !== this.selected && currentCanBeSelected) {
          currentIntersect.material.emissive = MapState.colors.colorEmissiveHover;
        }

        // No objects in intersection
      } else {
        // Clear last intersected if needed
        if (this.INTERSECTED && this.INTERSECTED !== this.selected) {
          this.INTERSECTED.material.emissive = clearEmissiveColor;
        }
        this.INTERSECTED = null;
      }
    }

    // Select circleobj
    if (MapState.mode === MapState.MODE_TYPE.sensor) {
      // Reset current circleobj hover
      if (this.INTERSECTED_OBJ && this.INTERSECTED_OBJ !== MapState.selectedObj) {
        this.resetSprite(this.INTERSECTED_OBJ);
      }
      this.INTERSECTED_OBJ = null;

      this.intersects = this.raycaster.intersectObjects(MapState.rayCastGroupObjects, true);

      let mindist = 9999;
      _.each(this.intersects, (intersection) => {
        // Find parent, parent has userdata and we want parent to do be able to do changes on all its children
        const parent = MapState.rayCastGroupObjects.filter((obj) => obj.children.find((child) => child === intersection.object))[0];

        if (intersection.distance < mindist && parent.userData.clickable && !MapState.selectedObj) {
          mindist = intersection.distance;

          // Current intersect
          this.INTERSECTED_OBJ = parent;

          // Set emissive if not selected and can be selected
          if (this.INTERSECTED_OBJ !== MapState.selectedObj) {
            this.setSpriteHover(parent);
          }
        }
      });
    }

    if (MapState.mode === MapState.MODE_TYPE.issue) {
      if (this.INTERSECTED_OBJ && this.INTERSECTED_OBJ !== MapState.selectedObj) {
        AnimationHelper.stopAnimating(this.INTERSECTED_OBJ);
      }
      this.INTERSECTED_OBJ = null;
      let closestToPointer = null;
      let minDistance = null;

      this.raycaster.intersectObjects(Object.values(MapState.markerObjects.issueMarkers)).forEach((intersection) => {
        const containerGroup = intersection.object.parent;
        const distanceToObject = intersection.point.distanceTo(containerGroup.position);

        if (distanceToObject < minDistance || !minDistance) {
          // Select the object closest to the pointer, needed for when there are overlapping sprites
          minDistance = distanceToObject;
          closestToPointer = containerGroup;
        }
      });

      if (closestToPointer && closestToPointer.userData.clickable) {
        this.INTERSECTED_OBJ = closestToPointer;
        if (this.INTERSECTED_OBJ !== MapState.selectedObj) {
          this.setSpriteHover(this.INTERSECTED_OBJ);
        }
      }
    }
  }

  setSpriteHover = (spriteObj) => {
    // Show bigger circleobj on hover
    // Do not if a sensor is selected
    this.storeOriginalScaleIfNeeded(spriteObj);
    spriteObj.scale.set(
      spriteObj.prev_scale.x * 1.1,
      spriteObj.prev_scale.y * 1.1,
      spriteObj.prev_scale.z * 1.1,
    );

    spriteObj.renderOrder = MapState.RENDER_ORDERS.spriteSelected;
  }

  storeOriginalScaleIfNeeded = (object) => {
    if (!object.prev_scale) {
      object.prev_scale = {
        x: object.scale.x,
        y: object.scale.y,
        z: object.scale.z,
      };
    }
  }

  resetSprite = (spriteObj) => {
    this.storeOriginalScaleIfNeeded(spriteObj);
    spriteObj.scale.set(
      spriteObj.prev_scale.x,
      spriteObj.prev_scale.y,
      spriteObj.prev_scale.z,
    );
    spriteObj.renderOrder = MapState.RENDER_ORDERS.sprite;
    AnimationHelper.clearAnimation(spriteObj);
  }

  setSpriteNotSelected = (circleobj) => {
    this.storeOriginalScaleIfNeeded(circleobj);
    circleobj.scale.set(
      circleobj.prev_scale.x,
      circleobj.prev_scale.y,
      circleobj.prev_scale.z,
    );
    AnimationHelper.setOpacity(circleobj, 0.5);
  }

  clearSelection = () => {
    this.selected = null;
    MapState.selectedObj = null;
    this.INTERSECTED = null;
    this.INTERSECTED_OBJ = null;
    this.selectIntersected();
  }

  selectIntersected = () => {
    // Select mesh
    // ---
    const clearEmissiveColor = new THREE.Color(0x000000);

    if (this.INTERSECTED && this.INTERSECTED !== this.selected) {
      // reset old selected if old exists
      if (this.selected) {
        this.selected.material.emissive = clearEmissiveColor;
      }

      // set slected to what the user clicked
      this.selected = this.INTERSECTED;
      if (!this.selected.userData.zone || this.selected.userData.zone.Color !== null) {
        this.selected.material.emissive = (MapState.colors.colorEmissiveSelected);
      }
    } else if (this.selected) {
      this.selected.material.emissive = clearEmissiveColor;
      this.selected = null;
    }

    if (MapState.mode === MapState.MODE_TYPE.sensor) {
      if (this.INTERSECTED_OBJ && this.INTERSECTED_OBJ !== MapState.selectedObj) {
        // reset old selected if old exists
        if (MapState.selectedObj) {
          this.resetSprite(MapState.selectedObj);
        }

        // set slected to what the user clicked
        MapState.selectedObj = this.INTERSECTED_OBJ;
        this.setSpriteSelected(MapState.selectedObj);

        // Sprite was selected, trigger event
        if (MapState.selectedObj.userData.sensorId && this.eventListeners.on_sensor_selected) {
          const evt = { id: MapState.selectedObj.userData.sensorId };
          this.triggerEvent('on_sensor_selected', evt);
        }

        // Dim all others
        this.invalidateSensorSpritesDimStatus();
      } else if (MapState.selectedObj) {
        this.resetSprite(MapState.selectedObj);
        MapState.selectedObj = null;

        // Reset all others
        this.invalidateSensorSpritesDimStatus();

        if (this.eventListeners.on_sensor_selected) {
          // No selected circleobj, trigger event
          const evt = { id: null };
          this.triggerEvent('on_sensor_selected', evt);
        }

        // If deselected, raycast to keep the hover effect
        this.raycast();
      }
    }

    if (MapState.mode === MapState.MODE_TYPE.issue) {
      if (this.INTERSECTED_OBJ && this.INTERSECTED_OBJ !== MapState.selectedObj) {
        this.triggerEvent('on_issue_selected', this.INTERSECTED_OBJ);
      } else if (MapState.selectedObj) {
        this.triggerEvent('on_issue_deselected');
        // If deselected, raycast to keep the hover effect
        this.raycast();
      }
    }

    if (MapState.mode === MapState.MODE_TYPE.navigator) {
      const vClickPosition = new THREE.Vector3();
      vClickPosition.set(
        (this.lastMouseDownPos.x / MapState.targetCanvas.offsetWidth) * 2 - 1,
        -(this.lastMouseDownPos.y / MapState.targetCanvas.offsetHeight) * 2 - 1,
        5,
      );

      this.raycaster.ray.at(this.raycaster.ray.distanceToPlane(this.ground), vClickPosition);
      this.triggerEvent('on_navigation_marker_set', vClickPosition);
    }
  }

  selectIntersectedIfNeeded() {
    let hideMarker = false;
    if (this.lastMouseDownPos.distanceTo(this.currentMousePos) < 5) {
      this.raycast();
      this.selectIntersected();
      MapState.selectedZone = this.getZoneFromSelected(this.selected || MapState.selectedObj);
      const currentlySelectedZid = MapState.selectedZone?.Zid;
      if (MapState.selectedZone?.Properties?.type === 'space') {
        this.triggerEvent('on_space_selected', { id: currentlySelectedZid });
      } else {
        this.triggerEvent('on_zone_selected', { id: currentlySelectedZid });
      }

      if (!currentlySelectedZid && MapState.mode !== MapState.MODE_TYPE.navigator) {
        this.hideMarker();
        this.hideNavigatorResourcePopUp();
        this.triggerEvent('on_zone_deselected');
      }

      if (MapState.selectedZone && MapState.selectedZone.linkTo) {
        this.cameraController.setCameraView(MapState.selectedZone.linkTo.View);
        hideMarker = true;
      }

      const { camera } = MapState;

      if (MapState.selectedZone && camera) {
        this.raycaster.setFromCamera(this.viewportMouse, camera);

        const vector = new THREE.Vector3();
        this.raycaster.ray.at(this.raycaster.ray.distanceToPlane(this.ground), vector);
        MapState.selectedZone.clickPosition = vector;
        MapState.selectedZone.clickPositionScreenSpace = this.lastMouseDownPos.clone();

        // Place marker
        if (!hideMarker) {
          this.placeMarker();
        }
      }
    }
  }

  setSpriteSelected = (spriteObj) => {
    this.storeOriginalScaleIfNeeded(spriteObj);
    spriteObj.scale.set(
      spriteObj.prev_scale.x * 1.1,
      spriteObj.prev_scale.y * 1.1,
      spriteObj.prev_scale.z * 1.1,
    );
    AnimationHelper.setOpacity(spriteObj, 1);
    AnimationHelper.selectSpritePulse(spriteObj);
  }

  invalidateSensorSpritesDimStatus = () => {
    _.each(MapState.rayCastGroupObjects, (child) => {
      if (child.userData.sensorId) {
        if (child !== MapState.selectedObj) {
          if (MapState.selectedObj) {
            this.setSpriteNotSelected(child);
            AnimationHelper.setOpacity(child, 0.3);
          } else {
            this.resetSprite(child);
            AnimationHelper.setOpacity(child, 1);
          }
        }
      }
    });
  }

  selectSensor = (id) => {
    if (!id) {
      // reset old selected if old exists
      if (MapState.selectedObj) {
        this.resetSprite(MapState.selectedObj);
        MapState.selectedObj = null;
      }

      // Dim all others
      this.invalidateSensorSpritesDimStatus();
      window.dispatchEvent(new Event('map_render'));
      return;
    }
    _.each(MapState.rayCastGroupObjects, (sensor) => {
      if (sensor && sensor.userData && sensor.userData.sensorId && sensor.userData.sensorId === id) {
        // reset old selected if old exists
        if (MapState.selectedObj) {
          this.resetSprite(MapState.selectedObj);
        }

        // Select sensor
        MapState.selectedObj = sensor;
        this.setSpriteSelected(sensor);

        // Dim all others
        this.invalidateSensorSpritesDimStatus();
      }
    });

    this.moveTo(MapState.selectedObj);
    window.dispatchEvent(new Event('map_render'));
  }

  moveTo = async (object) => {
    if (!object) {
      return;
    }
    // Get target world pos
    const selectedWP = new THREE.Vector3();
    object.getWorldPosition(selectedWP);

    // Move camera to position and fit
    const sphere = new THREE.Sphere(selectedWP, this.selectionFitToSphereRadius);
    await MapState.controls.fitToSphere(sphere, true);
    const delta = MapState.clock.getDelta();
    MapState.controls.update(delta);

    // Trigger render event
    window.dispatchEvent(new Event('map_render'));
  }


  // MARK: - Convenience

  getZoneFromSelected = (_selected) => {
    if (_selected !== null) {
      if (_selected.userData.zone) {
        return _selected.userData.zone;
      }

      if (_selected.userData.info) {
        return {
          info: _selected.userData.info,
          selected: _selected,
        };
      }

      if (_selected.userData.linkTo) {
        return {
          linkTo: _selected.userData.linkTo,
          selected: _selected,
        };
      }

      return this.getZoneFromSelected(_selected.parent);
    }
    return null;
  }


  // MARK: - Marker

  placeMarker = (zone = MapState.selectedZone) => {
    this.invalidateMarkerPosition(true, zone);
  }

  hideMarker = () => {
    MapState.navigationMarkerSprite.material.visible = false;
  }

  hideNavigatorResourcePopUp = () => {
    MapState.scene.remove(MapState.navigatorPopupObject);
    MapState.navigatorPopupObject = null;
    if (MapState.controls) {
      MapState.controls.maxZoom = 30;
      MapState.controls.minZoom = 0.5;
    }
  }

  invalidateMarkerPosition = (forceShow, zone = MapState.selectedZone) => {
    if (MapState.navigationMarkerSprite && (MapState.navigationMarkerSprite.visible === false || forceShow)) {
      const group = this.findChildGroup(MapState.floorplanGroup.children, zone.Zid, (MapState.selectedZone?.Type === 'LOCKER'));
      if (!group) return;
      const vector = this.getVectorCoordinatesFromGroup(group);

      if (!PhoneService.isInPhoneApp()) {
        this.showNavigatorResourcePopup(vector);
      } else {
        MapState.navigationMarkerSprite.material.visible = true;
        this.placeMarkerPosition(vector);
      }
    }
  }

  showNavigatorResourcePopup = (vector) => {
    if (MapState.elementNavigatorPopup) {
      MapState.elementNavigatorPopup.style.opacity = 1;
      MapState.elementNavigatorPopup.style.pointerEvents = 'auto';
      if (MapState.navigatorPopupObject) {
        MapState.scene.remove(MapState.navigatorPopupObject);
        MapState.navigatorPopupObject = null;
      }
      MapState.navigatorPopupObject = new CSS2DObject(MapState.elementNavigatorPopup);
      MapState.navigatorPopupObject.position.set(vector.x, vector.y + 1, vector.z);
      MapState.scene.add(MapState.navigatorPopupObject);
    }
  }

  placeMarkerPosition = ({ x = 0, y = 0, z = 0 }) => {
    // const yOffset = y > 0.09 ? y * 2 : y + 1.2;
    MapState.navigationMarkerSprite.position.set(x, y, z);
  }

  // eslint-disable-next-line class-methods-use-this
  findChildGroup(children, name = MapState.selectedZone?.Zid, findParent) {
    let foundChild = null;
    const recurse = (_ch = children, _name = name) => {
      if (_ch?.name === _name) foundChild = _ch;
      const chldrn = _ch?.length ? _ch : _ch.children;
      if (chldrn?.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const ch of chldrn) {
          if (foundChild) break;
          recurse(ch, name);
        }
      }
    };
    recurse();
    return (foundChild && foundChild.parent.parent.type !== 'Scene' && findParent) ? foundChild.parent : foundChild;
  }

  getVectorCoordinatesFromGroup = (group) => {
    const box = new THREE.Box3();
    const vector = new THREE.Vector3();
    const vectorSize = new THREE.Vector3();
    box.setFromObject(group);
    box.getCenter(vector);
    box.getSize(vectorSize);
    if (MapState.customCameraViewType === 'Top-down') {
      vector.z -= 0.5;
    }
    vector.y = vectorSize.y > 0.09 ? vectorSize.y + vector.y : vector.y + 1.5;
    return vector;
  }

  panAndZoomToZone = async (zone, zoomLevel = 5) => {
    const group = this.findChildGroup(MapState.floorplanGroup.children, zone.Zid, (MapState.selectedZone?.Type === 'LOCKER'));
    if (!group) return;
    const vector = this.getVectorCoordinatesFromGroup(group);
    AnimationHelper.moveCameraFocus(vector, zoomLevel);
  }

  // MARK: - Mouse & touch events

  handleMouseDown = (event, pos) => {
    this.currentMousePos.set(pos.x, pos.y);
    this.lastMouseDownPos = this.currentMousePos.clone();
    this.updateViewportMouse();
  }

  handleMouseUp = (event, pos) => {
    this.currentMousePos.set(pos.x, pos.y);
    this.updateViewportMouse();
    this.selectIntersectedIfNeeded();
  }

  handleMouseWheel = (event, pos) => {
    window.dispatchEvent(new Event('map_zoom_controlled'));
  }

  handleMouseMove = (event, pos) => {
    this.currentMousePos.set(pos.x, pos.y);
    this.updateViewportMouse();

    // Raycast. Only when mouse button is not pressed.
    const flags = event.buttons !== undefined ? event.buttons : event.which;
    // eslint-disable-next-line no-bitwise
    const primaryIsDown = flags != null && (flags & 1) === 1;
    if (!primaryIsDown) {
      this.raycast();
    }
  }

  handleTouchStart = (event, positions) => {
    // This code is no longer in use, but leaving it in case
    /*
    if (positions.length > 0) {
      this.currentMousePos.set(positions[0].x, positions[0].y);
      this.lastMouseDownPos = this.currentMousePos.clone();
      this.updateViewportMouse();
    }
    */

    if (event.touches.length > 1) {
      window.dispatchEvent(new Event('map_zoom_controlled'));
    }
  }

  handleTouchMove = (event, positions) => {
    if (positions.length > 0) {
      this.currentMousePos.set(positions[0].x, positions[0].y);
      this.updateViewportMouse();
    }
  }

  handleTouchEnd = (event, positions) => {
    if (positions.length > 0) {
      this.currentMousePos.set(positions[0].x, positions[0].y);
      this.updateViewportMouse();
    }
    this.selectIntersectedIfNeeded();
  }

  updateViewportMouse = () => {
    // Set viewport mouse position
    this.viewportMouse = this.currentMousePos.clone();
    this.viewportMouse.x = (this.currentMousePos.x / MapState.targetCanvas.offsetWidth) * 2 - 1;
    this.viewportMouse.y = -(this.currentMousePos.y / MapState.targetCanvas.offsetHeight) * 2 + 1;
  }


  // MARK: - Events to listen to

  addListener = (event, func) => {
    // Always pass an identified func. If you pass an anonymous func,
    // then it will not delete it from the stack before adding the next one
    this.removeListener(event, func);
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }
    this.eventListeners[event].push(func);
  }

  removeListener = (event, func) => {
    if (!this.eventListeners[event]) {
      return;
    }
    for (let index = this.eventListeners[event].length - 1; index >= 0; index -= 1) {
      const it = this.eventListeners[event][index];
      if (it === func) {
        // Den här verkar ju inte ta bort våra event?
        this.eventListeners[event].splice(index, 1);
      }
    }
  }

  triggerEvent = (eventName, event) => {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = [];
    }
    this.eventListeners[eventName].forEach((callback) => {
      callback(event);
    });
  }

  onSensorSelected = (callback) => {
    this.addListener('on_sensor_selected', callback);
  }

  onIssueSelected = (callback) => {
    this.addListener('on_issue_selected', callback);
  }

  onIssueDeselected = (callback) => {
    this.addListener('on_issue_deselected', callback);
  }

  onNavigationMarkerSet = (callback) => {
    this.addListener('on_navigation_marker_set', callback);
  }

  onZoneSelected = (callback) => {
    this.addListener('on_zone_selected', callback);
  }

  onZoneDeselected = (callback) => {
    this.addListener('on_zone_deselected', callback);
  }

  onSpaceSelected = (callback) => {
    this.addListener('on_space_selected', callback);
  }
}

export default SelectionManager;

