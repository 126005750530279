class ZoneState {
  constructor() {
    //
    // Enums, for state
    this.ZONE_STATE = {
      UNKNOWN: 0,
      AVAILABLE: 1,
      AWAY: 2,
      OCCUPIED: 3,
      SPACE: 4,
    };

    this.ZONE_STATE_TO_STR = {
      0: 'UNKNOWN',
      1: 'AVAILABLE',
      2: 'AWAY',
      3: 'OCCUPIED',
      4: 'SPACE',
    };

    this.ZONE_MAP_COLOR = {
      OTHER: 0,
      NOT_IN_FILTER: 1,
      GREEN: 2,
      YELLOW: 3,
      RED: 4,
      BLUE: 5,
    };

    this.ZONE_MAP_COLOR_TO_STR = {
      0: 'OTHER',
      1: 'NOT_IN_FILTER',
      2: 'GREEN',
      3: 'YELLOW',
      4: 'RED',
      5: 'BLUE',
    };
    // To check if ZoneStateService.init() has been run yet
    this.hasLoadedOnce = false;

    // Raw realm state
    this.realmState = [];

    // Zone states
    this.zoneStates = {};
    this.highlightedZoneStates = {};
    this.zoneStateChanged = 0;

    // Timer time
    this.INVALIDATE_TIMER_TICK_MS = 1000;

    // Resources
    this.activeResources = [];
    this.highlightedResources = [];
    this.resourcesOfActiveType = [];

    // Filters
    this.hasActiveFilters = false;
    this.hasActiveEquipmentFilter = false;
    this.activeZoneFilter = null;
    this.ignoreType = false;
    this.activeTypeZoneFilter = null;
    this.resourceTypeFilter = null;
    this.activeHasEquipmentFilters = [];
    this.activeAmountEquipmentFilters = {};
    this.activeFrom = null;
    this.activeUntil = null;
    this.activeDate = null;
    this.filterOnSensors = false;
    this.referenceBooking = null;

    // State invalidation timer
    this.availabilityStateUpdateTimerHandle = null;
  }

  destroy() {
    // Raw realm state
    this.realmState = [];

    // Zone states
    this.zoneStates = {};
    this.zoneStateChanged = 0;

    // Resources
    this.activeResources = [];
    this.highlightedResources = [];
    this.resourcesOfActiveType = [];

    // Filters
    this.hasActiveFilters = false;
    this.hasActiveEquipmentFilter = false;
    this.activeZoneFilter = null;
    this.activeTypeZoneFilter = null;
    this.ignoreType = false;
    this.resourceTypeFilter = null;
    this.activeHasEquipmentFilters = [];
    this.activeAmountEquipmentFilters = {};
    this.activeFrom = null;
    this.activeUntil = null;
    this.activeDate = null;
    this.filterOnSensors = false;
    this.referenceBooking = null;

    // State invalidation timer
    this.availabilityStateUpdateTimerHandle = null;
  }
}

export default new ZoneState();
