import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faMapMarkerAlt,
  faUserSecret,
  faSearch,
  faCalendarAlt,
  faLocationArrow,
  faPlus,
  faHome,
  faSignInAlt,
  faChartArea,
  faSignOutAlt,
  faCircle,
  faPlusCircle,
  faCog,
  faMapPin,
  faTrafficLight,
  faCheck,
  faBars,
  faEnvelope,
  faInfoCircle,
  faClock,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faTimes,
  faExclamationCircle,
  faExclamationTriangle,
  faChair,
  faMicrophone,
  faTools,
  faInfo,
  faGlobe,
  faWifi,
  faSlidersH,
  faSortAmountUpAlt,
  faSortAmountDown,
  faVolumeMute,
  faVolumeUp,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faMapMarkerAlt,
  faUserSecret,
  faSearch,
  faCalendarAlt,
  faLocationArrow,
  faMapPin,
  faPlus,
  faPlusCircle,
  faHome,
  faSignInAlt,
  faChartArea,
  faSignOutAlt,
  faCircle,
  faEnvelope,
  faCog,
  faTrafficLight,
  faCheck,
  faBars,
  faInfoCircle,
  faClock,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faTimes,
  faExclamationCircle,
  faExclamationTriangle,
  faChair,
  faTools,
  faMicrophone,
  faInfo,
  faGlobe,
  faWifi,
  faSlidersH,
  faSortAmountUpAlt,
  faSortAmountDown,
  faVolumeMute,
  faVolumeUp,
  faQuestionCircle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
