/* eslint-disable object-shorthand */
/* eslint-disable class-methods-use-this */

import CompanyService from '@/Services/Company/company.service';
import UserService from '@/Services/User/user.service';
import CompanyState from '@/singletons/company.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import PhoneService from '@/Services/Phone/phone.service';

import Vue from 'vue';

export const eventBus = new Vue();
class LocationSelectionController {
  /* Init */
  constructor() {
    this.isLoading = false;
    this.items = [];
    this.selected = null;
    this.hasLoadedOnce = false;
    this.finnishedLoading = null;
  }

  reset() {
    this.isLoading = false;
    this.items = [];
    this.selected = null;
    this.hasLoadedOnce = false;
  }

  init() {
    this.finnishedLoading = Promise.resolve(this.initAsync());
  }

  async initAsync() {
    this.isLoading = true;

    try {
      const tree = await CompanyService.getCompanies();
      const floors = this.getFloorItemsFromTree(tree);

      // Set floors
      this.items = floors;

      // Get possible current floor
      const currentFloor = UserState.selectedFloorplan;
      if (!this.selected && currentFloor) {
        this.selected = this.getItemModelByFloorId(currentFloor.Zid);
      }

      // Take floor zid from url
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (!this.selected && params.floor) {
        this.selected = this.getItemModelByFloorId(params.floor);
      }

      // If we have zid in params, override floor
      if (params.zid) {
        this.selected = await this.getLocationByResourceZid(params.zid);
      }

      // Get possible saved floor
      const savedFloorId = UserState.savedFloorplanId;
      if (!this.selected && (savedFloorId || localStorage.getItem('floor'))) {
        this.selected = this.getItemModelByFloorId(savedFloorId || localStorage.getItem('floor'));
      }

      // Set first floor found when no floor is selected or saved
      if (!this.selected) {
        this.selected = this.items.find(({ Zid }) => Zid !== null);
      }

      // Set location
      if (this.selected !== null) {
        this.setLocation(this.selected);
      }

      this.hasLoadedOnce = true;
    } catch (e) {
      console.log('ERROR: ', e);
      this.isLoading = false;
    }
    this.listenForPhoneNavigation();
    this.listenForResourceSelection();
    this.listenNavigateSpecificResource();
    this.isLoading = false;
  }

  checkIfZoneIsOnFloorAndSwitch(zid) {
    const currentZone = CompanyState?.zones.find((zone) => zone?.Zid === zid);
    const floor = CompanyState.zones.find((parent) => parent.Zid === currentZone.ParentZone);
    if (floor.Zid !== UserState.selectedLocation.Zid) {
      this.setLocationByFloorZid(floor.Zid);
    }
  }

  listenNavigateSpecificResource() {
    PhoneService.broadcast.$on(PhoneService.events.phone_book_gotoresource, (evt) => {
      const { zid } = evt.data;
      const currentZone = CompanyState?.zones.find((zone) => zone?.Zid === Number(zid));
      this.checkIfZoneIsOnFloorAndSwitch(Number(zid));
      PhoneService.broadcast.$emit(PhoneService.events.phone_module_change, { data: { module: 'CUSTOM', customPath: `/book/${currentZone.Type}/${zid}` } });
    });
  }

  listenForResourceSelection() {
    PhoneService.broadcast.$on(PhoneService.events.phone_map_selectresource, (evt) => {
      const { zid, source } = evt.data;
      this.checkIfZoneIsOnFloorAndSwitch(zid);

      let customPath = `/map?zid=${zid}`;
      if (source) {
        customPath += `&source=${source}`;
      }

      PhoneService.broadcast.$emit(PhoneService.events.phone_module_change, { data: { module: 'CUSTOM', customPath: customPath } });
    });
  }

  listenForPhoneNavigation() {
    PhoneService.broadcast.$on(PhoneService.events.phone_map_selectlocation, (evt) => {
      const { floorZid } = evt.data;

      if (floorZid && floorZid !== this.selected.Zid) {
        this.selected = this.getItemModelByFloorId(floorZid);
        this.setLocation(this.selected);
      }
    });
  }

  getFloorItemsFromTree(tree) {
    let res = [];
    const floors = tree.Floors || [];
    const children = tree.Children || [];

    // Add floors
    for (let i = 0; i < floors.length; i += 1) {
      const floor = floors[i];
      res.push({
        Cid: tree.Cid,
        Realm: tree.Realm,
        Parent: tree.Parent,
        Zid: floor.Zid,
        floor: floor,
        isHeader: false,
        type: 'floor',
        title: floor.Name,
        selectedTitle: floor.Name,
        parentName: null,
        offset: 0,
      });
    }

    // Add self to heirarchy if level exists
    if (res.length > 0 && tree.Level) {
      res = this.getIncreasedHierarchyList(res, tree.Name);
      res = res.sort((a, b) => a.title > b.title ? 1 : -1);
      res.splice(0, 0, {
        Cid: tree.Cid,
        Realm: tree.Realm,
        Parent: tree.Parent,
        Zid: null,
        floor: null,
        isHeader: true,
        type: 'office',
        title: tree.Name,
        selectedTitle: tree.Name,
        parentName: null,
        offset: 0,
      });
    }

    // Add floors from children
    for (let k = 0; k < children.length; k += 1) {
      const child = children[k];
      res = res.concat(this.getFloorItemsFromTree(child));
    }

    // Return full list
    return res;
  }

  getItemModelByFloorId(zid) {
    let zidToCheck = zid;
    if (typeof zidToCheck !== 'number') {
      zidToCheck = Number.parseInt(zidToCheck, 10);
    }

    const foundFloor = this.items.find((floor) => floor.Zid === zidToCheck);
    if (foundFloor) {
      return foundFloor;
    }

    return null;
  }

  async getLocationByResourceZid(zid) {
    let zidToCheck = zid;
    if (typeof zidToCheck !== 'number') {
      zidToCheck = Number.parseInt(zidToCheck, 10);
    }
    await CompanyState.finnishedLoading;
    const zone = CompanyState.zones?.find((it) => it.Zid === zidToCheck);
    return this.items.find((it) => it.Zid === zone?.ParentZone);
  }

  getIncreasedHierarchyList(list, parentName) {
    const res = list;
    for (let i = 0; i < res.length; i += 1) {
      res[i].offset += 1;
      res[i].selectedTitle = `${parentName} / ${res[i].selectedTitle}`;
      res[i].parentName = parentName;
    }
    return res;
  }

  async setLocationByFloorZid(zid) {
    const floor = this.items.find((_floor) => _floor.Zid === zid);
    await this.setLocation(floor);
  }

  // TODO: Needs to be run when refreshing page
  async setLocation(item) {
    if (!item) {
      return;
    }
    // Set selected
    this.selected = item;
    // Set selected floor on User singleton
    // & emit event for observers.
    const floorModel = (item || null);

    UserService.setFloorplan(floorModel);
    UserService.setCid(item.Cid);
    await CompanyService.setZonesOnFloor(item.Zid);
    PhoneService.send(PhoneService.events.phone_map_selectlocation, { floorZid: item.Zid });
    eventBus.$emit('onUserFloorplanChanged', floorModel);
    eventBus.$emit('onUserCidChanged', item.Cid);

    // SensorService.filterSensorsToSelectedFloor(SensorService.sensorsFiltered);
  }
}

export default new LocationSelectionController();
