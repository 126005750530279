/* eslint-disable no-param-reassign */
import {
  getCompanyTree, getZones, getZone, getAvailableUserStatusTypes, getAssets, getZoneTypes, getCompaniesByParentId,
} from '@/util/api';

import UserState from '@/singletons/user.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import { groupBy } from '@/functions/helper.functions';

const groupByTypeGroup = groupBy(['Group']);
const groupByType = groupBy(['Type']);
class CompanyService {
  static async initState(company) {
    CompanyState.init(company);
    CompanyState.finnishedLoading = await Promise.all([
      CompanyService.fetchZoneTypes(),
      CompanyService.getZones(),
      CompanyService.fetchCompaniesInRealm(),
      CompanyService.getAssets(),
    ]);
    CompanyState.hasLoadedOnce = true;
  }

  static destroyState() {
    CompanyState.destroy();
  }

  static async fetchCompaniesInRealm() {
    try {
      const { data } = await getCompaniesByParentId(CompanyState.realm);
      CompanyState.companiesInRealm = data;
    } catch (e) {
      console.log('Error fetching companies in realm');
    }
  }

  static async getCompanies() {
    try {
      const { data } = await getCompanyTree();
      CompanyState.companyTree = data;
      return data || [];
    } catch (err) {
      console.log('Error fetching companyTree: ', err);
      return [];
    }
  }

  /**
  *
  * @param {Number} zid
  * Groups zones on floor on their group then their type. Sets result to CompanyState.zonesOnFloor
  * If no group then key is empty string
  */
  static async setZonesOnFloor(zid) {
    await CompanyState.finnishedLoading;
    const { zones } = CompanyState;
    const types = CompanyState.zoneTypes;
    const floorZones = zones.filter((zone) => zone.ParentZone === zid);
    const zonesOnFloor = [];
    floorZones.forEach((zone) => {
      const type = types.find((_type) => _type.Constant === zone.Type);
      if (!type) { return; }
      zone.Group = type.Group || '';
      zone.Icon = type.Icon;
      zone.TypeName = type.Name;
      zone.Constant = type.Constant;
      zonesOnFloor.push(zone);
    });
    const zonesOnFloorWithGrouping = groupByTypeGroup(zonesOnFloor);
    // eslint-disable-next-line no-restricted-syntax
    const groupsWithTypes = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(zonesOnFloorWithGrouping)) {
      const groupedByType = groupByType(value);
      groupsWithTypes[key] = groupedByType;
    }
    CompanyState.zonesOnFloor = groupsWithTypes;
  }

  static async getRealmName() {
    await CompanyState.finnishedLoading;

    let cmp = CompanyState.companiesInRealm.find((c) => c.Cid === CompanyState.realm);
    if (!cmp) cmp = CompanyState.companiesInRealm.find((c) => c.Realm === CompanyState.realm);

    return cmp?.Name || null;
  }

  static async getZones() {
    try {
      const { data } = await getZones(UserState.parent);
      CompanyState.zones = data;
      return data || [];
    } catch (err) {
      // console.log(UserState);
      if (UserState.parent) {
        console.log('Error fetching zones with for realm: ', UserState.parent);
      } else {
        console.log('Error fetching zones, no User.parent realm id');
      }
      console.log(err);
      return [];
    }
  }

  static async getCachedZone(zid) {
    const cachedZone = CompanyState.zones && CompanyState.zones.find((_zone) => _zone.Zid === zid);
    if (!cachedZone) {
      try {
        const { data: zone } = await CompanyService.getZone(zid);
        CompanyState.zones.push(zone);
      } catch (e) {
        return null;
      }
    }

    return CompanyState.zones.find((_zone) => _zone.Zid === zid);
  }

  static async getZone(zid) {
    const { data } = await getZone(zid);
    return data || '';
  }

  static async getAssets() {
    const { data } = await getAssets(UserState.parent);
    CompanyState.assets = data;
  }

  static getFloors() {
    if (!CompanyState.floors.size) {
      CompanyService.traverseCompanyTree(CompanyState.companyTree);
    }
    return CompanyState.floors;
  }

  static traverseCompanyTree(root) {
    if (root?.Floors && root.Floors.length) {
      root.Floors.forEach((floor) => {
        const newFloor = {
          Name: floor.Name,
          Zid: floor.Zid,
          Parent: root.Cid,
        };
        CompanyState.floors.add(newFloor);
      });
    }
    if (root?.Children && root.Children.length) {
      const children = root.Children;
      children.forEach((child) => CompanyService.traverseCompanyTree(child));
    }
  }

  static async getChacedZoneTypes() {
    try {
      if (!CompanyState.zoneTypes?.length) {
        const { data } = await getZoneTypes(UserState.parent);
        CompanyState.zoneTypes = data;
      }
    } catch (err) {
      console.log('Error fetching zones types: ', err);
    }
    return CompanyState.zoneTypes;
  }

  static async fetchZoneTypes() {
    try {
      const { data } = await getZoneTypes(UserState.parent);
      CompanyState.zoneTypes = data;
    } catch (err) {
      console.log('Error fetching zones types: ', err);
    }
  }

  /** User Status Types */
  static async getAvailableUserStatuses() {
    try {
      const { data } = await getAvailableUserStatusTypes(UserState.parent);
      CompanyState.userStatusTypes = data;
      CompanyState.hasLoadedOnce = data !== [];
      return data || [];
    } catch (err) {
      console.log('Error fetching user statuses: ', err);
      return [];
    }
  }

  static getZoneByZid(zid) {
    const target = (typeof zid === 'string') ? Number.parseInt(zid, 10) : zid;
    return CompanyState.zones.find((z) => z.Zid === target);
  }

  static getLocationByParent(parent, zid) {
    try {
      const parentZone = CompanyService.getZoneByZid(zid)?.ParentZone;
      if (!parentZone) return '';
      const floor = CompanyService.getFloorByParent(parentZone);
      const company = CompanyService.findCompanyByCid(CompanyState.companyTree, parent);
      if (floor && floor.Name && company && company.Name) {
        return `${floor.Name}, ${company.Name}`;
      } return '';
    } catch (e) {
      console.log('Company.getLocationByParent: ', e);
      return '';
    }
  }

  static getCompanyByCid(cid) {
    // console.log('Company by cid');
    // console.log(CompanyState.companyTree);
    const company = CompanyState.companiesInRealm.find((cmp) => cmp.Cid === cid);
    return company;
  }

  static findCompanyByCid(root, cid) {
    if (root?.Cid === cid) return root;
    if (root?.Children != null) {
      let found = null;
      for (let i = 0; found === null && i < root.Children.length; i += 1) {
        found = CompanyService.findCompanyByCid(root.Children[i], cid);
      } return found;
    }
    return null;
  }

  static findStatusByType = (type) => (item) => item.Type === type;

  static getUserStatusFromType(type) {
    if (!type) {
      return null;
    }

    const status = CompanyState.userStatusTypes.find(CompanyService.findStatusByType(type));
    if (!status) {
      return null;
    }

    return status;
  }

  static getResourceIconFromType(type) {
    const defaultIcon = '/icons/default_resource.svg';

    if (!type) {
      return defaultIcon;
    }

    const zone = CompanyState.zoneTypes.find((item) => item.Constant === type);

    if (!zone) {
      return defaultIcon;
    }

    return zone.Icon || defaultIcon;
  }

  static getFloorByParent(zid) {
    const floors = Array.from(CompanyService.getFloors());
    const floor = floors.find((f) => f.Zid === zid);
    return floor;
  }

  static getMapFloors() {
    const floors = [];
    const { zones } = CompanyState;

    for (let z = zones.length - 1; z >= 0; z -= 1) {
      if (zones[z].Type === 'FLOOR') {
        floors.push({
          Zid: zones[z].Zid,
          zones: [],
        });
      }
    }

    for (let i = 0; i < zones.length; i += 1) {
      if (zones[i].ParentZone !== null) {
        if (zones[i].Type !== 'FLOOR') {
          for (let fd = floors.length - 1; fd >= 0; fd -= 1) {
            if (floors[fd].Zid === zones[i].ParentZone) {
              floors[fd].zones.push(zones[i]);
              break;
            }
          }
        }
      }
    }

    return floors;
  }

  static getMapFloor(zid) {
    const floors = this.getMapFloors();
    return floors.find((it) => it.Zid === zid);
  }
}

export default CompanyService;
