import getBrowserLocale from '@/i18n/util/browser.locale';

class ExtendedDate extends Date {
  lang = getBrowserLocale();

  tomorrow() {
    const next = new ExtendedDate(this);
    next.setDate(next.getDate() + 1);
    next.setHours(0, 0, 0);
    return next;
  }

  yesterday() {
    return new ExtendedDate(this).getTime() - (24 * 60 * 60 * 1000);
  }

  isBefore(date) {
    return new ExtendedDate(this).getTime() < new ExtendedDate(date).getTime();
  }

  /**
   * @returns String: 'yyyy-mm-dd'
   */
  isoDateString() {
    const yy = this.getFullYear();
    const mm = this.getMonth() > 8 ? this.getMonth() + 1 : `0${this.getMonth() + 1}`;
    const dd = this.getDate() > 9 ? this.getDate() : `0${this.getDate()}`;
    return `${yy}-${mm}-${dd}`;
  }

  isAfter(date) {
    return new ExtendedDate(this).getTime() > new ExtendedDate(date).getTime();
  }

  add(type, arg) {
    const next = new ExtendedDate(this);

    switch (type) {
      case 'days': {
        next.setDate(next.getDate() + arg);
        next.setHours(0, 0, 0);
        break;
      }
      case 'hours': {
        next.setHours(next.getHours() + arg);
        break;
      }
      default: return next;
    }

    return next;
  }

  dateNr() {
    return this.getDate();
  }

  isToday() {
    return this.isSameDate(new ExtendedDate());
  }

  isSameDate(date) {
    return (!(
      this.getFullYear() !== date.getFullYear()
          || this.getMonth() !== date.getMonth()
          || this.getDate() !== date.getDate())
    );
  }

  /**
   *
   * @param {*} queryDate String on format yyyy-mm-dd
   * @param {*} timeunits default to 0, can be number or string "xx" e.g 06
   * @returns:
   *  A date object with year/month/date staying the same as the
   *  input without shifting in different timezones.
   */
  static createDateFromQuery(queryDate, _hh = 0, _mm = 0, _ss = 0, _mil = 0) {
    const [yy, mm, dd] = queryDate.split('-');
    const date = ExtendedDate.createDate(yy, mm - 1, dd, _hh, _mm, _ss, _mil);
    return date;
  }

  /**
   *
   * @param {*} dateunits (yy, mm, dd): String, required
   * @param {*} timeunites (_hh, _mm..): Number or string, defaults to 0
   * @returns
   *  A Date object with year/month/date staying the same as the
   *  input without shifting in different timezones.
   */
  static createDate(yy, mm, dd, _hh = 0, _mm = 0, _ss = 0, _mil = 0) {
    const date = new ExtendedDate();
    date.setFullYear(yy);
    date.setMonth(mm, dd);
    date.setDate(dd);
    date.setHours(_hh);
    date.setMinutes(_mm);
    date.setSeconds(_ss);
    date.setMilliseconds(_mil);
    return date;
  }

  /**
   *
   * @param {*} dateunits (yy, mm, dd): String, required
   * @param {*} timeunites (_hh, _mm..): Number or string, defaults to 0
   * @returns A Date object with UTC values for the input.
   *
   * The need for this is becaus javascript date can't create a new Date for a certain timezone
   * so compare datetimes in timezone that is not client timezone or UTC we create "UTC-dates"
   * with the same values to compare instead.
   */
  static createUTCDate(yy, mm, dd, HH, MM, _ss = 0, _mil = 0) {
    const date = new ExtendedDate();
    date.setUTCFullYear(yy);
    date.setUTCMonth(mm, dd);
    date.setUTCDate(dd);
    date.setUTCHours(HH);
    date.setUTCMinutes(MM);
    date.setUTCSeconds(_ss);
    date.setUTCSeconds(_mil);
    return date;
  }

  /**
   *
   * @param {*} tz: String, Timezone e.g. Europe/Stockholm
   * @returns String with Datetime in timezone
   * Example: UTC-0 time is 2022-05-17 11:30, returns 2022-05-17 13:30
   */
  static getDateTimeInTimezone(tz) {
    const options = {
      timeZone: tz,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };
    const dateStr = new Intl.DateTimeFormat(['sv-SE'], options).format(new Date());
    return dateStr;
  }

  /**
   *
   * @param {*} str String, datetime e.g. 2022-05-17 13:30
   * @returns Date object with utc values for input. (Uses createUTC date)
   */
  static createUTCDateFromTimezoneString(str) {
    const [dStr, tStr] = str.split(' ').map((s) => s.trim());
    const [yy, mm, dd] = dStr.split('-');
    const [HH, MM] = tStr.split(':');
    return ExtendedDate.createUTCDate(yy, mm - 1, dd, HH, MM);
  }

  /**
   *
   * @param {*} d Date, date object.
   * @param {*} t String, time, e.g. '12:00'
   * @returns Date object with utc values for input
   */
  static createUTCDateFromDateAndTimestring(d, t) {
    const [HH, MM] = t.split(':');
    return ExtendedDate.createUTCDate(d.getFullYear(), d.getMonth(), d.getDate(), HH, MM);
  }

  /**
   * The example matches client running on timezone "Europe/Stockholm" (UTC +2)
   * @param {*} timestr String representation of a time e.g. '18:00:00'
   * @param {*} tz timezone e.g. "Pacific/Honolulu" (UTC -10)
   * @returns HH:MM in clients local time. E.g. "06:00"
   * NOTE: Have not been implemented with regard to timezones with halfhours or > +12
   * so probably breaks with those timezones.
   */
  static getTimestringForTimezone(timestr, tz) {
    const [hh, mm] = timestr.split(':').map((item) => Number(item));
    const mmIn = (hh * 60) + mm;
    // Create an UTC representation of the inputed timestr.
    const mmOffset = -ExtendedDate.getTimezoneOffset(tz);
    const mmTot = mmIn + mmOffset;
    const utc = new Date();
    utc.setUTCHours(0, 0, 0, 0);
    utc.setUTCMinutes(mmTot);
    return `${ExtendedDate.timeUnitToString(utc.getHours())}:${ExtendedDate.timeUnitToString(utc.getMinutes())}`;
  }

  /**
   *
   * @param {*} t Int, some time value.
   * @returns String, with 0 appended in front if needed. e.g. 5 => "05"
   */
  static timeUnitToString(t) {
    let res = t.toString();
    if (t < 10) res = `0${res}`;
    return res;
  }

  static timeStamp(millis) {
    const dateInMillis = new ExtendedDate().setHours(0, 0, 0, millis);
    return new ExtendedDate(dateInMillis).localeTimeString();
  }

  /**
   *
   * @param {*} timeZone e.g "Europe/Stockholm"
   * @returns Offset in minutes from UTC for a timezone.
   * NOTE: Currently only handles full hours
   */
  static getTimezoneOffset(timeZone) {
    const date = new Date(new Date().setUTCHours(12));
    const optA = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      timeZone: 'UTC',
    };
    const optB = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      timeZone,
    };
    const utc = new Intl.DateTimeFormat('en-US', optA).format(date);
    const [utcH] = utc.split(':');
    const tz = new Intl.DateTimeFormat('en-US', optB).format(date);
    const [tzH] = tz.split(':');

    const hhDiff = Number(tzH) - Number(utcH);
    const tot = hhDiff * 60;
    return tot;
  }

  static timeStringToLocal(timeString) {
    const d = new ExtendedDate();
    const [hh, mm] = timeString.split(':');
    d.setUTCHours(hh, mm, 0, 0);
    return d.localeTimeString();
  }

  weekDay(length) {
    const len = length || 'long';
    const weekDayCapitalLetter = this.toLocaleString(this.lang, { weekday: len });
    return weekDayCapitalLetter.charAt(0).toUpperCase() + weekDayCapitalLetter.slice(1).toLowerCase();
  }

  getDateString() {
    let day = this.getDate();
    let month = this.getMonth() + 1; // starts at 0
    const year = this.getFullYear();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }

  getMonthDateNrString() {
    const locale = getBrowserLocale();
    if (locale === 'en-US' || locale === 'en-CA') {
      return `${this.monthName()} ${this.dateNr()}`;
    }
    return `${this.dateNr()} ${this.monthName()}`;
  }

  getWeekDayAndYearString() {
    return `${this.weekDay()}, ${this.getFullYear()}`;
  }

  monthName(len) {
    const monthNameCapitalLetter = this.toLocaleString(this.lang, { month: len || 'long' });
    return monthNameCapitalLetter.charAt(0).toUpperCase() + monthNameCapitalLetter.slice(1).toLowerCase();
  }

  dayStart() {
    return new ExtendedDate(new ExtendedDate(this).add('days', -1).setHours(23, 59, 59));
  }

  dayEnd() {
    return new ExtendedDate(new ExtendedDate(this).setHours(23, 59, 59));
  }

  getMonday() {
    const date = new ExtendedDate(this);
    const day = date.getDay();
    date.setHours(-24 * (day - 1));
    return date;
  }

  // Used by weekly datepicker in order to not change week upon setting day to monday
  getMondayAlt() {
    const date = new ExtendedDate(this);
    for (let i = 0; i < 7; i += 1) {
      if (date.getDay() === 1) {
        break;
      }
      date.setDate(date.getDate() - 1);
    }

    return date;
  }

  getWeekStart(day) {
    const date = new ExtendedDate(this);
    for (let i = 0; i < 7; i += 1) {
      if (date.getDay() === day) break;
      date.setDate(date.getDate() - 1);
    }
    return date;
  }

  /**
   * @param nr Weekday where: 0 = previous Sunday, 1 = Monday... 6 = Saturday, 7 = next sunday;
   * @returns new Date object for weekday.
   */
  getDateForWeekday(dayNr) {
    const date = new ExtendedDate(this);
    let day = date.getDay();
    if (day === 0) day = 7;
    date.setHours(24 * (dayNr - (day)));
    return date;
  }

  localeTimeString() {
    const locales = [this.lang, 'en', 'sv'];
    const format = { hour: '2-digit', minute: '2-digit' };
    const timeString = this.toLocaleTimeString(locales, format);
    return timeString;
  }

  UTCTimeString() {
    const millis = this.getTime();

    const hours = Math.floor(millis / (1000 * 60 * 60));
    const hoursString = hours < 10 ? '0'.concat(hours.toString()) : hours.toString();
    const mins = Math.floor((millis - (hours * (1000 * 60 * 60))) / (1000 * 60));
    const minsString = mins < 10 ? '0'.concat(mins.toString()) : mins.toString();

    return hoursString.concat(':').concat(minsString);
  }

  remainingWorkDays() {
    let date = new ExtendedDate(this);
    const days = [];
    date.setHours(0, 0, 0);
    days.push(date);
    while (date.getDay() < 5) {
      date = date.tomorrow();
      days.push(date);
    }
    return days;
  }

  static getSaturday() {
    const date = new ExtendedDate();
    const dayNr = date.getDay();
    const saturday = date.add('days', 6 - dayNr);
    return saturday;
  }

  static getFutureWeekDay(dayNr) {
    let count;
    let date = new ExtendedDate();
    if (date.getDay() === dayNr) {
      count = 7;
    } else {
      count = (7 + dayNr) - date.getDay();
    }
    date = date.add('days', count);
    return date;
  }

  // Returns the ISO 8601 week number (1-53)
  getWeekNumber() {
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  }

  getTimeMillisWithoutDateOffset(canBeMidnight) {
    const offset = new ExtendedDate(this).setHours(0, 0, 0, 0);
    const millis = this.getTime() - offset;
    const aDayMillis = 8.64 * 10 ** 7;
    return canBeMidnight && millis === 0 ? aDayMillis : millis;
  }
}

export default ExtendedDate;
