import router from '@/router';
import { NotificationWrapperRenderer } from '@rolergo/native-notifications-package-nodejs/renderer';
import ReminderStateSingleton from '@/singletons/reminder.state.singleton';
import { NOTIFICATION_ACTIONS } from './notification.constants';

// eslint-disable-next-line import/prefer-default-export
export const NotificationRegistryRenderer = {
  init: async () => {
    const tenMinutesMs = 10 * 60 * 1000;

    (await NotificationWrapperRenderer.init())
      .registerAction(NOTIFICATION_ACTIONS.NOTIFICATION_WELLNESS_TIMEOUT_SHOW, () => {
        if (router.history.current.path !== '/timer') {
          router.push({ path: '/timer' });
        }
      })
      .registerAction(NOTIFICATION_ACTIONS.NOTIFICATION_WELLNESS_TIMEOUT_SNOOZE, () => {
        ReminderStateSingleton.createSnoozeState(tenMinutesMs);
      });
  },
};
