import i18n from '@/i18n';

/**
 * Usage:
 * @param message: The message that will be displayed
 * @param eventType: The type of event that eventListener will listen for.
 * @param variant: Toast variant, decide what bootstrap color theme will be applied.
 * const evt = new CustomEvent('hello world', ,)
 * evt.dispatch() will display a error toast in App.vue
 */

export default class ToastEvent extends Event {
  constructor(message, eventType = 'global_error_message', variant = 'danger') {
    super(eventType);
    this.message = message;
    this.variant = variant;
    this.title = this.variant === 'success' ? i18n.t('Errors.success') : i18n.t('Errors.something_wrong');
  }

  dispatch() {
    document.dispatchEvent(this);
  }
}
