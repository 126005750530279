/* eslint-disable no-return-assign,class-methods-use-this */
import MapState from '@/singletons/map.state.singleton';
import { getFromLocalStorage, saveToLocalStorage } from '@/functions/helper.functions';

export const STORAGE_KEYS = {
  NAVIGATOR_MARKER: 'NAVIGATOR_MARKER',
  PIN_SIDE_PAGE: 'PIN_SIDE_PAGE',
  NAVIGATOR_CAMERA_VIEWS: 'NAVIGATOR_CAMERA_VIEWS',
};

const cameraViewIndex = (customCameraViewType, floorZid) => `${floorZid}/${customCameraViewType}`;

class SettingsNavigatorState {
  constructor() {
    this.idle = null;
    this.navigatorMarker = null;
    this.navigatorCameraViews = null;
    this.pinSidePage = null;
    this.showSidePage = false;
    this.idleTimeCounter = null;
    this.idleDuration = null;
  }

  init() {
    this.navigatorMarker = getFromLocalStorage(STORAGE_KEYS.NAVIGATOR_MARKER);
    this.pinSidePage = getFromLocalStorage(STORAGE_KEYS.PIN_SIDE_PAGE);
    this.navigatorCameraViews = getFromLocalStorage(STORAGE_KEYS.NAVIGATOR_CAMERA_VIEWS);
  }

  showOrHideSidePage(show) {
    this.showSidePage = !!show;
  }

  setPinSidePage(doPin) {
    return this.pinSidePage = saveToLocalStorage(STORAGE_KEYS.PIN_SIDE_PAGE, doPin);
  }

  setNavigatorMarker(visible, position, floorZid) {
    const obj = { visible, position, floorZid };
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);

    return this.navigatorMarker = saveToLocalStorage(STORAGE_KEYS.NAVIGATOR_MARKER, obj);
  }

  setCameraView(position, zoom, customCameraViewType = MapState.customCameraViewType, floorZid = MapState.floor.Zid) {
    const obj = { [cameraViewIndex(floorZid, customCameraViewType)]: { position, zoom } };
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);

    this.navigatorCameraViews = saveToLocalStorage(STORAGE_KEYS.NAVIGATOR_CAMERA_VIEWS, obj);
    return this.navigatorCameraViews;
  }

  getCameraView(customCameraViewType = MapState.customCameraViewType, floorZid = MapState.floor.Zid) {
    if (this.navigatorCameraViews && cameraViewIndex(floorZid, customCameraViewType) in this.navigatorCameraViews) {
      return this.navigatorCameraViews[cameraViewIndex(floorZid, customCameraViewType)];
    }
    return false;
  }

  removeCameraView(customCameraViewType = MapState.customCameraViewType, floorZid = MapState.floor.Zid) {
    const obj = getFromLocalStorage(STORAGE_KEYS.NAVIGATOR_CAMERA_VIEWS);
    if (obj) {
      delete obj[cameraViewIndex(floorZid, customCameraViewType)];
    }
    saveToLocalStorage(STORAGE_KEYS.NAVIGATOR_CAMERA_VIEWS, obj, false);
    this.navigatorCameraViews = obj;
  }
}

export default new SettingsNavigatorState();
