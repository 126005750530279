<template>
  <div>
    <svg-icon
      v-if="!activeDevice"
      class="menu-icon-3"
      src="/icons/wellness.svg"
    />
    <h6 v-else>
      {{ type }}
    </h6>
    <h6
      v-if="!activeDevice"
      class="wellness__sidebar-item"
    >
      {{ $t('Components.Wellness.header') }}
    </h6>
    <h6 v-else>
      {{ currentHeight }}
    </h6>
  </div>
</template>

<script>
import ConnectState from '@/singletons/connect.state.singleton';
import UserState from '@/singletons/user.state.singleton';

export default {
  data() {
    return {
      ConnectState,
      UserState,
    };
  },
  computed: {
    activeDevice() { return ConnectState?.selectedDevice; },
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
    currentHeight() {
      return `${this.activeDevice.getPosition(this.measurementUnit)} ${this.measurementUnit}`;
    },
    type() {
      if (!this.activeDevice.type) {
        return 'Desk';
      }
      return this.activeDevice.type;
    },
  },

};

</script>

<style lang="scss" scoped>

  h6 {
    font-variant-numeric: tabular-nums;
  }

  .icon {
    transform: scale(1.5);
  }
  span {
    fill: var(--primary-color);
  }

</style>
<style lang="scss">
@import '../../wellness/style/wellness.scss';
</style>
