import axios from 'axios';
import apiClient from './api.interceptor';

const baseUrl = process.env.VUE_APP_BASE_URL;

export const authenticateBasic = () => apiClient.get(`${baseUrl}/api/authenticate/basic`, { withCredentials: true });
export const authenticateAzure = () => apiClient.get(`${baseUrl}/api/authenticate/azure`);
export const authenticateCookie = () => apiClient.get(`${baseUrl}/api/authenticate/cookie`);
export const logout = () => apiClient.get(`${baseUrl}/api/logout`, { withCredentials: true });
export const getTicket = () => apiClient.get(`${baseUrl}/gossip/auth/ticket`);
export const setUserStatus = (status, username) => apiClient.put(`${baseUrl}/core/v3/user/${username}/status`, status);
export const getUser = (username) => apiClient.get(`${baseUrl}/core/v3/user/${username}`);
export const getUserSmall = (username) => apiClient.get(`${baseUrl}/core/v3/user/small/${username}`);

// Settings
export const updateUserProfile = (username, profile) => apiClient.put(`${baseUrl}/core/v3/user/${username}/profile`, profile);
export const updateUserAvatar = (username, avatar) => apiClient.post(`${baseUrl}/core/v3/user/${username}/avatar/upload/finish`, avatar);
export const deleteUserAvatar = (username) => apiClient.delete(`${baseUrl}/core/v3/user/${username}/avatar`);
export const updateUserPassword = (username, data) => apiClient.put(`${baseUrl}/core/v3/user/${username}/keys/up`, data);

export const verifyPassword = (username, password) => axios.get(`${baseUrl}/api/authenticate/basic`, {
  headers: {
    Accept: 'application/vnd.idesk-v5+json',
    'idesk-auth-password': password,
    'idesk-auth-username': username,
    'idesk-auth-method': 'up',
    'Content-Type': 'application/vnd.idesk-v5+json',
  },
  withCredentials: true,
  crossDomain: true,
});

export const getPowerBIEmbedToken = () => apiClient.get(`${baseUrl}/v1/admin/report/embedtoken`);

export const getAzureProfilePhoto = async () => null;
